<template>
  <div class="list">
    <h2 class="headline" v-if="title">{{ title }}</h2>
    <ul class="list-container">
      <li
        v-for="(item, index) in items"
        :key="`item-${item.token}-${index}`"
        class="item"
      >
        <component
          v-bind:is="type"
          :document="item"
          :legalcase="item"
          :selected="selected && selected.token === item.token"
          :displayHTML="displayHTML"
          :canDelete="canDelete"
          :actions="itemActions"
          @onClick="onLink"
          @onDelete="onDelete"
        />
      </li>
    </ul>
  </div>
</template>

<script>
import LegalCase from '@/components/elements/LegalCase';
import Document from '@/components/elements/Document';
import Sprite from '@/components/elements/Sprite';

export default {
  components: {
    Sprite,
    LegalCase,
    Document
  },
  props: {
    type: {
      type: String,
      default: 'document' // legalcase
    },
    title: {
      type: String
    },
    items: {
      type: Array,
      default: () => []
    },
    selected: {
      type: Object,
      default: null
    },
    displayHTML: {
      type: Boolean,
      default: false
    },
    canDelete: {
      type: Boolean,
      default: false
    },
    actions: {
      type: Array,
      default: null
    }
  },
  computed: {
    itemActions() {
      if (typeof this.actions === 'function') return this.actions();
      return this.actions;
    }
  },
  methods: {
    onLink(item) {
      this.$emit('onClick', item);
    },
    onDelete(token) {
      this.$emit('onDelete', token);
    }
  }
};
</script>

<style lang="scss" scoped>
.list {
  width: 100%;
  // padding-bottom: 24px;
  margin-bottom: 24px;
  text-align: left;

  &:not(:first-child) {
    margin-top: 24px;
  }

  .headline {
    margin-bottom: 8px;
  }

  .item {
    &:not(:first-child) {
      margin-top: 8px;
    }
  }
}
</style>
