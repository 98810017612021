<template>
  <div class="preview">
    <div class="preview-wrapper">
      <div class="preview-content">
        <iframe
          v-if="previewType === 'iframe'"
          :src="`${iframeURL}#toolbar=0&navpanes=0`"
          width="100%"
          height="407"
          class="preview-embed preview-embed--iframe"
        />
        <img
          v-if="previewType === 'image'"
          :src="iframeURL"
          class="preview-embed"
        />
        <h2 class="preview-title">{{ doc.name }}</h2>
        <p v-if="doc.summary" class="preview-text">{{ doc.summary }}</p>
      </div>
      <div class="preview-actions">
        <Btn v-if="isSuperAdmin" @click="onEdit">Éditer</Btn>
        <Btn v-else-if="doc.mime === 'application/pdf'" @click="onClick('open')">
          Ouvrir
        </Btn>
        <Btn :theme="'ghost'" @click="onClick('download')">Télécharger</Btn>
      </div>

      <Select
        v-if="legalCases.length"
        :options="legalCases"
        :value="[]"
        :allowNull="true"
        :placeholder="'Ajouter à une affaire'"
        :multiple="true"
        position="top"
        @onChange="onChange($event)"
      />
    </div>

    <div
      v-if="loading"
      class="preview-loader"
    >
      <Loader/>
    </div>
  </div>
</template>

<script>
import Btn from '@/components/elements/Btn';
import Loader from '@/components/elements/Loader';
import Select from '@/components/elements/Select';

export default {
  components: {
    Btn,
    Loader,
    Select
  },
  beforeMount() {
    this.$store.dispatch('preview/getLegalCases');
  },
  computed: {
    doc() {
      return this.$store.state.preview.document;
    },
    iframeURL() {
      return this.$store.state.preview.iframeURL;
    },
    previewType() {
      const { document, iframeURL } = this.$store.state.preview;
      if (!iframeURL) return null;

      switch (document.mime) {
        case 'application/pdf':
          return 'iframe'
        case 'image/png':
        case 'image/jpg':
        case 'image/jpeg':
          return 'image'
        default:
          return null;
      }
    },
    canOpen() {
      const { mime } = this.$store.state.preview.document;
      return mime === 'application/pdf' || mime === 'image/png' || mime === 'image/jpg' || mime === 'image/jpeg';
    },
    legalCases() {
      return this.$store.state.preview.legalCases
      .filter(lc => lc.status === 'opened')
      .map(obj => {
        return {
          value: obj.token,
          label: `${obj.litigant.firstname} ${obj.litigant.lastname}${obj.lawcase && obj.lawcase.content ? ' - ' + obj.lawcase.content : ''}`
        }
      });
    },
    loading() {
      return this.$store.state.preview.requests !== 0;
    },
    isSuperAdmin() {
      const { role } = this.$store.state.user.data;
      return role === 'admin';
    }
  },
  methods: {
    onEdit() {
      this.$router.push({
        name: 'Tagging',
        query: { token: this.doc.token }
      });
    },
    onClick($event) {
      this.$store.dispatch('preview/open', { type: $event });
    },
    onChange($event) {
      this.$store.dispatch('preview/addToCase', $event);
    }
  }
};
</script>

<style lang="scss" scoped>
.preview {
  position: relative;
  height: 100%;

  &-wrapper {
    display: flex;
    flex-direction: column;
    position: relative;
    height: 100%;
    overflow: auto;
  }

  &-content {
    @include padding(16px);
    overflow: auto;
    width: 100%;
  }

  &-embed {
    display: block;
    width: 100%;
    height: auto;
    margin-bottom: 16px;
    border-radius: 8px;

    &--iframe {
      height: 406px;
    }
  }

  &-text {
    color: rgba($black-light, .6);
    font-size: rem(12px);
    line-height: 1.5;
  }

  &-actions {
    @include padding(0 16px 16px);
    display: flex;
    justify-content: space-between;
    flex-shrink: 0;

    .button {
      width: calc(50% - 4px);

      &:last-child:first-child {
        width: 100%;
      }
    }
  }

  .select {
    @include margin(null 16px 16px);
  }

  &-loader {
    @include position(absolute, 0 null null 0);
    @include size(100%);
    display: flex;
    align-items: center;
    z-index: 3;
    background-color: rgba($white, .7);
    pointer-events: none;
  }
}
</style>