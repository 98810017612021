<template>
  <div class="legals page">
    <Header :title="$t('pages.legals.title')"/>

    <div class="box">
      <ul>
        <li v-for="link in links" :key="link.label">
          <a :href="link.href" target="_blank">{{ link.label }}</a>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import Header from "@/components/modules/Header";

export default {
  components: {
    Header
  },
  data() {
    return {
      links: [
        {
          href: 'https://symplicy.com/conditions-generales-de-services/',
          label: this.$t('footer.links.services')
        },
        {
          href: 'https://symplicy.com/politique-en-matiere-de-protection-des-donnees-a-caractere-personnel/ ',
          label: this.$t('footer.links.privacy')
        },
        {
          href: 'https://symplicy.com/politique-en-matiere-de-cookies/',
          label: this.$t('footer.links.cookies')
        },
        {
          href: 'https://symplicy.com/mentions-legales/',
          label: this.$t('footer.links.legals')
        }
      ]
    }
  }
};
</script>

<style lang="scss" scoped>
.box {
  ul {
    a {
      display: inline-block;
      color: var(--black-light);

      &:hover {
        color: var(--primary);
      }
    }
  }
}
</style>