<template>
  <div class="category">
    <template v-if="isInput">
      <input
        :id="`cat-${category.id}`"
        type="checkbox"
        class="u-visually-hidden category-input"
        :checked="checked"
        @input="$emit('onInput')"
      />
      <label :for="`cat-${category.id}`" class="category-label">
        <span class="category-label-check">
          <Sprite type="check"/>
        </span>
        {{ category.description }}
      </label>
    </template>
    <template v-else>
      <router-link :to="{ name: 'CategoriesDetail', params: { id: category.id } }" class="category-link" @click="onClick(category)">
        <span class="u-visually-hidden">{{ category.description }}</span>
      </router-link>
      <p class="category-label">{{ category.description }}</p>

      <!-- <div class="category-right">
        <ul v-if="displayLawyers.length" class="category-lawyers">
          <li
            v-for="lawyer in displayLawyers"
            :key="`lawyer-${lawyer.id}`"
            class="category-lawyer"
          >
            <figure v-if="lawyer.picture" class="category-lawyer-profile">
              <img :src="lawyer.picture"/>
            </figure>
            <span v-else :class="`category-lawyer-profile${lawyer.plus ? ' plus' : ''}`">{{ lawyer.plus ? `+${lawyer.plus}` : lawyer.name.charAt(0) }}</span>
          </li>
        </ul>

        <button class="category-toggle">
          <span class="u-visually-hidden">Ouvrir</span>
          <span class="category-toggle-dots" />
        </button>
      </div> -->
    </template>
  </div>
</template>

<script>
import Sprite from '@/components/elements/Sprite';

export default {
  components: {
    Sprite
  },
  props: {
    category: {
      type: String,
      require: true
    },
    isInput: {
      type: Boolean,
      default: false
    },
    checked: {
      type: Boolean
    },
  },
  data() {
    return {
      lawyers: [
        {
          id: '1',
          name: 'Phillipe Navette',
          picture: 'https://picsum.photos/id/64/64/64'
        },
        {
          id: '2',
          name: 'Samantha Allays',
          picture: 'https://picsum.photos/id/342/64/64'
        },
        {
          id: '3',
          name: 'Jacinthe Deveaker'
        },
        {
          id: '4',
          name: 'Thierry Mugies'
        }
      ]
    }
  },
  computed: {
    displayLawyers() {
      const { lawyers } = this;
      // const { lawyers } = this.category;
      const toDisplay = [];
      if (lawyers?.length) {
        lawyers.map((lawyer, index) => {
          if (index < 3) toDisplay.push(lawyer);
          else if (index === 3) toDisplay.push({ plus: lawyers.length - 3 });
        });
      }

      return toDisplay;
    }
  },
  methods: {
    onClick(cat) {
      this.$store.commit('category/update', { prop: 'id', value: cat.id });
      this.$store.commit('category/update', { prop: 'data', value: cat });
    }
  }
}
</script>

<style lang="scss" scoped>
.category {
  @include padding(15px);
  display: flex;
  align-items: center;
  position: relative;
  width: 100%;
  border: 1px solid var(--grey-border);
  border-radius: 8px;
  background-color: var(--white);

  // Is input
  &-input {
    &:checked + .category-label {
      .category-label-check {
        background-color: var(--primary);

        .svg {
          opacity: 1;
        }
      }
    }
  }

  &-label {
    display: flex;
    align-items: flex-start;
    transition: color .3s $ease-out-quart;

    &:is(label) {
      cursor: pointer;

      &:hover {
        color: var(--primary);
      }

      &::before {
        @include position(absolute, 0 null null 0);
        @include size(100%);
        content: '';
        z-index: 1;
      }
    }

    &-check {
      @include margin(4px 8px null null);
      @include size(16px);
      content: '';
      position: relative;
      border-radius: 50%;
      background-color: var(--white-dark);

      .svg {
        fill: var(--white);
        opacity: 0;
      }
    }
  }

  // Not input
  &-link {
    @include position(absolute, 0 null null 0);
    @include size(100%);
    z-index: 7;

    &:focus,
    &:hover {
      ~ .category-label {
        color: var(--primary);
      }
    }
  }

  // Right
  &-right {
    display: flex;
    margin-left: auto;
  }

  &-lawyers {
    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-end;
  }

  &-lawyer {
    position: relative;

    &:not(:last-child) {
      margin-left: -8px;
    }

    @for $i from 1 through 4 {
      &:nth-child(#{$i}) {
        z-index: 4 - $i;
      }
    }

    &-profile {
      @include size(32px);
      display: block;
      overflow: hidden;
      border-radius: 50%;
      border: 1px solid var(--white);
      background-color: var(--white-darkest);
      color: var(--black);
      font-size: rem(10px);
      line-height: 30px;
      text-align: center;

      img {
        @include size(30px);
        display: block;
        border-radius: 50%;
      }
    }
  }

  &-toggle {
    @include size(32px);
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    z-index: 8;
    margin-left: 16px;
    border-radius: 10px;
    background-color: var(--white-dark);

    &:focus,
    &:hover {
      .category-toggle-dots {
        background-color: var(--primary);

        &::before {
          background-color: var(--primary);
        }

        &::after {
          background-color: var(--primary);
          // transition-delay: .1s;
        }
      }
    }

    &-dots {
      @include size(3px);
      position: relative;
      border-radius: 50%;
      background-color: var(--grey);
      transition: background-color .2s $ease-out-quart;
      transition-delay: .05s;

      &::before,
      &::after {
        @include position(absolute, 0 null null null);
        @include size(100%);
        content: '';
        border-radius: 50%;
        background-color: var(--grey);
        transition: background-color .2s $ease-out-quart;
      }

      &::before {
        right: 200%;
      }

      &::after {
        left: 200%;
        transition-delay: .1s;
      }
    }
  }
}
</style>