export function isDescendant(parent, child) {
  if (parent === child) return true;

  let node = child.parentNode;
  while (node) {
    if (node === parent) return true;
    node = node.parentNode;
  }

  return false;
}