<template>
  <div class="onboarding">
    <h2 class="onboarding-title">{{ $t('legalcases.onboarding.title') }}</h2>
    <p class="onboarding-text">{{ $t('legalcases.onboarding.text') }}</p>

    <div
      v-for="(step, index) in steps"
      :key="`onboarding-step-${index}`"
      class="onboarding-step"
    >
      <span v-if="!step.done" class="onboarding-step-dot">{{ index + 1 }}</span>
      <span v-else class="onboarding-step-dot checked">
        <Sprite :width="22" :height="22" type="check"/>
      </span>

      <p class="onboarding-step-text">{{ step.text }}</p>
      <Btn
        :disabled="step.disabled"
        :theme="step.done ? 'ghost' : ''"
        :to="step.to"
        :href="!step.disabled ? step.href : null"
        :target="step.target"
        @onClick="step.onClick ? step.onClick($event) : null"
      >
        {{ step.disabled
            ? step.buttonDisabled
            : step.done
              ? step.buttonDone
              : step.button
        }}
      </Btn>
    </div>
  </div>
</template>

<script>
import Btn from '@/components/elements/Btn';
import Sprite from '@/components/elements/Sprite';

export default {
  components: {
    Btn,
    Sprite
  },
  computed: {
    steps() {
      const { onboarding } = this.$store.state.user.data;
      if (!onboarding) return [];
      const steps = [
        {
          text: this.$t("legalcases.onboarding.step1.text"),
          button: this.$t("legalcases.onboarding.step1.button"),
          buttonDone: this.$t("legalcases.onboarding.step1.button-done"),
          done: onboarding.has_user_set_their_skills,
          to: { name: 'CategoriesUpdate' }
        },
        {
          text: this.$t("legalcases.onboarding.step2.text"),
          button: this.$t("legalcases.onboarding.step2.button"),
          buttonDone: this.$t("legalcases.onboarding.step2.button"),
          buttonDisabled: this.$t("legalcases.onboarding.step2.button-disabled"),
          disabled: onboarding.has_user_set_their_skills === false,
          done: onboarding.has_user_set_casus_on_their_site,
          onClick: async (e) => {
            e.preventDefault();
            window.open('https://symplicy.com/documentation/installation/');
            await this.$store.dispatch('user/updateOnboarding', 'casus');
            this.$store.dispatch('user/getUserOnboardingStatus');
          }
        },
        {
          text: this.$t("legalcases.onboarding.step3.text"),
          button: this.$t("legalcases.onboarding.step3.button"),
          buttonDone: this.$t("legalcases.onboarding.step3.button"),
          buttonDisabled: this.$t("legalcases.onboarding.step3.button-disabled"),
          disabled: onboarding.has_user_set_their_skills === false,
          done: onboarding.has_done_onboarding,
          onClick: async (e) => {
            e.preventDefault();
            window.open('https://symplicy.com/documentation/lien-magique/');
            await this.$store.dispatch('user/updateOnboarding');
            this.$store.dispatch('user/getUserOnboardingStatus');
          }
        }
      ];

      return steps;
    },
  }
}
</script>

<style lang="scss" scoped>
.onboarding {
  @include padding(16px);
  border-radius: 8px;
  background-color: var(--white);

  &-title {
    font-size: rem(20px);
    font-weight: 600;
    line-height: (32 / 20);
  }

  &-text {
    margin-top: 8px;
    font-size: rem(12px);
    line-height: 1.25;
    opacity: .5;
  }

  &-step {
    display: grid;
    grid-template-columns: 24px 1fr;
    grid-template-rows: repeat(3, auto);
    justify-items: flex-start;
    column-gap: 16px;
    margin-top: 32px;

    &-dot {
      @include size(24px);
      grid-row: 1 / -1;
      border-radius: 50%;
      border: 1px solid var(--grey-lighter);
      background-color: var(--white-dark);
      color: var(--grey-darker);
      font-size: rem(12px);
      font-weight: 500;
      line-height: 24px;
      text-align: center;

      .svg {
        fill: var(--white);
      }

      &.checked {
        border-color: var(--primary);
        background-color: var(--primary);
      }
    }

    .button {
      margin-top: 8px;
    }
  }
}
</style>