<template>
  <transition name="fade">
    <div v-if="isVisible && notifications.length > 0" class="notifications">
      <span
        v-for="(item, i) in notifications"
        :key="`notifications-${item.text.replace(' ', '')}-${i}`"
        :class="`notifications-item ${item.type}`"
      >
        <Sprite
          v-if="item.type === 'alert' || item.type === 'error'"
          :type="'close-big'"
          :width="17"
          :height="17"
        />
        <Sprite
          v-else
          :type="'check'"
          :width="20"
          :height="20"
        />
        <span class="text">{{ item.text }}</span>
        <Sprite
          :type="'close'"
          :width="12"
          :height="12"
          @click="close(item)"
        />
      </span>
    </div>
  </transition>
</template>

<script>
import Sprite from '@/components/elements/Sprite';
import Notifications from '@/services/notifications';

export default {
  components: {
    Sprite
  },
  data() {
    return {
      notificationsClass: null,
    }
  },
  beforeMount() {
    this.notificationsClass = new Notifications(this);
  },
  beforeUnmount() {
    this.notificationsClass.unbindEvents();
    this.notificationsClass = null;
  },
  computed: {
    notifications() {
      return this.$store.state.notifications.items;
    },
    isVisible() {
      return this.$store.state.notifications.isVisible;
    }
  },
  methods: {
    close(item) {
      this.$store.commit('notifications/remove', item);
    }
  }
}
</script>

<style lang="scss" scoped>
.notifications {
  @include position(fixed, null 24px 68px null);
  @include padding(8px 16px 6px);
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 1001;
  width: calc(100% - 48px);
  border-radius: 4px;
  border: 1px solid var(--grey-lightest);
  background-color: var(--white);
  box-shadow: 0 4px 16px rgba($black, .3);

  @include mq($from: 'l') {
    width: 360px;
    bottom: auto;
    top: 80px;
  }

  &-item {
    @include padding(10px 26px 12px);
    // display: flex;
    position: relative;
    width: 100%;
    color: var(--black);
    font-size: rem(14px);
    font-weight: 500;
    line-height: 1.3;
    text-align: left;

    &:not(:first-child) {
      // @include margin(12px null null);
      border-top: 1px solid var(--grey-lightest);
    }

    .text {
      margin-right: 10px;
    }

    .svg {
      // Success
      &.svg-check {
        @include position(absolute, 8px null null 0);
      }

      // Error - Warning
      &.svg-close-big {
        @include position(absolute, 10px null null 0);
      }

      &.svg-close {
        @include position(absolute, 50% 0 null null);
        transform: translateY(-50%);
        transition: fill .3s $ease-out-quart;
        fill: var(--grey-light);
        cursor: pointer;

        &:hover {
          fill: var(--primary);
        }
      }
    }

    &.error,
    &.alert {
      .svg:not(.svg-close) {
        fill: var(--alert);
      }
    }

    &.success {
      .svg:not(.svg-close) {
        fill: var(--success);
      }
    }
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity .4s $ease-out-quart, transform .4s $ease-out-quart;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
  transform: translateY(5px);
}

</style>
