<template>
  <div class="popup">
    <div class="popup-wrapper">
      <div class="popup-header">
        <h2 v-if="title" class="popup-title">{{ title }}</h2>
        <button
          class="popup-close"
          @click="$emit('onClose')"
        >
          <Sprite :type="'close-big'"/>
        </button>
      </div>

      <div class="popup-content">
        <slot />
      </div>
    </div>
    <div class="popup-backdrop" @click="$emit('onClose')"/>
  </div>
</template>

<script>
import Sprite from '@/components/elements/Sprite';

export default {
  components: {
    Sprite
  },
  props: {
    title: {
      type: String,
      default: null
    }
  }
}
</script>

<style lang="scss" scoped>
.popup {
  @include position(fixed, 0 null null 0);
  @include size(100%);
  z-index: 1000;

  // Wrapper
  &-wrapper {
    @include position(absolute, 50% null null 50%);
    z-index: 1;
    width: 704px;
    max-width: calc(100% - 32px);
    max-height: calc(100% - 100px);
    border-radius: 8px;
    transform: translate(-50%, -50%);
    background-color: var(--white);
  }

  // Header
  // -----------------------------------------------------------------------------
  &-header {
    @include padding(16px);
    display: flex;
    align-items: center;
    border-bottom: 1px solid var(--grey-border);
  }

  &-title {
    color: var(--primary);
    font-size: rem(18px);
    font-family: $alternate;
    font-weight: 600;
    line-height: (24 / 18);
  }

  &-close {
    @include size(32px);
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: auto;
    border-radius: 10px;
    flex-shrink: 0;
    background-color: var(--white-dark);
    cursor: pointer;
    transition: background-color .3S $ease-out-quart;

    &:hover {
      background-color: var(--primary);

      .svg {
        fill: var(--white);
      }
    }

    .svg {
      fill: var(--grey);
      transition: fill .3s $ease-out-quart;
    }
  }

  // Content
  // -----------------------------------------------------------------------------
  &-content {
    @include padding(16px);
    display: flex;
    flex-direction: column;
    max-height: calc(100vh - 165px);
    overflow: auto;
  }

  // Backdrop
  // -----------------------------------------------------------------------------
  &-backdrop {
    @include position(absolute, 0 null null 0);
    @include size(100%);
    z-index: 0;
    background-color: rgba($black, .4);
  }
}
</style>
