<template>
  <div
    v-show="pages > 1"
    class="pagination"
  >
    <router-link
      v-if="current !== 1"
      :to="{
        path: $route.path,
        query: {
          page: current - 1
        }
      }"
      class="pagination-button prev"
    >
      <Sprite :type="'chevron-left'" :width="20" :height="20"/>
    </router-link>
    <span
      v-else
      class="pagination-button prev"
    >
      <Sprite :type="'chevron-left'" :width="20" :height="20"/>
    </span>
    <ul class="pagination-list">
      <li
        v-for="page in pages"
        :key="`pagination-${page}`"
        :class="['pagination-list-item', { 'current': current === page }]"
      >
        <router-link
          class="pagination-list-link"
          :to="{
            path: $route.path,
            query: {
              page,
            }
          }"
        >
          {{ page }}
        </router-link>
      </li>
    </ul>
    <router-link
      v-if="current !== pages"
      :to="{
        path: $route.path,
        query: {
          page: current + 1
        }
      }"
      class="pagination-button next"
    >
      <Sprite :type="'chevron-right'" :width="20" :height="20"/>
    </router-link>
    <span
      v-else
      class="pagination-button next"
    >
      <Sprite :type="'chevron-right'" :width="20" :height="20"/>
    </span>
  </div>
</template>

<script>
import Sprite from '@/components/elements/Sprite';

export default {
  name: 'Pagination',
  components: {
    Sprite
  },
  props: {
    total: {
      type: Number,
      required: true
    },
    current: {
      type: Number,
      required: true
    },
    perPage: {
      type: Number,
      required: true
    }
  },
  computed: {
    pages() {
      const pages = Math.ceil(this.total / this.perPage);
      return pages;
    }
  }
};
</script>

<style lang="scss" scoped>
.pagination {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;

  @include mq($from: 'm') {
    justify-content: center;
    padding: 0 30px 30px;
  }

  &-button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 34px;
    height: 34px;
    text-decoration: none;
    background: none;

    &:hover {
      .svg {
        fill: var(--primary);
      }
    }

    .svg {
      fill: var(--grey);
    }

    &.prev {
      margin-right: 12px;
    }

    &.next {
      margin-left: 12px;
    }
  }

  span.pagination-button {
    opacity: .3;
    pointer-events: none;
  }

  &-list {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;

    &-item {
      &:not(:first-child) {
        margin-left: 4px;
      }

      &.current {
        .pagination-list-link {
          color: var(--black);
        }
      }

      &:not(.current) {
        @include mq($until: 'm') {
          display: none;
        }
      }
    }

    &-link {
      display: block;
      height: 34px;
      padding: 0 4px;
      color: var(--grey);
      line-height: 34px;
      text-decoration: none;
      font-size: rem(14px);

      &:hover {
        color: var(--primary);
      }
    }
  }
}
</style>