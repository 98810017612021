<template>
  <div
    :class="[
      'input',
      { 'input--error': error },
      { 'input--disabled': disabled },
      { 'input--prepend': prependIcon },
      { 'input--append': appendIcon },
      classes
    ]"
  >
    <label
      v-if="label"
      class="label"
      :for="id"
    >
      {{ label }}
    </label>
    <div class="input-wrapper">
      <Sprite v-if="prependIcon" :type="prependIcon" class="input-icon input-icon--prependIcon"/>
      <input
        :id="id"
        :type="type"
        :value="value"
        :placeholder="placeholder"
        :disabled="disabled"
        :autocomplete="autocomplete"
        @input="onInput"
      >
      <Sprite v-if="appendIcon" :type="appendIcon" class="input-icon input-icon--append"/>
    </div>
    <span
      v-if="error"
      class="error"
    >
      {{ error }}
    </span>
  </div>
</template>

<script>
import Sprite from '@/components/elements/Sprite';

export default {
  components: {
    Sprite
  },
  props: {
    id: {
      type: String,
      required: true
    },
    value: {
      type: [String, Number],
      default: null
    },
    type: {
      type: String,
      default: 'text'
    },
    label: {
      type: String,
      default: null
    },
    placeholder: {
      type: String,
      default: null
    },
    autocomplete: {
      type: String,
      default: null
    },
    error: {
      type: String,
      default: null
    },
    disabled: {
      type: Boolean,
      default: false
    },
    classes: {
      type: String,
      default: ''
    },
    prependIcon: {
      type: String,
      default: null
    },
    appendIcon: {
      type: String,
      default: null
    }
  },
  methods: {
    onInput(e) {
      const { currentTarget } = e;
      const { value } = currentTarget;
      this.$emit('onInput', value);
    }
  }
};
</script>

<style lang="scss" scoped>
.input {
  display: flex;
  flex-direction: column;

  &--error {
    input {
      border-color: var(--alert);
    }
  }

  &--disabled {
    opacity: .5;
    pointer-events: none;

    .select-toggle {
      overflow: hidden;
    }
  }

  &--prepend {
    input {
      padding-left: 32px;
    }
  }

  &--append {
    input {
      padding-left: 32px;
    }
  }

  &-wrapper {
    position: relative;
  }

  &-icon {
    @include position(absolute, 8px null null);
    fill: var(--primary);

    &--prepend { left: 8px; }
    &--append { right: 8px; }
  }

  input {
    @include padding(7px);
    display: block;
    width: 100%;
    max-height: 37px;
    border: 1px solid var(--grey-border);
    border-radius: 8px;
    font-family: $sans-serif;
    font-size: rem(14px);
    line-height: (16 / 14);
    background-color: var(--white-dark);
    outline: none;
    transition: border-color .3s $ease-out-quart;
    appearance: none;

    &:focus,
    &:hover {
      border-color: var(--primary);
    }
  }

  .error {
    margin-top: 6px;
    color: var(--alert);
    font-size: rem(12px);
    line-height: 1;
  }
}
</style>