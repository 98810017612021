<template>
  <Popup
    v-if="visible"
    :title="$t('pages.office.lawyers.popup.title')"
    @onClose="onCloseInvite"
  >
    <form class="add-form" novalidate @submit.prevent="onInvite">
      <template v-if="success">
        <p class="add-form-success">{{ $t('pages.office.lawyers.popup.success') }}</p>
        <Btn @click="onOther" :disabled="disabled" type="button">{{ $t('actions.invite-other') }}</Btn>
      </template>
      <template v-else>
        <Input
          :placeholder="$t('labels.email')"
          :value="email.value"
          :error="email.error"
          autocomplete="email"
          type="email"
          id="email"
          @onInput="($event) => email.value = $event"
        />
        <Btn :disabled="disabled" type="submit">{{ $t('actions.invite') }}</Btn>
      </template>

      <div v-if="requests !== 0" class="loader-container">
        <Loader />
      </div>
    </form>
  </Popup>
</template>

<script>
import Btn from "@/components/elements/Btn";
import Input from "@/components/elements/Input";
import Loader from "@/components/elements/Loader";
import Popup from "@/components/partials/Popup";
import { calls } from '@/services/calls';

export default {
  components: {
    Btn,
    Input,
    Loader,
    Popup
  },
  props: {
    isVisible: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      visible: false,
      requests: 0,
      success: false,
      error: null,
      email: {
        value: '',
        error: null
      }
    }
  },
  computed: {
    disabled() {
      const { email } = this;
      // eslint-disable-next-line
      const regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return !email.value?.length || !regex.test(email.value);
    },
  },
  watch: {
    isVisible(value) {
      this.visible = value;
    }
  },
  methods: {
    onOther() {
      this.email.value = '';
      this.success = false;
    },
    async onInvite() {
      this.requests++;

      try {
        const request = await calls.post('inviteUser', { email: this.email.value });
        if (request.status === "success") {
          this.success = true;
        }
      } catch (error) {
        console.log('error');
      }

      this.requests--;
    },
    onClose() {
      this.visible = false;
      this.success = false;
      this.email.value = '';
    },
  }
};
</script>

<style lang="scss" scoped>
:deep(.popup-wrapper) {
  max-width: 576px;
}

.add-form {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: relative;

  &-success {
    width: 100%;
    margin-bottom: 16px;
    font-size: rem(18px);
    font-weight: 500;
    text-align: center;
  }

  .input {
    width: 100%;
    max-width: 312px;
  }

  .button {
    margin-left: auto;
  }

  .loader-container {
    @include position(absolute, 0 null null 0);
    @include size(100%);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;
    background-color: rgba(255, 255, 255, .5);
  }
}
</style>