<template>
  <div
    ref="container"
    :class="[
      'select',
      `select--${position}`,
      { 'select--open': open },
      { 'select--disabled': disabled },
      // { 'error': error }
    ]"
  >
    <label v-if="label" class="label">{{ label }}</label>
    <div class="select-custom">
      <button
        class="select-toggle"
        type="button"
        @click="onToggle"
      >
        <span class="select-toggle-text">{{ selected ? selected.label : placeholder}}</span>
      </button>

      <!-- Input for FE search -->
      <input
        v-show="hasSearch && open"
        ref="search"
        class="select-search"
        type="text"
        :placeholder="'Rechercher'"
        :value="search"
        @input="onSearch"
      />

      <!-- Input for API search -->
      <input
        v-show="hasSearchApi && open"
        ref="searchApi"
        class="select-search"
        type="text"
        :placeholder="'Rechercher'"
        :value="searchApi"
        @input="onSearchApi"
      />

      <div
        v-show="open"
        class="select-options"
      >
        <Loader v-if="loading"/>
        <ul v-else>
          <li v-if="allowNull && (!search || !search.length) && (!searchApi || !searchApi.length)">
            <!-- <li v-if="allowNull && (!search || !search.length) && (!searchApi || !searchApi.length)"> -->
            <button
              :class="[
                'select-option',
                { 'select-option--selected': !selected && !multiple }
              ]"
              value=""
              type="button"
              @click="onOptionClick()"
            >
              {{ placeholder }}
            </button>
          </li>
          <li
            v-for="option in displayedOptions"
            :key="option.value"
          >
            <button
              :value="option.value"
              :class="[
                'select-option',
                // { 'select-option--selected': value === option.value },
                { 'select-option--selected': (multiple && value.indexOf(option.value) >= 0) || (!multiple && value === option.value) },
              ]"
              type="button"
              @click="onOptionClick(option)"
            >
              {{ option.label ? option.label : option.value }}
            </button>
          </li>
          <li
            v-show="search && search.length && !displayedOptions.length"
            class="select-no-result"
          >
            Pas de résultat
          </li>
          <li
            v-show="searchApi && searchApi.length && !options.length"
            class="select-no-result"
          >
            Pas de résultat
          </li>
        </ul>
      </div>
    </div>

    <div class="select-wrapper">
      <select
        ref="input"
        :name="name"
        :required="required"
        :value="value"
        autocomplete="off"
      >
        <!-- @change="onChange" -->
        <option 
          v-if="allowNull"
          :selected="!selected"
          value=""
        >
          {{ placeholder }}
        </option>
        <option 
          v-for="option in options"
          :key="option.value"
          :value="option.value"
          :selected="value === option.value"
        >
          {{ option.label ? option.label : option.value }}
        </option>
      </select>
    </div>

    <strong 
      v-if="error"
      class="small error-msg"
    >
      <span>{{ error }}</span>
    </strong>
  </div>
</template>

<script>
/* eslint-disable */
import _ from 'lodash';
import Sprite from './Sprite';
import Loader from './Loader';
import { isDescendant } from '@/helpers';

export default {
  components: {
    Sprite,
    Loader
  },
  props: {
    value: {
      type: [String, Number, Array],
      default: null
    },
    label: {
      type: String,
      default: null
    },
    placeholder: {
      type: String,
      // required: true
    },
    name: {
      type: String,
      default: null
    },
    options: {
      type: Array,
      required: true
    },
    nullOption: {
      type: Boolean,
      default: false
    },
    required: {
      type: Boolean,
      default: false
    },
    error: {
      type: String,
      default: null
    },
    allowNull: {
      type: Boolean,
      default: false
    },
    hasSearch: {
      type: Boolean,
      default: false
    },
    hasSearchApi: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    multiple: {
      type: Boolean,
      default: false
    },
    position: {
      type: String,
      default: 'bottom'
    }
  },
  data() {
    return {
      open: false,
      search: null,
      searchApi: null,
      _documentClick: this.onDocumentClick.bind(this),
      debounceSearchRequest: _.debounce(this.submitSearch.bind(this), 300)
    }
  },
  computed: {
    selected() {
      if (!this.value) return null;
      if (this.multiple) return null;
      const selected = this.options.filter(o => o.value === this.value);
      return selected[0];
    },
    placeholderText() {
      if (this.selected) {
        if (this.selected.label) return this.selected.label;
        return this.selected.value;
      }

      if (this.hasSearch) return 'Rechercher';

      return this.placeholder;
    },
    displayedOptions() {
      let options = [...this.options];

      if (this.nullOption && this.placeholder) {
        options.unshift({
          label: this.placeholder,
          value: null
        });
      }

      if (!this.search || !this.search.length) return options;

      options = this.options.filter(o => {
        if (o.label) {
          const lower = o.label.toLowerCase();
          const index = lower.indexOf(this.search);
          return index >= 0;
        }

        return false;
      });

      return options;
    }
  },
  methods: {
    onOptionClick(option) {
      if (!this.multiple || (!option && this.multiple)) {
        this.open = false;
        document.removeEventListener('click', this._documentClick);
      }

      this.search = null;

      if (!this.multiple || (option && this.multiple)) {
        this.$emit('onChange', option ? option.value : null);
      }
    },
    onChange(e) {
      this.search = null;
      document.removeEventListener('click', this._documentClick);

      const { currentTarget } = e;
      const { value } = currentTarget;
      this.$emit('onChange', value);
    },
    onSearch(e) {
      const { value } = e.target;
      this.search = value;
    },
    onSearchApi(e) {
      const { value } = e.target;
      this.searchApi = value;

      if (value.length < 3) {
        this.submitSearch();
        return;
      }

      this.debounceSearchRequest();
    },
    submitSearch() {
      this.$emit('onSearch', this.searchApi);
    },
    onToggle() {
      this.open = !this.open;
      this.search = null;

      if (this.open) {
        document.addEventListener('click', this._documentClick);

        if (this.hasSearch) {
          setTimeout(() => {
            this.$refs.search.focus();
          }, 100);
        }

        if (this.hasSearchApi) {
          setTimeout(() => {
            this.$refs.searchApi.focus();
          }, 100);
        }
      } else {
        document.removeEventListener('click', this._documentClick);
      }
    },
    onDocumentClick(e) {
      const { target } = e;
      const descendant = isDescendant(this.$refs.container, target);
      if (!descendant && this.open) {
        this.onToggle();
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.select {
  // &-custom {}
  display: flex;
  flex-direction: column;
  position: relative;

  &--top {
    &.select--open {
      .select-toggle {
        border-radius: 0 0 4px 4px;
        border-bottom: 1px solid var(--grey-border);
        border-top: 0;

        &:before {
          top: unset;
          bottom: calc(100% - 1px);
        }
      }
    }

    .select-options {
      top: unset;
      bottom: 100%;
      border-radius: 4px 4px 0 0;
      border-top: 1px solid var(--grey-border);
      border-bottom: 0;
    }
  }

  &--disabled {
    opacity: .5;
    pointer-events: none;

    .select-toggle {
      overflow: hidden;
    }
  }

  &--open {
    z-index: 1;

    .select-toggle {
      border-radius: 4px 4px 0 0;
      border-bottom: 0;

      &:before {
        opacity: 1;
      }

      &:after {
        @include triangle(12px, var(--grey-lightest), 'up');
        border-top: 0;
        content: '';
      }
    }
  }

  // .label {
  //   margin-bottom: 8px;
  // }

  &-custom {
    position: relative;
  }

  &-toggle {
    display: block;
    position: relative;
    width: 100%;
    max-height: 37px;
    border: 1px solid var(--grey-border);
    border-radius: 4px;
    font-family: $sans-serif;
    font-size: rem(14px);
    line-height: 1.25;
    background-color: var(--white-dark);
    white-space: nowrap;
    overflow: hidden;
    // transition: border-color .3s $ease-out-quart;
    text-align: left;
    cursor: pointer;

    &:before {
      @include position(absolute, calc(100% - 1px) null null 16px);
      @include size(calc(100% - 32px), 1px);
      content: '';
      opacity: 0;
      background-color: var(--grey-lightest);
      pointer-events: none;
    }

    &::after {
      @include position(absolute, calc(50% - 2px) 12px null null);
      @include triangle(12px, var(--grey-lightest), 'down');
      content: '';
    }

    &-text {
      @include padding(9px 0 9px 16px);
      display: block;
      max-width: calc(100% - 40px);
      white-space: nowrap;
      overflow: auto;
    }
  }

  &-search {
    @include position(absolute, 1px null null 1px);
    @include padding(8px 16px 8px);
    display: block;
    width: calc(100% - 40px);
    max-height: 37px;
    border: 0;
    border-top: 1px solid rgba(0, 0, 0, 0);
    border-radius: 4px 0 0 0;
    font-family: $sans-serif;
    font-size: rem(14px);
    line-height: 1.25;
    background-color: var(--white-dark);
    text-align: left;
    cursor: pointer;
    outline: none;
  }

  &-options {
    @include position(absolute, 100% null null 0);
    @include padding(5px null);
    display: flex;
    flex-direction: column;
    width: 100%;
    max-height: 150px;
    overflow: auto;
    border-radius: 0 0 4px 4px;
    border: 1px solid var(--grey-border);
    border-top: 0;
    background-color: var(--white-dark);

    .loader {
      margin: 0 auto;
    }
  }

  &-option {
    @include padding(5px 16px);
    display: block;
    width: 100%;
    transition: color .3s $ease-out-quart;
    text-align: left;
    cursor: pointer;

    &:hover {
      color: var(--primary);
    }

    &--selected {
      background-color: rgba($primary, .2);
    }
  }

  &-no-result {
    @include padding(5px 16px);
    font-style: italic;
  }

  &-wrapper {
    position: relative;
    display: none;

    &::before {
      @include triangle(14px, var(--grey-border), 'down');
      @include position(absolute, calc(50% - 2px) 16px null null);
      content: '';
    }
  }

  select {
    @include padding(9px 16px 9px);
    display: block;
    width: 100%;
    max-height: 37px;
    border: 1px solid var(--grey-border);
    border-radius: 8px;
    font-family: $sans-serif;
    font-size: rem(14px);
    line-height: 1.25;
    background-color: var(--white);
    outline: none;
    transition: border-color .3s $ease-out-quart;
    appearance: none;

    &:focus,
    &:hover {
      border-color: var(--primary);
    }
  }
}
</style>