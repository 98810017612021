import toolboxData from '@/data/static/toolbox.json';

const toolbox = {
  namespaced: true,
  state: {
    label: '',
    formatedLabel: '',
    color: toolboxData.colors[0],
    colors: toolboxData.colors
  },
  mutations: {
    updateColor(state, color) {
      state.color = color;
    },
    updateLabel(state, label) {
      state.label = label;
    },
    updateFormated(state, label) {
      state.formatedLabel = label
        .replace(/ /g, '_')
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '')
        .replace(/[^A-z0-9_]/g, '')
        .toLowerCase();
    }
  },
  actions: {}
}

export default toolbox;
