<template>
  <div class="page payment">
    <Header
      :title="$t(`pages.subscription.${step}.title`)"
      :text="text"
      sticky
    />

    <div class="payment-view">
      <div v-if="loading" :class="`payment-view-loader ${step}`">
        <Loader/>
      </div>

      <!-- Plans -->
      <div v-show="step === 'plans'" class="payment-plans">
        <Plan
          v-for="(plan, index) in plans"
          :key="`plan-${plan.id}`"
          :plan="plan"
          :highlighted="plan.name.match('Plus')"
          :contact="plan.id === 'custom'"
          :more="index > 1 ? $t('plans.more_advantages', { plan: plans[index - 1].name }) : false"
          @onClick="onOption"
        />
      </div>

      <!-- Stripe (card & billing) -->
      <form
        v-show="step.match(/payment|billing/)"
        id="payment-form"
        class="payment-form box"
        novalidate
        @submit.prevent="onStripeSubmit"
      >
        <div class="payment-form-wrapper">
          <div id="payment-elements" :class="`payment-form-elements${step === 'payment' ? ' is-visible' : ''}`"/>
          <div id="billing-elements" :class="`payment-form-elements${step === 'billing' ? ' is-visible' : ''}`"/>
          <div :class="`payment-form-elements vat${step === 'billing' ? ' is-visible' : ''}${addressReady ? ' is-ready' : ''}`">
            <label>Numéro de TVA</label>
            <input :value="vat" @input="onVat"/>
          </div>
        </div>
        <Btn :disabled="disabledNext" type="submit">{{ $t("actions.continue") }}</Btn>
      </form>

      <!-- Recap -->
      <div v-if="step === 'recap'" class="payment-recap">
        <div v-if="plan" class="payment-recap-box box">
          <div class="payment-recap-box-left">
            <p v-if="plan">Symplicy {{ $t(`plans.${plan.shortcode}.title`) }}</p>
            <p v-if="plan">{{ $t(`plans.${plan.shortcode}.size`) }}</p>
          </div>
          <div class="payment-recap-box-right">
            <p v-if="plan">
              <strong>{{ $t(`plans.${plan.shortcode}.price`) }}</strong>
            </p>
          </div>
        </div>
        <div class="payment-recap-box box">
          <div class="payment-recap-box-left">
            <p>{{ $t(`pages.subscription.payment.title`) }}</p>
          </div>
          <div class="payment-recap-box-right">
            <p>
              <strong>{{ $te(`pages.subscription.recap.methods.${recap.payment}`) ? $t(`pages.subscription.recap.methods.${recap.payment}`) : recap.payment }}</strong>
            </p>
          </div>
        </div>
        <div class="payment-recap-box box">
          <div class="payment-recap-box-left">
            <p>{{ $t(`pages.subscription.billing.title`) }}</p>
          </div>
          <div v-if="recap.address" class="payment-recap-box-right">
            <p><strong>{{ recap.address.line1 }}</strong></p>
            <p v-if="recap.address.line2"><strong>{{ recap.address.line2 }}</strong></p>
            <p><strong>{{ recap.address.postal_code }}, {{ recap.address.city }}</strong></p>
            <p><strong>{{ vat }}</strong></p>
          </div>
        </div>
        <Btn @onClick="onSubscribe">{{ $t('actions.subscribe') }}</Btn>
      </div>
    </div>
  </div>
</template>


<script>
import Btn from "@/components/elements/Btn";
import Input from "@/components/elements/Input";
import Loader from "@/components/elements/Loader";
import Plan from "@/components/modules/Plan";
import Header from "@/components/modules/Header";
import { loadStripe } from '@stripe/stripe-js';
import { checkVAT, belgium, france, germany, netherlands, luxembourg } from 'jsvat';

export default {
  components: {
    Btn,
    Plan,
    Loader,
    Input,
    Header
  },
  async mounted() {
    this.getData();
  },
  data() {
    return {
      step: 'plans', // plans, payment, billing, recap

      stripe: null,
      elements: null,
      paymentElement: null,
      addressElement: null,
      loadingStripe: false,
      vat: null,

      paymentComplete: false,
      addressComplete: false,
      addressReady: false,

      errors: {},
      loadingRecap: false,
      loadingSubscription: false,
      recap: {
        payment: 'card',
        address: null
      }
    };
  },
  computed: {
    loading() {
      const { options } = this.$store.state.subscription.requests;
      return this.loadingStripe || this.loadingRecap || options !== 0 || this.loadingSubscription;
    },
    plan() {
      const { price_id } = this.$store.state.subscription.form;
      const plan = this.plans.find(p => p.price_id === price_id);
      return plan;
    },
    plans() {
      const { options } = this.$store.state.subscription;
      return options;
    },
    text() {
      return this.step === 'plans' ? this.$t('pages.subscription.plans.title') : null;
    },
    disabledNext() {
      if (this.step === 'payment') return !this.paymentComplete;
      if (this.step === 'billing') {
        // const checkVat = checkVAT(this.vat, [belgium, france, germany, netherlands, luxembourg]);
        return !this.addressComplete;
        // return !this.addressComplete || !checkVat.isValid;
      }
      return false;
    }
  },
  watch: {
    step() {
      this.getData();
    }
  },
  methods: {
    getData() {
      switch (this.step) {
        case 'plans':
          this.$store.dispatch('subscription/getOptions')
          break;
        case 'payment':
          this.initStripe();
          break;
        case 'billing':
          this.initBilling();
          break;
        case 'recap':
          this.initRecap();
          break;
      }
    },
    async initStripe() {
      if (this.stripe && this.elements) return;  
      this.loadingStripe = true;
      await this.$store.dispatch('subscription/getIntent');
      this.stripe = await loadStripe(process.env.VUE_APP_STRIPE_KEY);
      const { intent } = this.$store.state.subscription;
      this.elements = this.stripe.elements({ clientSecret: intent.client_secret });
      this.initPayment();
      this.loadingStripe = false;
    },
    initPayment() {
      if (this.paymentElement) return;
      this.paymentElement = this.elements.create('payment');
      this.paymentElement.mount("#payment-elements");
      this.paymentElement.on('change', this.onPaymentChange.bind(this));
    },
    initBilling() {
      if (this.addressElement) return;
      this.addressElement = this.elements.create('address', { mode: 'billing' });
      this.addressElement.on('ready', this.onAddressReady.bind(this));
      this.addressElement.on('change', this.onAddressChange.bind(this));
      this.addressElement.mount("#billing-elements");
    },
    async initRecap() {
      if (!this.addressElement) return;
      this.loadingRecap = true;
      const req = await this.addressElement.getValue();
      const { address } = req.value;
      this.recap.address = address;
      this.loadingRecap = false;
    },
    onVat(e) {
      this.vat = e.target.value;
    },
    onOption(plan) {
      this.$store.commit('subscription/updateForm', { prop: 'price_id', value: plan.price_id });
      this.step = 'payment';
    },
    onStripeSubmit() {
      if (this.disabledNext) return;
      this.step = this.step === 'payment' ? 'billing' : 'recap';
    },
    onPaymentChange(e) {
      const { value, complete } = e;
      const { type } = value;
      this.recap.payment = type;
      this.paymentComplete = complete;
    },
    onAddressReady(e) {
      this.addressReady = true;
    },
    onAddressChange(e) {
      const { complete } = e;
      this.addressComplete = complete;
    },
    onSubscribe() {
      this.proceedPayment();
    },
    async proceedPayment() {
      this.loadingSubscription = true;
      const ad = await this.addressElement.getValue();
      const { setupIntent, error } = await this.stripe.confirmSetup({
        elements: this.elements,
        confirmParams: {
          return_url: `${window.location.origin}/subscription/checkout`,
        },
        redirect: 'if_required'
      });

      if (error) {
        if (error.type === "card_error" || error.type === "validation_error") console.log(error.message);
        else console.log("An unexpected error occured.");
      } else {
        this.$store.commit('subscription/updateForm', { prop: 'payment_method_id', value: setupIntent.payment_method });
        this.$store.commit('subscription/updateForm', { prop: 'vat', value: this.vat });
        await this.$store.dispatch('subscription/subscribe');
      }

      this.loadingSubscription = false;
    }
  },
};
</script>


<style lang="scss" scoped>
.payment {
  &-view {
    position: relative;

    &-loader {
      &.payment,
      &.billing,
      &.recap {
        @include position(absolute, 0 null null 0);
        @include size(100%);
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 1;
      }

      &.recap {
        ~ .payment-recap {
          opacity: .5;
        }
      }
    }
  }

  // Plans
  &-plans {
    display: grid;
    grid-gap: 16px;
    grid-template-columns: 1fr;

    @include mq($from: 'm') {
      grid-template-columns: repeat(2, 1fr);
    }

    @include mq($from: 'l-big') {
      grid-template-columns: repeat(4, 1fr);
    }
  }

  // Card & Address
  &-form {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    min-height: 100px;

    &-wrapper {
      width: 100%;
      max-width: 496px;
    }

    &-elements {
      width: 100%;

      &:not(.is-visible) {
        display: none;
      }

      &.vat {
        transition: opacity .6s linear;

        &:not(.is-ready) {
          opacity: 0;
        }

        label {
          @include margin(12px null 4px);
          display: block;
          font-size: 14.88px;
          transition: transform 0.5s cubic-bezier(0.19, 1, 0.22, 1), opacity 0.5s cubic-bezier(0.19, 1, 0.22, 1);
        }

        input {
          display: block;
          width: 100%;
          padding: 12px;
          background-color: var(--white);
          border-radius: 5px;
          transition: background 0.15s ease, border 0.15s ease, box-shadow 0.15s ease, color 0.15s ease;
          border: 1px solid #e6e6e6;
          box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.03), 0px 3px 6px rgba(0, 0, 0, 0.02);
          font-size: rem(16px);
          color: rgb(48, 49, 61);

          &:focus {
            outline: 0px;
            border-color: rgba(5, 115, 225, 0.5);
            box-shadow: rgba(0, 0, 0, 0.03) 0px 1px 1px 0px, rgba(0, 0, 0, 0.02) 0px 3px 6px 0px, rgba(5, 115, 225, 0.25) 0px 0px 0px 3px, rgba(0, 0, 0, 0.08) 0px 1px 1px 0px;
          }
        }
      }
    }

    .button {
      @include margin(32px null null auto);

      @include mq($until: 'm') {
        width: 100%;
        margin-top: 20px;
      }
    }
  }

  &-recap {
    &-box {
      display: flex;
      justify-content: space-between;
      margin-bottom: 16px;

      @include mq($until: 'm') {
        flex-direction: column;
      }
    }

    &-left {
      flex-grow: auto;
    }

    &-right {
      flex-shrink: 0;

      p {
        text-align: right;
      }
    }

    .button {
      display: block;
      margin-left: auto;

      @include mq($until: 'm') {
        width: 100%;
      }
    }
  }
}
</style>