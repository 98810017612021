import router from '../router';
import { calls } from '@/services/calls';
import { dispatchNotif } from '@/services/notifications';

const subscription = {
  namespaced: true,
  state: {
    requests: {
      options: 0,
      intent: 0,
      subscribe: 0
    },
    options: [],
    intent: null,
    form: {
      price_id: null,
      payment_method_id: null,
      vat: null,
      ////
      // name: null,
      // email: null,
      // street: null,
      // number: null,
      // box: null,
      // city: null,
      // postal_code: null,
      // country: null,
      // vat: null
    }
  },
  mutations: {
    update(state, { prop, value }) {
      state[prop] = value;
    },
    updateForm(state, { prop, value }) {
      state.form[prop] = value;
    },
    updateCard(state, { prop, value }) {
      state.form.card[prop] = value;
    },
    incRequests(state, type) {
      state.requests[type]++;
    },
    decRequests(state, type) {
      state.requests[type]--;
    }
  },
  actions: {
    async getIntent({ commit }) {
      commit('incRequests', 'intent');

      const req = await calls.get('getIntent');

      if (req.status === 'success') {
        commit('update', { prop: 'intent', value: req.data });
      }

      commit('decRequests', 'intent');
      return 
    },
    async getOptions({ state, commit }) {
      commit('incRequests', 'options');
      const request = await calls.get('getOptions');

      if (request.status === 'success') {
        let { data } = request;
        data.push({ name: 'Symplicy Custom', id: "custom" });
        data = data.map(p => {
          p.shortcode = p.name.toLowerCase().replace(/ /g, '_')
          return p;
        });

        if (!state.form.option) commit('updateForm', { prop: 'option', value: data[0].id });
        commit('update', { prop: 'options', value: data });
      }
      commit('decRequests', 'options');
    },
    async subscribe({ state, commit }) {
      commit('incRequests', 'subscribe');

      const { payment_method_id, price_id, vat } = state.form;
      const req = await calls.post('subscribe', { payment_method_id, price_id, vat });

      if (req.status === 'success') {
        dispatchNotif({ type: 'success', text: 'Vous êtes désormais abonné !' })
        router.push({ name: 'OfficeSubscription' });
      }

      commit('decRequests', 'subscribe');
    }
  }
}

export default subscription;
