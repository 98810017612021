import { calls, endpoints } from '@/services/calls';
import { dispatchNotif } from '@/services/notifications';

const category = {
  namespaced: true,
  state: {
    requests: 0,
    id: null,
    lawcases: [],
    lawyers: [],
    skillsSearch: null
  },
  mutations: {
    update(state, { prop, value }) {
      state[prop] = value;
    },
    updateData(state, { prop, value }) {
      state.data[prop] = value;
    },
    incRequests(state) {
      state.requests++;
    },
    decRequests(state) {
      state.requests--;
    }
  },
  actions: {
    async getLawcases({ state, commit }) {
      commit('incRequests');
      const url = endpoints.getCategoryLawcases.replace('{id}', state.id);
      const request = await calls.get(url);
      if (request.status === 'success') {
        const { data } = request;
        const ids = [];
        const lawcases = data.filter(l => {
          const index = ids.indexOf(l.id);
          if (index < 0) {
            ids.push(l.id);
            return true;
          }

          return false;
        });

        lawcases.sort((a, b) => {
          if (a.name < b.name) return -1;
          else if (a.name > b.name) return 1;
          return 0;
        });

        commit('update', { prop: 'lawcases', value: lawcases });
      }
      commit('decRequests');
    },
    async getLawyers({ state, commit }) {
      commit('incRequests');
      const url = endpoints.getCategoryLawyers.replace('{id}', state.id);
      const request = await calls.get(url);
      if (request.status === 'success') {
        commit('update', { prop: 'lawyers', value: request.data });
      }
      commit('decRequests');
    }
    // Work, but not needed for now
    // async getSkills({ state, commit }) {
    //   commit('incRequests');
    //   const url = endpoints.getCategorySkills.replace('{id}', state.id);
    //   const request = await calls.get(url);
    //   if (request.status === 'success') {
    //     commit('update', { prop: 'lawcases', value: request.data });
    //   }
    //   commit('decRequests');
    // }
  }
}

export default category;
