const notifications = {
  namespaced: true,
  state: {
    isVisible: true,
    timeout: null,
    items: [],
    historic: []
  },
  mutations: {
    updateVisibility(state, flag) {
      state.isVisible = flag;
    },
    remove(state, notif) {
      const index = state.items.indexOf(notif);

      if (index >= 0) {
        state.historic.push({ ...state.items[index] });
        state.items.splice(index, 1);
      }

      if (state.items.length === 0) {
        state.isVisible = false;
      }
    },
    add(state, notif) {
      state.items.push(notif);
    },
    updateTimeout(state) {
      if (state.timeout) {
        clearTimeout(state.timeout);
      }

      state.timeout = setTimeout(() => {
        state.isVisible = false;
      }, 6000);
    }
  },
  actions: {
    addNotifs({ commit }, notifs) {
      for (let i = 0; i < notifs.length; i++) {
        const notif = notifs[i];
        commit('add', notif);

        setTimeout(() => {
          commit('remove', notif);
        }, 6000);
      }

      commit('updateVisibility', true);
    }
  }
}

export default notifications;
