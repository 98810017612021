<template>
  <div class="informations">
    <Header
      :title="$t('pages.office.informations.title')"
      :actions="[
        {
          label: $t('actions.save'),
          action: onSave,
          disabled: disabled
        }
      ]"
    />

    <div class="informations-wrapper">
      <div class="informations-name box">
        <div class="informations-row">
          <label for="cabinet-name">{{ $t("labels.company-name") }}</label>
          <DataInput
            :value="client.name"
            :disabled="!isAdmin"
            id="cabinet-name"
            @onInput="onInput($event, 'name')"
          />
        </div>

        <div class="informations-row">
          <p class="label">{{ $t("labels.uuid") }}</p>
          <p class="datainput">{{ client.uuid }}</p>
        </div>
      </div>

      <template v-if="isAdmin">
        <h2 class="informations-subtitle">
          {{ $t("pages.office.informations.sending-methods.title") }}
        </h2>
        <p class="informations-text">
          {{ $t("pages.office.informations.sending-methods.text") }}
        </p>

        <div class="informations-methods box">
          <Radio
            :vertical="true"
            :value="client.mail_system"
            :name="'methods'"
            :options="[
              {
                label: $t('labels.method-lawyers'),
                value: 2
              },
              {
                label: $t('labels.method-box'),
                value: 1
              },
            ]"
            @onChange="onInput($event, 'mail_system')"
          />
          <Input
            v-if="client.mail_system === 1"
            :placeholder="'Email'"
            :value="client.email_box"
            :error="emailError"
            @onInput="onInput($event, 'email_box')"
          />
        </div>
      </template>

      <div v-if="loading" class="informations-loader">
        <Loader/>
      </div>
    </div>
  </div>
</template>

<script>
import DataInput from "@/components/elements/DataInput";
import Radio from "@/components/elements/Radio";
import Loader from "@/components/elements/Loader";
import Header from "@/components/modules/Header";
import Input from '@/components/elements/Input.vue';

export default {
  components: {
    DataInput,
    Radio,
    Loader,
    Header,
    Input
  },
  data() {
    return {
      emailError: null
    }
  },
  computed: {
    user() {
      const { data } = this.$store.state.user;
      return data;
    },
    client() {
      const { data } = this.$store.state.client;
      return data;
    },
    loading() {
      return this.$store.state.client.requests.name !== 0;
    },
    isAdmin() {
      return this.user.id === this.client.admin_id
    },
    disabled() {
      const { data, originalData } = this.$store.state.client;
      if (data.name !== originalData.name) return false;
      if (data.mail_system !== originalData.mail_system) {
        if (data.mail_system === 1) {
          // eslint-disable-next-line
          const regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return !regex.test(data.email_box);
        }

        return false;
      }

      return this.loading;
    }
  },
  methods: {
    onSave() {
      this.$store.dispatch('client/setClientName');
      this.$store.dispatch('client/setMailSystem');
    },
    onInput(value, prop) {
      this.$store.commit('client/updateData', { prop, value });
    },
    // onMethods(value) {
    //   this.$store.commit('client/updateData', { prop: 'mail_system', value });
    // }
  },
};
</script>


<style lang="scss" scoped>
.informations {
  &-wrapper {
    position: relative;
  }

  &-name {
    .label,
    label {
      width: 100%;
      line-height: (32 / 14);

      @include mq($from: 'm') {
        width: calc(50% - 8px);
        max-width: 264px;
        margin-right: 16px;
      }
    }

    .datainput {
      width: 100%;
      
      &:is(p) {
        font-weight: 700;
      }

      @include mq($from: 'm') {
        width: calc(50% - 8px);
        max-width: 360px;

        &:is(div) {
          margin-left: -8px;
        }
      }
    }
  }

  &-row {
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    
    + .informations-row {
      margin-top: 16px;

      @include mq($until: 'm') {
        flex-direction: column;
        margin-top: 8px;
      }
    }
  }

  &-subtitle {
    margin-top: 16px;
    font-size: 16px;
    font-weight: 700;
    line-height: (24 / 16);
  }

  &-text {
    color: var(--grey-darker);
    margin-bottom: 8px;
  }

  &-methods {
    .input {
      margin-top: 8px;
      max-width: 354px;
      padding-left: 24px;
    }
  }

  // Loader
  &-loader {
    @include position(absolute, 0 null null 0);
    @include size(100%);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1;
    background-color: rgba($white-dark, .7);
  }
}
</style>