<template>
  <div class="profile">
    <Header
      :title="$t('pages.account.profile.title')"
      :actions="[
        {
          label: $t('actions.save'),
          action: onSave,
          disabled
        },
      ]"
    />

    <div class="profile-wrapper">
      <div class="profile-picture">
        <input
          id="picture-id"
          class="profile-picture-input u-visually-hidden"
          accept=".png,.jpg,.jpeg"
          type="file"
          @change="onPicture"
        />
        <label for="picture-id" class="profile-picture-label">
          <span :class="`profile-picture-thumb${!user.profile_picture_url ? ' empty' : ''}`">
            <img
              v-if="user.profile_picture_url"
              :src="user.profile_picture_url"
            />
            <Sprite v-else type="plus" :width="36" :height="36"/>
          </span>
          {{ $t("actions.modify-picture") }}
        </label>
      </div>

      <div class="profile-informations">
        <h2 class="h3">{{ $t("pages.account.profile.informations.title") }}</h2>
        <form class="profile-informations-form box" @submit.prevent="onSave">
          <div class="profile-informations-row">
            <label for="user-firstname">{{ $t("labels.firstname") }}</label>
            <DataInput
              :value="user.lawyer_firstname"
              id="user-firstname"
              @onInput="onInput($event, 'lawyer_firstname')"
            />
          </div>
          <div class="profile-informations-row">
            <label for="user-lastname">{{ $t("labels.lastname") }}</label>
            <DataInput
              :value="user.lawyer_lastname"
              id="user-lastname"
              @onInput="onInput($event, 'lawyer_lastname')"
            />
          </div>
          <div class="profile-informations-row">
            <label for="user-email">{{ $t("labels.email") }}</label>
            <DataInput
              :value="user.email"
              id="user-email"
              @onInput="onInput($event, 'email')"
            />
          </div>

          <!-- <Btn type="button" theme="ghost-faded">{{ $t("actions.update-password") }}</Btn> -->
        </form>

        <div class="profile-other">
          <h2 class="h3">{{ $t("pages.account.profile.other.title") }}</h2>
          <Btn theme="alert" href="mailto:info@symplicy.com">{{ $t("actions.delete-account") }}</Btn>
        </div>
      </div>

      <div v-if="loading" class="profile-loader">
        <Loader/>
      </div>
    </div>
  </div>
</template>

<script>
import Btn from "@/components/elements/Btn";
import Sprite from "@/components/elements/Sprite";
import Loader from "@/components/elements/Loader";
import DataInput from "@/components/elements/DataInput";
import Header from "@/components/modules/Header";

export default {
  components: {
    Btn,
    Sprite,
    Loader,
    DataInput,
    Header,
  },
  data() {
    return {
      fields: ['lawyer_firstname', 'lawyer_lastname', 'email', 'profile_picture_url']
    }
  },
  computed: {
    user() {
      const { data } = this.$store.state.user;
      return data;
    },
    loading() {
      const { detail, picture } = this.$store.state.user.requests;
      return detail !== 0 || picture !== 0;
    },
    disabled() {
      const { data, originalData } = this.$store.state.user;
      let hasEmptyField = false;
      let hasChangedField = false;

      for (let i = 0; i < this.fields.length; i++) {
        const field = this.fields[i];
        if (!data[field] && field !== 'profile_picture_url') hasEmptyField = true;
        if (data[field] !== originalData[field]) hasChangedField = true;
      }

      return (hasEmptyField || !hasChangedField) || this.loading;
    },
  },
  methods: {
    onInput(value, prop) {
      this.$store.commit("user/updateDataProp", { prop, value });
    },
    onPicture(e) {
      const $input = e.currentTarget;
      const files = $input.files;
      const accept = /\.png|\.jpg|\.jpeg/g;

      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        const lower = file.name.toLowerCase();
        const extAccepted = lower.match(accept);

        if (!extAccepted) {
          window.alert("File not supported :", file.name);
        } else {
          const url = URL.createObjectURL(file);
          this.onInput(url, 'profile_picture_url');
          this.$store.commit('user/update', { prop: 'profilePicture', value: file });
        }
      }
    },
    onSave(e) {
      this.$store.dispatch('user/updateUser');
      this.$store.dispatch('user/updatePicture');
    },
  },
};
</script>

<style lang="scss" scoped>
.profile {
  &-wrapper {
    display: grid;
    grid-template-columns: auto 1fr;
    column-gap: 65px;
    position: relative;

    @include mq($until: 'm') {
      grid-template-columns: 1fr;
    }
  }

  &-loader {
    @include position(absolute, 0 null null 0);
    @include size(100%);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1;
    background-color: rgba($white-dark, .7);
  }

  // Picture
  &-picture {
    display: flex;
    flex-direction: column;
    width: 182px;

    @include mq($until: 'm') {
      justify-self: center;
      margin-bottom: 32px;
    }

    &-label {
      width: 100%;
      color: var(--primary);
      font-size: rem(14px);
      font-weight: 700;
      line-height: 40 / 14;
      text-align: center;
      cursor: pointer;

      &:hover {
        .profile-picture-thumb {
          .svg {
            fill: var(--primary);
          }
        }
      }
    }

    &-thumb {
      @include aspect-ratio(1, 1);
      display: block;
      width: 100%;
      position: relative;
      overflow: hidden;
      margin-bottom: 8px;
      border: 2px dashed rgba(200, 200, 200, 0);
      border-radius: 50%;
      // background-color: var(--grey-lightest);

      &.empty {
        border-color: var(--grey-lightest);
      }

      .svg {
        @include position(absolute, 50% null null 50%);
        transform: translate(-50%, -50%);
        fill: var(--grey-lightest);
      }

      img {
        @include position(absolute, 0 null null 0);
        @include size(100%);
        object-fit: cover;
        border-radius: 50%;
      }
    }
  }

  // Informations
  &-informations {
    .box {
      margin-top: 8px;
    }

    &-row {
      display: flex;
      align-items: flex-start;

      @include mq($until: 'm') {
        flex-direction: column;
        align-items: flex-start;
      }

      &:not(:first-child) {
        margin-top: 8px;
      }

      label {
        width: 100%;
        line-height: (32 / 14);

        @include mq($from: 'm') {
          width: calc(50% - 8px);
          max-width: 264px;
          margin-right: 16px;
        }
      }

      .datainput {
        width: 100%;

        @include mq($from: 'm') {
          width: calc(50% - 8px);
          max-width: 264px;
        }
      }
    }

    .button {
      margin-top: 16px;
    }
  }

  &-other {
    margin-top: 16px;

    .button {
      margin-top: 8px;
    }
  }
}
</style>