<template>
  <div class="list">
    <div v-if="loading" class="list-loader">
      <Loader/>
    </div>

    <template v-if="categories?.length">
      <Category
        v-for="cat in categories"
        :key="`cat-${cat.id}`"
        :category="cat"
      />
    </template>

    <p v-if="!categories?.length && !loading && search" class="noresult">{{ $t('pages.account.categories.list.no-result') }}</p>
    <div v-if="!categories?.length && !loading && !search" class="box">
      <p>{{ $t('pages.account.categories.list.empty.text') }}</p>
      <Btn :to="{ name: 'CategoriesUpdate' }">{{ $t('actions.update_categories') }}</Btn>
    </div>
  </div>
</template>


<script>
import Btn from "@/components/elements/Btn";
import Loader from "@/components/elements/Loader";
import Category from '@/components/elements/Category';

export default {
  components: {
    Btn,
    Loader,
    Category
  },
  computed: {
    search() {
      const { categoriesSearch } = this.$store.state.user;
      return categoriesSearch;
    },
    categories() {
      const { categories } = this.$store.state.user.data;
      return categories.filter(cat => {
        if(!this.search) return true;
        const description = cat.description.toLowerCase();
        return description.match(this.search);
      });
    },
    loading() {
      return this.$store.state.user.requests.categories !== 0;
    }
  }
}
</script>

<style lang="scss" scoped>
.list {
  position: relative;

  &-loader {
    display: flex;
    justify-content: center;

    &:not(:last-child) {
      @include position(absolute, 0 null null 0);
      @include size(100%);
      z-index: 1;
      background-color: rgba($white, .5);
    }
  }

  .noresult {
    margin-top: 32px;
  }

  .category {
    &:not(:first-child) {
      margin-top: 2px;
    }
  }

  .box {
    @include padding(40px);
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;

    .button {
      margin-top: 16px;
    }
  }
}
</style>