<template>
  <div
    :class="[
      'header',
      `header--${size}`,
      { 'header--light': light },
      { 'header--sticky': sticky },
    ]"
  >
    <div class="header-left">
      <h1 v-if="title" class="h1">{{ title }}</h1>
      <p v-if="text" class="header-text">{{ text }}</p>
      <p v-if="small" class="header-small">{{ small }}</p>

      <form v-if="search" class="header-search">
        <Input
          :placeholder="search"
          appendIcon="search"
          @onInput="$emit('onSearch', $event)"
        />
      </form>
    </div>

    <Btn v-if="mobileToggle" class="header-mobile-toggle" theme="icon" @onClick="$emit('onToggle')">
      <Sprite :type="typeof mobileToggle === 'string' ? mobileToggle : 'search'"/>
    </Btn>

    <div
      class="header-right"
      v-show="loading || select || righText || displayedActions?.length"
    >
      <div
        v-if="loading"
        class="header-loader"
      >
        <Loader />
      </div>
      <template v-else>
        <span
          v-if="rightText"
          class="header-right-text"
        >
          {{ rightText }}
        </span>
        <Select
          v-if="select"
          :value="null"
          :allowNull="true"
          :placeholder="select.placeholder"
          :options="select.options"
          :selected="select.selected"
          @onChange="select.action($event)"
        />
        <Btn
          v-for="(btn, index) in displayedActions"
          :key="`view-actions-${index}`"
          :theme="btn.theme"
          :disabled="btn.disabled"
          @onClick="onAction(btn)"
        >
          {{ typeof btn.label === 'function' ? btn.label() : btn.label }}
        </Btn>
      </template>
    </div>

    <span v-if="subtitle" class="header-subtitle">
      {{ subtitle }}
    </span>
  </div>
</template>

<script>
import Btn from '@/components/elements/Btn';
import Input from '@/components/elements/Input';
import Select from '@/components/elements/Select';
import Sprite from '@/components/elements/Sprite';
import Loader from '@/components/elements/Loader';

export default {
  components: {
    Btn,
    Input,
    Sprite,
    Select,
    Loader
  },
  props: {
    title: {
      type: String,
      default: null
    },
    text: {
      type: String,
      default: null
    },
    small: {
      type: String,
      default: null
    },
    subtitle: {
      type: String,
      default: null
    },
    actions: {
      type: [Array, Function],
      default: () => []
    },
    light: {
      type: Boolean,
      default: false
    },
    sticky: {
      type: Boolean,
      default: false
    },
    search: {
      type: Object,
      default: null
    },
    rightText: {
      type: String,
      default: null
    },
    select: {
      type: Object,
      default: null
    },
    loading: {
      type: Boolean,
      default: false
    },
    size: {
      type: String,
      default: 'regular'
    },
    mobileToggle: {
      type: [Boolean, String],
      default: false
    }
  },
  computed: {
    displayedActions() {
      if (typeof this.actions === 'function') {
        return this.actions();
      }

      return this.actions;
    }
  },
  methods: {
    onAction(btn) {
      btn.action();
    }
  }
};
</script>

<style lang="scss" scoped>
.header {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
  position: relative;
  margin-bottom: 16px;

  @include mq($until: 'l') {
    align-items: center;
    flex-wrap: nowrap;
  }

  &--sticky {
    @include mq($from: 'l') {
      @include padding(8px 5px);
      @include margin(-8px -5px 8px);
      position: sticky;
      top: -32px;
      z-index: 10;
      background-color: var(--white-dark);
    }
  }

  &--light {
    &::before {
      @include position(absolute, -32px 50% null null);
      @include size(100vw, calc(100% + 32px + 24px));
      content: '';
      z-index: -1;
      transform: translateX(50%);
      background-color: var(--white);
    }
  }

  &--large {
    .header-left {
      max-width: unset;
    }
  }

  // Left
  &-left {
    width: 100%;

    @include mq($from: 'm') {
      width: auto;
      max-width: 624px;
    }

    @include mq($from: 'l-big') {
      width: 75%;
    }
  }

  &-text {
    margin-top: 8px;
  }

  &-small {
    font-size: rem(12px);
    color: var(--grey-darker);
  }

  &-search {
    width: 100%;
    margin-top: 16px;

    @include mq($from: 'm', $until: 'l-big') {
      min-width: 320px;
    }

    :deep(input) {
      background-color: var(--white);
    }
  }

  // Mobile search
  &-mobile-toggle {
    margin-left: auto;
    flex-shrink: 0;

    @include mq($from: 'm') {
      display: none !important;
    }
  }

  // Right
  &-right {
    display: flex;
    position: relative;

    @include mq($until: 'm') {
      @include position(fixed, 8px 8px null null);
      justify-content: space-between;
      z-index: 101;
    }

    @include mq($from: 'm', $until: 'l') {
      margin-left: 20px;
    }

    &-text {
      display: block;
      padding-top: 14px;
      color: var(--grey-lighter);
      font-size: rem(14px);
      font-weight: 700;
      line-height: 1;
    }

    .select {
      min-width: 200px;
      z-index: 2;

      &:not(:last-child) {
        margin-right: 24px;
      }
    }
  }

  &-subtitle {
    width: 100%;
    margin-top: 8px;
  }

  &-loader {
    @include size(40px);

    .loader {
      @include position(absolute, 50% null null 50%);
      @include size(50px);
      z-index: 0;
      transform: translate(-50%, -50%);
    }
  }

  .button {
    + .button {
      margin-left: 8px;
    }
  }
}
</style>

<style lang="scss">
.header {
  .header-right {
    .select {
      @include mq($until: 'm') {
        .select-toggle {
          &-text {
            padding-top: 7px;
            padding-bottom: 6px;
            font-size: rem(12px);
          }
        }
      }
    }
  }
}
</style>