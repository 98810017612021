<template>
  <div class="default">
    <img
      class="default-logo"
      src="/img/logo.svg"
    />

    <nav class="default-menu">
      <ul class="default-menu-list">
        <li
          v-for="link in links"
          :key="`menu-${link.name}`"
          class="default-menu-item"
        >
          <router-link
            :to="{ name: link.name }"
            class="default-menu-link"
          >
            {{ $t(`pages.${link.name.toLowerCase()}.title`) }}
          </router-link>
        </li>
      </ul>
    </nav>

    <Profile v-if="user.data" :user="user.data"/>
  </div>
</template>

<script>
import Profile from '@/components/modules/Profile';

export default {
  components: {
    Profile,
  },
  computed: {
    user() {
      const { user } = this.$store.state;
      return user;
    },
    links() {
      const { role } = this.$store.state.user.data;
      const routes = this.$router.getRoutes();

      return routes.filter(r => {
        if (role === 'admin') return r.meta.menu && r.meta.admin;
        return r.meta.menu && !r.meta.admin;
      });
    },
  }
}
</script>


<style lang="scss" scoped>
.default {
  @include padding(null 8px);
  display: flex;
  align-items: center;
  height: auto;
  width: 100%;

  &-logo {
    @include size(32px);
    margin-right: 16px;

    @include mq($from: 'l') {
      @include size(40px);
    }

    @include mq($until: 'm') {
      display: none;
    }
  }

  &-menu {
    width: auto;

    @include mq($until: 'm') {
      @include position(fixed, null null 0 0);
      @include size(100%, auto);
      z-index: 101;
      border-top: 1px solid var(--grey-border);
      background-color: var(--white);
      max-width: unset;

      .layout--staging & {
        bottom: 30px;
      }
    }

    &-list {
      display: flex;

      @include mq($until: 'm') {
        width: calc(100% - 48px);
      }
    }

    &-item {
      @include mq($from: 'm') {
        &:not(:first-child) {
          margin-left: 16px;
        }
      }

      @include mq($until: 'm') {
        width: 33.33%;
        min-height: 44px;
      }
    }

    &-link {
      @include padding(11px null);
      display: block;
      position: relative;
      color: var(--black-light);
      text-decoration: none;
      transition: color .3s $ease-out-quart;

      @include mq($from: 'l') {
        @include padding(16px null);
      }

      @include mq($until: 'm') {
        @include padding(8px 8px);
        @include size(100%);
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: rem(10px);
        line-height: 1.2;
        text-align: center;
      }

      &:hover {
        &::before {
          @include mq($from: 'm') {
            transform: scaleY(1);
            transform-origin: 0% 50%;
          }
        }
      }

      &.router-link-active {
        color: var(--primary);

        &::before {
          transform: scaleY(1);
          transform-origin: 0% 50%;
        }
      }

      &::before {
        @include position(absolute, null null 0 0);
        @include size(100%, 2px);
        content: '';
        transform: scaleX(0);
        transform-origin: 100% 50%;
        transition: transform .3s $ease-out-quart;
        background-color: var(--primary);

        @include mq($until: 'm') {
          bottom: auto;
          top: 0;
        }
      }
    }
  }

  .profile {
    @include mq($from: 'm') {
      margin-left: auto;
      margin-right: -8px;
      border-left: 1px solid var(--grey-border);
    }
  }
}
</style>
