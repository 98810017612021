
const responsive = {
  namespaced: true,
  state: {
    visibilities: {
      search: false,
      menu: false
    }
  },
  mutations: {
    toggleVisibility(state, type) {
      state.visibilities[type] = !state.visibilities[type];
    }
  },
  actions: {
  }
}

export default responsive;
