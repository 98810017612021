<template>
  <button
    class="backstreet-back"
    @click.prevent="$emit('onClick')"
  >
    <Sprite :type="'chevron-left'"/>
  </button>
</template>


<script>
import Sprite from '@/components/elements/Sprite';

export default {
  components: {
    Sprite
  }
};
</script>


<style lang="scss" scoped>
.backstreet-back {
  @include size(32px);
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 1;
  border-radius: 10px;
  flex-shrink: 0;
  background-color: var(--white-dark);
  transition: background-color .3S $ease-out-quart;

  &:hover {
    background-color: var(--primary);

    .svg {
      fill: var(--white);
    }
  }

  .svg {
    fill: var(--grey);
    transition: fill .3s $ease-out-quart;
  }
}
</style>