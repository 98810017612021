<template>
  <div class="page tagging">
    <Aside
      :metadatas="fileMetadatas"
      :categorization="categorization"
      :tagsOpenAI="tagsOpenAI"
      @toggleLabel="onToggleLabel"
      @deleteLabel="onDeleteLabel"
      @updateLabel="onUpdateLabel"
      @blurLabel="onBlurLabel"
      @blurLabelCode="onBlurLabelCode"
      @highlightLabel="onHighlightLabel"
      @shadowLabel="onShadowLabel"
      @addIALabel="onAddIALabel"
      @deleteSelection="onDeleteSelection"
      @highlightSelection="onHighlightSelection"
      @shadowSelection="onShadowSelection"
      @toggleMeta="toggleMeta"
    />
    <div
      ref="page"
      :class="[
        'tagging-page',
        { 'is-highlighting': isHighlighting },
        { 'is-editing': isEditing }
      ]"
    >
      <div
        v-if="loading || processing"
        class="tagging-page-loader"
      >
        <p v-if="processing">{{ $t('tagging.loader') }}</p>
        <Loader/>
      </div>

      <div
        v-show="content && !loading && !processing"
        ref="content"
        class="tagging-page-content"
      >
        <!-- Toolbox -->
        <Toolbox
          v-if="toolbox.visible"
          :top="toolbox.top"
          :left="toolbox.left"
          :atBottom="toolbox.atBottom"
          :labels="categorization"
          @addLabel="onAddLabel"
          @onLabel="onLabel"
          @onClose="close"
        />

        <!-- Content -->
        <pre
          ref="text"
          v-html="content"
          @mouseup="onMouseUp"
        />

        <!-- Labels -->
        <template
          v-for="(label, index) in categorization"
          :key="`label-${index}`"
        >
          <template
            v-for="(selection, ind) in label.data"
            :key="`label-selection-${ind}`"
          >
            <span
              v-for="span in selection.spans"
              :key="`label-selection-span-${span.id}`"
              :style="{
                top: `${span.top}px`,
                left: span.left ? `${span.left}px` : '0',
                width: span.width ? `${span.width}px` : '100%',
                backgroundColor: `#${label.color.code}`
              }"
              :class="['highlight', { 'is-highlighted': label.highlighted || selection.highlighted }]"
              @click="onLabelClick(label, selection)"
            />
          </template>
        </template>

        <!-- Results -->
        <template
          v-for="(result, index) in results"
          :key="`result-${index}`"
        >
          <span
            v-for="span in result.spans"
            :key="`label-result-span-${span.id}`"
            :style="{
              top: `${span.top}px`,
              left: span.left ? `${span.left}px` : '0',
              width: span.width ? `${span.width}px` : '100%'
            }"
            :class="['highlight', 'highlight--result', { 'is-current': currentResult && currentResult.start_index === result.start_index }]"
            @click="onLabelClick(label, result)"
          />
        </template>

        <!-- Highlight -->
        <span
          v-for="span in highlight.spans"
          :key="`highlight-${span.id}`"
          :style="{
            top: `${span.top}px`,
            left: span.left ? `${span.left}px` : '0',
            width: span.width ? `${span.width}px` : '100%',
            backgroundColor: `#${color.code}`
          }"
          class="highlight"
        />
      </div>
    </div>

    <popup
      v-if="metaPopup"
      :title="$t('tagging.metas.title')"
      @onClose="toggleMeta"
    >
      <!-- <MetadataForm
        :editing="true"
        :metadatas="metadatas"
        @onChange="onMetaChange"
      /> -->
      <Formmetadata
        :editing="true"
        :metadatas="metadatas"
        @onChange="onMetaChange"
      />

      <FormRow>
        <Btn
          :theme="'ghost'"
          @onClick="onMetaReset"
        >
          {{ $t('actions.cancel') }}
          </Btn>
        <Btn @onClick="onMetaSave">{{ $t('actions.save') }}</Btn>
      </FormRow>
    </popup>
  </div>
</template>

<script>
import Btn from '@/components/elements/Btn';
import Loader from '@/components/elements/Loader';
import FormRow from '@/components/modules/FormRow';
import Toolbox from '@/components/modules/Toolbox';
import Aside from '@/components/partials/Aside';
import Popup from '@/components/partials/Popup';
// import MetadataForm from '@/components/partials/MetadataForm';
import Formmetadata from '@/components/partials/Formmetadata';
import { sanitizeCode } from '@/data/formaters/label';

import getRef from '@/data/formaters/reference';

export default {
  components: {
    Btn,
    Loader,
    FormRow,
    Toolbox,
    Aside,
    Popup,
    // MetadataForm Doesn't seems to work in this file for no fucking reason
    // Formmetadata is just a copy/paste of MetadataForm and it seems to work...
    // MetadataForm,
    Formmetadata
  },
  async beforeMount() {
    this.$store.commit('file/updateOnPage', true);
    this.$store.commit('file/updateProcessing', false);
    const token = this.$route?.query?.token;

    if (token) {
      this.$store.commit('file/updateToken', token);
    } else if(this.$store.state.files.importeds.length) {
      this.$store.commit('file/updateToken', this.$store.state.files.importeds[0].token);
    }

    if (!this.$store.state.file.token) return;

    const getData = await this.$store.dispatch('file/getFile');

    const reference = getRef(this.fileMetadatas);

    if (reference && reference.length && reference !== this.fileMetadatas.reference) {
      this.onMetaChange({ value: reference, type: 'reference' });
      await this.onMetaSave(false);
      // this.$store.dispatch('file/save');
    }

    if (getData === 'success') {
      setTimeout(() => {
        this.setCategorization();
      }, 100);
    }
  },
  mounted() {
    document.addEventListener('keydown', this.keyDown);
    document.addEventListener('keyup', this.keyUp);
  },
  beforeUnmount() {
    this.$store.commit('file/updateOnPage', false);
    document.removeEventListener('keydown', this.keyDown);
    document.removeEventListener('keyup', this.keyUp);
  },
  data() {
    return {
      toolbox: {
        visible: false,
        atBottom: false,
        top: 0,
        left: 0
      },
      highlight: {
        content: null,
        start_index: null,
        end_index: null,
        spans: []
      },
      editing: {
        label: null,
        selection: null
      },
      isHighlighting: false,
      isEditing: false,
      keyDown: this.onKeyDown.bind(this),
      keyUp: this.onKeyUp.bind(this),
      metaPopup: false
    }
  },
  computed: {
    content() {
      return this.$store.state.file.content;
    },
    color() {
      return this.$store.state.toolbox.color;
    },
    metadatas() {
      return this.$store.state.file.metadatas;
    },
    fileMetadatas() {
      return this.$store.state.file.fileMetadatas;
    },
    categorization() {
      return this.$store.state.file.categorization;
    },
    tagsOpenAI() {
      return this.$store.state.file.tagsOpenAI;
    },
    results() {
      return this.$store.state.file.search.results;
    },
    currentResult() {
      return this.$store.state.file.search.current;
    },
    loading() {
      return this.$store.state.file.requests !== 0;
    },
    processing() {
      return this.$store.state.file.processing;
    },
    currentSearch() {
      return this.$store.state.file.search.current;
    },
  },
  watch: {
    results() {
      this.setSearchResults();
    },
    currentResult(value) {
      this.checkCurrentResult();
    },
    async $route(to, from) {
      if (to.name === 'Tagging' && from.name === 'Tagging') {
        this.$store.commit('file/updateToken', to.query.token);
        const getData = await this.$store.dispatch('file/getFile');

        if (getData === 'success') {
          setTimeout(() => {
            this.setCategorization();
          }, 100);
        }
      }
    }
  },
  methods: {
    cleanHighlights() {
      this.highlight = {
        content: null,
        start_index: null,
        end_index: null,
        spans: []
      };
    },
    close() {
      // Toolbox
      this.toolbox.visible = false;
      this.cleanHighlights();
    },
    setHighlights(range) {
      this.cleanHighlights();

      this.highlight = {
        start_index: range.startOffset,
        end_index: range.endOffset,
        content: range.toString(),
        spans: this.createSpans(range),
      }
    },
    setToolbox(range) {
      const { toolbox } = this;
      if (!toolbox.visible) toolbox.visible = true;

      const rangeRect = range.getBoundingClientRect();
      const contentRect = this.$refs.content.getBoundingClientRect();

      const top = rangeRect.top - contentRect.top;
      const topToBottom = (rangeRect.bottom - contentRect.top) + 140;

      toolbox.top = top < 300 ? topToBottom : top;
      toolbox.atBottom = top < 300;
      toolbox.left = Math.max((rangeRect.left - contentRect.left) + (rangeRect.width / 2), 160);
    },
    setCategorization() {
      if(!this.$refs.text || !this.$refs.text.firstChild) return;

      const categorization = [...this.categorization];

      for (let i = 0; i < categorization.length; i++) {
        const label = categorization[i];
        const { data } = label;

        for (let j = 0; j < data.length; j++) {
          const selection = data[j];
          if(!selection.spans || !selection.spans.length) {
            const range = this.createRange(selection);
            const spans = this.createSpans(range);
            selection.spans = spans;
          }
        }
      }
    },
    setSearchResults() {
      if(!this.$refs.text || !this.$refs.text.firstChild) return;

      const results = [...this.results];

      for (let i = 0; i < results.length; i++) {
        const result = results[i];
        if(!result.spans || !result.spans.length) {
          const range = this.createRange(result);
          const spans = this.createSpans(range);
          result.spans = spans;
        }
      }
    },
    checkCurrentResult() {
      if (!this.currentResult) return;

      // Result el
      const { spans } = this.currentResult;
      const span = spans[0];
      const positionTop = span.top;

      // this.$refs.page.scrollTo(0, positionTop);
      this.$refs.page.scrollTop = positionTop;
    },
    // Events
    onKeyDown(e) {
      const { key } = e;

      if (key === 'Meta') {
        this.isEditing = true;
      }
    },
    onKeyUp(e) {
      const { key } = e;
      if (key === 'Meta') {
        this.isEditing = false;
      }
    },
    onMouseUp() {
      const selection = document.getSelection();
      const range = selection.getRangeAt(0);
      const {
        startOffset, startContainer,
        endOffset, endContainer
      } = range;

      if (startOffset === endOffset || startContainer !== endContainer) {
        this.close();
        return;
      }

      this.setToolbox(range);
      this.setHighlights(range);
    },
    onAddLabel() {
      // If it's highlight, it's a new label/selection
      if (this.highlight.content && this.highlight.content.length) {
        this.$store.commit('file/addLabel', {
          name: this.$store.state.toolbox.label,
          code: this.$store.state.toolbox.formatedLabel,
          color: this.$store.state.toolbox.color,
          open: false,
          highlighted: false,
          data: [{ ...this.highlight }],
          openai: false
        });

        this.close();
        return;
      }

      // If it's not highlight, it's an editing of a label/selection
      this.$store.commit('file/addLabel', {
        name: this.$store.state.toolbox.label,
        code: this.$store.state.toolbox.formatedLabel,
        color: this.$store.state.toolbox.color,
        open: false,
        highlighted: false,
        data: [{ ...this.editing.selection }],
        openai: false
      });

      this.$store.commit('file/removeSelection', { ...this.editing });

      this.close();
    },
    onAddIALabel(label) {
      this.$store.commit('file/addLabel', {
        name: label,
        savedName: label,
        code: sanitizeCode(label),
        savedCode: sanitizeCode(label),
        color: {
          code: "E68A70",
          contrast: "dark"
        },
        open: false,
        highlighted: false,
        data: [],
        openai: true
      });
    },
    onLabel(label) {
      // If it's highlight, it's a new selection to add
      if (this.highlight.content && this.highlight.content.length) {
        this.$store.commit('file/addSelection', {
          label,
          selection: { ...this.highlight }
        });

        this.close();
        return;
      }

      // If it's not highlight, it's an selection to move from a label to another
      this.$store.commit('file/addSelection', {
        label,
        selection: { ...this.editing.selection }
      });

      this.$store.commit('file/removeSelection', { ...this.editing });

      this.close();
    },
    onToggleLabel(label) {
      this.$store.commit('file/toggleLabel', label);
    },
    onDeleteLabel(label) {
      this.$store.commit('file/deleteLabel', label);
    },
    onUpdateLabel({ value, label, property = 'name' }) {
      this.$store.commit('file/editLabel', { label, value, property });
    },
    onBlurLabel({ value, label }) {
      if (!value.length) this.$store.commit('file/resetLabelName', label);
    },
    onBlurLabelCode({ value, label }) {
      if (!value.length) this.$store.commit('file/resetLabelCode', label);
    },
    onDeleteSelection({ label, selection }) {
      this.$store.commit('file/deleteSelection', { label, selection });
    },
    onHighlightSelection({ label, selection }) {
      this.scrollToSelection(selection);
      this.isHighlighting = true;
      this.$store.commit('file/highlightSelection', {
        label, selection,
        value: true
      });
    },
    onShadowSelection({ label, selection }) {
      this.isHighlighting = false;
      this.$store.commit('file/highlightSelection', {
        label, selection,
        value: false
      })
    },
    onHighlightLabel(label) {
      const { data } = label;
      const selection = data[0];
      this.scrollToSelection(selection);
      this.isHighlighting = true;
      this.$store.commit('file/highlightLabel', {
        label,
        value: true
      });
    },
    onShadowLabel(label) {
      this.isHighlighting = false;
      this.$store.commit('file/highlightLabel', {
        label,
        value: false
      })
    },
    onLabelClick(label, selection) {
      this.cleanHighlights();

      const { toolbox } = this;
      const { spans } = selection;
      const firstSpan = spans[0];
      const lastSpan = spans[spans.length - 1];
      const { top, left, width } = firstSpan;

      toolbox.top = top < 150 ? lastSpan.top + 20 + 140 : top;
      // toolbox.top = top < 150 ? lastSpan.top + 20 + 140 : top;
      toolbox.atBottom = top < 150;
      toolbox.left = spans.length > 1 ? '50%' : left + (width / 2);
      toolbox.visible = true;

      this.editing.label = label;
      this.editing.selection = selection;
    },
    toggleMeta() {
      this.metaPopup = !this.metaPopup;
    },
    onMetaChange({ type, value }) {
       if (type === 'lawcases') {
        this.$store.commit('file/updateMetadataArray', { type, item: value });
      } else {
        this.$store.commit('file/updateMetadata', { type, value });
      }
    },
    async onMetaSave(toggle = true) {
      const req = await this.$store.dispatch('file/saveMetadatas');

      if (req === 'success') {
        this.$store.dispatch('file/getFile');

        if (toggle) {
          this.toggleMeta();
        }
      }

      return 'true';
    },
    onMetaReset() {
      this.$store.commit('file/updateMetadatas', { ...this.$store.state.file.fileMetadatas });
      this.toggleMeta();
    },
    // Creators
    createRange(selection) {
      const range = document.createRange();
      range.setStart(this.$refs.text.firstChild, selection.start_index);
      range.setEnd(this.$refs.text.firstChild, selection.end_index);
      return range;
    },
    createSpans(range) {
      const rangeClientRects = [...range.getClientRects()];
      const lineRects = rangeClientRects.filter(rect => rect.width !== 0);
      const contentRect = this.$refs.content.getBoundingClientRect();
      const spans = [];

      for (let i = 0; i < lineRects.length; i++) {
        const line = lineRects[i];

        spans.push({
          index: i,
          top: line.top - contentRect.top,
          left: line.left - contentRect.left,
          width: line.width
        });
      }

      return spans;
    },
    scrollToSelection(selection) {
      if (this.$refs.page && selection) {
        const span = selection.spans[0];
        this.$refs.page.scrollTop = span.top;
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.tagging {
  @include margin(-32px -24px);
  display: flex;
  height: calc(100% + 64px);

  &-page {
    padding: 80px;
    flex-grow: 1;
    position: relative;
    z-index: 1;
    width: calc(100% - 240px);
    height: 100%;
    overflow: auto;

    &-loader {
      display: flex;
      flex-direction: column;
      align-items: center;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);

      .loader {
        margin-top: 20px;
      }
    }

    &-content {
      position: relative;
    }

    pre {
      position: relative;
      z-index: 2;
      white-space: pre-line;
      // font-size: rem(14px);
      // line-height: 32px;
    }

    &.is-highlighting {
      .highlight {
        opacity: .5;

        &:not(.is-highlighted) {
          opacity: .2;
        }
      }
    }

    &.is-editing {
      // background-color: red;

      pre {
        pointer-events: none;
        opacity: .6;
      }
    }

    .highlight {
      @include position(absolute, null null null 0);
      height: 20px;
      width: 200px;
      z-index: 0;
      cursor: pointer;
      border-radius: 4px;
      opacity: .3;

      &--result {
        border: 2px solid #fdd835;
        opacity: 1;

        &.is-current {
          background-color: #fdd835;
        }
      }
    }
  }

  .popup {
    .button {
      margin-top: 16px;
    }
  }
}
</style>

