<template>
  <div class="lawcase">
    <p class="lawcase-title">{{ lawcase.name }}</p>

    <div class="lawcase-right">
      <ThreeDotsMenu :items="items"/>
    </div>
  </div>
</template>

<script>
import ThreeDotsMenu from '@/components/elements/ThreeDotsMenu';

export default {
  components: {
    ThreeDotsMenu
  },
  props: {
    lawcase: {
      type: Object,
      required: true
    }
  },
  computed: {
    items() {
      const { data } = this.$store.state.client;
      return [
        {
          href: `${process.env.VUE_APP_CASUS_URL}?uuid=${data.uuid}&lawCase=${this.lawcase.id}&lang=fr`,
          label: this.$t('lawcase.open-form')
        }
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
.lawcase {
  @include padding(16px);
  display: flex;
  align-items: center;
  // position: relative;
  width: 100%;
  border-radius: 8px;
  box-shadow: 0px 1px 4px rgba(15, 15, 15, 0.16);
  background-color: var(--white);

  &-tag {
    @include padding(4px 8px);
    margin-left: 8px;
    border-radius: 4px;
    background-color: var(--white-dark);
    color: var(--grey);
    font-size: rem(12px);
    font-weight: 500;
    line-height: (16 / 12);
    text-transform: uppercase;
  }

  // Right
  &-right {
    display: flex;
    margin-left: auto;
  }

  &-toggle {
    @include size(32px);
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    z-index: 8;
    margin-left: 16px;
    border-radius: 10px;
    background-color: var(--white-dark);

    &:focus,
    &:hover {
      .category-toggle-dots {
        background-color: var(--primary);

        &::before {
          background-color: var(--primary);
        }

        &::after {
          background-color: var(--primary);
          // transition-delay: .1s;
        }
      }
    }

    &-dots {
      @include size(3px);
      position: relative;
      border-radius: 50%;
      background-color: var(--grey);
      transition: background-color .2s $ease-out-quart;
      transition-delay: .05s;

      &::before,
      &::after {
        @include position(absolute, 0 null null null);
        @include size(100%);
        content: '';
        border-radius: 50%;
        background-color: var(--grey);
        transition: background-color .2s $ease-out-quart;
      }

      &::before {
        right: 200%;
      }

      &::after {
        left: 200%;
        transition-delay: .1s;
      }
    }
  }
}
</style>