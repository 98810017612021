import Authentication from '../views/Authentication.vue';

export const authentication = [
  {
    path: '/',
    name: 'Login',
    component: Authentication,
    meta: {}
  },
  {
    path: '/forgot',
    name: 'Forgot',
    component: Authentication,
    meta: {}
  },
  {
    path: '/register',
    name: 'Register',
    component: Authentication,
    meta: {}
  },
  {
    path: '/subscribe',
    name: 'Subscribe',
    component: Authentication,
    meta: {}
  },
  {
    path: '/reset',
    name: 'Reset',
    component: Authentication,
    meta: {}
  },
]