<template>
  <div
    v-if="values"
    class="search"
  >
    <template
      v-for="(field, index) in filters"
      :key="`search-field-${field.data}-${index}`"
    >
      <div
        v-if="checkConditional(field.conditional)"
        class="search-field"
      >
        <Input
          v-if="field.component === 'input'"
          :id="field.data"
          :label="field.label"
          :placeholder="field.placeholder"
          :type="field.type"
          :value="values[field.data]"
          @onInput="onInput($event, field.data, 'text')"
        />
        <Select
          v-if="field.component === 'select'"
          :id="field.data"
          :label="field.label"
          :placeholder="field.placeholder"
          :options="field.options"
          :nullOption="true"
          :value="values[field.data]"
          @onChange="onInput($event, field.data, 'select')"
        />
        <Select
          v-if="field.component === 'select-lawyers'"
          :label="field.label"
          :placeholder="field.placeholder"
          :options="lawyers"
          :value="lawyerSourceID"
          :allowNull="true"
          :hasSearchApi="true"
          :loading="lawyersLoad"
          @onSearch="onLawyerSearch($event)"
          @onChange="onLawyerChange($event)"
        />

        <!-- v-if="field.component === 'block' && checkConditional(field.conditional)" -->
        <div
          v-if="field.component === 'block'"
          class="search-block"
        >
          <h3 class="search-block-title">{{ field.title }}</h3>

          <template
            v-for="(f, ind) in field.fields"
            :key="`search-field-${field.data}${index}${f.data}${ind}`"
          >
            <div
              v-if="checkConditional(f.conditional)"
              class="search-field"
            >
              <Input
                v-if="f.component === 'input'"
                :id="f.data"
                :label="f.label"
                :placeholder="f.placeholder"
                :type="f.type"
                :value="values[f.data]"
                @onInput="onInput($event, f.data, 'text')"
              />
              <Select
                v-if="f.component === 'select'"
                :id="f.data"
                :label="f.label"
                :placeholder="f.placeholder"
                :options="f.options"
                :value="values[f.data]"
                @onChange="onInput($event, f.data, 'select')"
              />
            </div>
          </template>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import _ from 'lodash';
import Input from '@/components/elements/Input';
import Radio from '@/components/elements/Radio';
import Select from '@/components/elements/Select';
import { calls, endpoints } from '@/services/calls';

export default {
  components: {
    Input,
    Radio,
    Select,
  },
  data() {
    return {
      lawyers: [],
      lawyersLoad: false,
      debounceSearchRequest: _.debounce(this.search.bind(this), 300, { 'leading': false, })
    }
  },
  computed: {
    values() {
      return this.$store.state.polysearch.fields;
    },
    filters() {
      return this.$store.state.polysearch.filters;
    }
  },
  methods: {
    checkConditional(cond) {
      if (!cond) return true;

      const notRespecteds = cond.filter(c => {
        const { data, value } = c;
        const currentValue = this.values[data];
        return value !== currentValue;
      });

      return notRespecteds.length === 0;
    },
    onInput(value, field, type) {
      this.$store.commit('polysearch/updateValue', { value, field });
      // this.updateRoute();

      if (type === 'select') {
        this.search();
        return;
      }

      this.debounceSearchRequest();
    },
    onLawyerChange(value) {
      this.$store.commit('polysearch/updateValue', { value, field: 'lawyerSource' });
      this.search();
    },
    async onLawyerSearch(value) {
      if (!value || value.length < 3) {
        this.lawyers = [];
        return;
      }

      this.lawyersLoad = true;
      try {
        const request = await calls.post('getLawyers', { terms: value });
        // const request = await getLawyers({ terms: value });

        if (request.status != 'error') {
          const { data } = request;
          // const { data } = request.data;
          this.lawyers = data.map(l => {
            return {
              label: l.name,
              value: l.id
            }
          });
        }
      } catch (error) {
        alert(error);
      }

      this.lawyersLoad = false;
    },
    search() {
      const params = {};

      this.filters.map(filter => {
        if (filter.component === 'block') {
          if (this.checkConditional(filter.conditional)) {
            filter.fields.map(f => {
              if (this.values[f.data]) {
                const datas = f.data.split('|');
                for (let i = 0; i < datas.length; i++) {
                  params[datas[i]] = this.values[f.data];
                }
              }
            });
          }
        } else {
          if (this.values[filter.data]) {
            const datas = filter.data.split('|');
            for (let i = 0; i < datas.length; i++) {
              params[datas[i]] = this.values[filter.data];
            }
          }
        }
      });

      if (Object.keys(params).length === 0) {
        if (this.$route.name === 'JuridicalDocuments') params.status = "processed";
        this.$store.dispatch('documents/getDocuments', params);
      } else {
        if (this.$route.name === 'JuridicalDocuments') params.status = "processed";
        this.$store.dispatch('documents/search', params);
      }
    },
    updateRoute() {
      const query = {};
      const filters = Object.entries(this.values);

      filters.map(filter => {
        if (filter[1]) query[filter[0]] = filter[1];
      });

      this.$router.replace({
        path: this.$route.path,
        query
      });
    }
  }
}
</script>

<style lang="scss" scoped>
.search {
  &-field {
    &:not(:first-child) {
      margin-top: 16px;
    }
  }

  &-block {
    @include margin(null -8px);
    @include padding(16px 8px);
    border: 1px solid var(--grey-border);
    border-radius: 8px;

    &-title {
      color: var(--black-light);
      font-weight: bold;
    }
  }
}
</style>