<template>
  <div class="demand">
    <TextZone
      v-if="details"
      :title="$t('pages.lcdemand.titles.details')"
      :content="details"
    />
    <TextZone
      v-if="infos"
      :title="$t('pages.lcdemand.titles.infos')"
      :content="infos"
    />
  </div>
</template>

<script>
import TextZone from '@/components/modules/TextZone';
import moment from 'moment';

export default {
  components: {
    TextZone
  },
  computed: {
    details() {
      const data = this.$store.state.legalCase.data;
      const demand = this.$store.state.legalCase?.data?.questions_and_answers;
      if (!demand) return null;

      let filesIndex = 0;

      const fields = demand.map(field => {
        let response = '';
        
        switch (field.question_type) {
          case 'checkbox':
            response = [];
            const answers = field.answer.split('||');
            field.question.answers.map(a => {
              if (answers.includes(a.value)) response.push(a.label['fr']);
            });
            response = `<p>${response.join('<br/>')}</p>`;
            break;
          case 'radio':
            const answer = field.question.answers.filter(a => a.value === field.answer);
            response = `<p>${answer[0].label['fr']}</p>`;
            break;
          case 'file':
          case 'files':
            response = field.answer.split('||');
            response = response.map((file, index) => {
              filesIndex += 1;
              return `<a href="${file}" data-vue-download="${file}" data-vue-download-name="${data.litigant.firstname} ${data.litigant.lastname} - Document ${filesIndex}" download>Document ${filesIndex}</a>`;
            });
            response = `<p>${response.join('<br/>')}</p>`;
            // response = `<p><a href="/legal-case/${data.token}/litigeant-documents" data-vue-link="/legal-case/${data.token}/litigeant-documents">Voir les documents</a></p>`;
            break;
          case 'date':
            response = `<p>${moment(field.answer).format('DD[/]MM[/]YYYY')}</p>`;
            break;
          default:
            response = `<pre>${field.answer}</pre>`;
            break;
        }
        
        return `<p style="font-weight: 600;">${field.question.title['fr']}</p>${response}`
      })

      return fields.join('<br>');
    },
    infos() {
      const litigant = this.$store.state.legalCase?.data?.litigant;
      if (!litigant) return null;
      const {
        firstname,
        lastname,
        email,
        phone
      } = litigant;
      return `
        <p>${firstname} ${lastname}</p>
        ${email ? `<p><a href="mailto:${email}m">${email}</a></p>` : ''}
        ${phone ? `<p>${phone}</p>` : ''}
      `;
    }
  }
}
</script>