<template>
  <div class="radio">
    <span class="label" v-if="label">{{ label }}</span>

    <div :class="`radio-inputs${vertical ? ' is-vertical' : ''}`">
      <div
        v-for="option in options"
        :key="`radio-${option.value}`"
        class="radio-input"
      >
        <input
          :key="`radio-${option.value}`"
          :id="`radio-${option.value}`"
          :name="name"
          :value="option.value"
          :checked="option.value === value"
          :disabled="option.disabled"
          type="radio"
          class="u-visually-hidden"
          @change="onChange(option.value)"
        >
        <label :for="`radio-${option.value}`">{{ option.label }}</label>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: [String, Number],
      default: null
    },
    label: {
      type: String,
      default: null
    },
    name: {
      type: String,
      default: null
    },
    options: {
      type: Array,
      required: true
    },
    vertical: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    onChange(value) {
      this.$emit('onChange', value);
    }
  }
}
</script>

<style lang="scss" scoped>
.radio {
  display: flex;
  flex-direction: column;

  .label {
    margin-bottom: 5px;
  }

  &-inputs {
    @include margin(null -4px);
    display: flex;

    &.is-vertical {
      @include margin(null 0);
      flex-direction: column;

      .radio-input {
        @include margin(null 0);

        &:not(:first-child) {
          margin-top: 8px;
        }
      }
    }
  }

  &-input {
    @include margin(null 8px);

    input {
      &:disabled {
        + label {
          pointer-events: none;
          color: var(--grey);

          &::after {
            border-color: var(--grey-lighter);
          }
        }
      }

      &:checked {
        + label {
          &::after {
            border-width: 5px;
            // transform: scale(1);
          }
        }
      }
    }

    // radio
    label {
      @include padding(null null null 24px);
      display: block;
      position: relative;
      cursor: pointer;
      transition: color .3s $ease-out-quart;

      &:hover {
        color: var(--primary);
      }

      &::before,
      &::after {
        @include position(absolute, 4px null null 0);
        @include size(16px);
        content: '';
        border: 1px solid var(--grey-border);
        border-radius: 50%;
      }

      &::after {
        border: 0px solid var(--primary);
      }
    }
  }
}
</style>