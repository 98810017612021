<template>
  <div class="subscription">
    <Header
      :title="$route ? $t('pages.office.subscription.title') : null"
      :actions="actions"
    />

    <h2 class="following">Disponible prochainement</h2>

    <!-- <div class="subscription-stats box">
      <div class="subscription-stat">
        <p class="subscription-stat-label">Nombre de place</p>
        <p class="subscription-stat-value">{{ getLaywersCount() }}/5 avocats</p>
      </div>
      <div v-if="client?.trial_ends_at" class="subscription-stat">
        <p class="subscription-stat-label">Jusqu'au</p>
        <p class="subscription-stat-value">{{ getDisplayDate(client.trial_ends_at) }}</p>
      </div>
      <div v-else-if="client?.subscribed_until" class="subscription-stat">
        <p class="subscription-stat-label">Prochaine période de facturation à partir du</p>
        <p class="subscription-stat-value">{{ getDisplayDate(client.subscribed_until) }}</p>
      </div>
    </div>

    <div class="subscription-header">
      <h2 class="h2">{{ $t("pages.office.subscription.details.title") }}</h2>
      <span class="subscription-header-message" v-html="$t('pages.office.subscription.details.stripe')"/>
    </div>

    <div class="subscription-box box">
      <div class="subscription-box-left">
        <p><span :class="`type ${client.pm_type}`">{{ client.pm_type }}</span> ****{{ client.pm_last_four }}</p>
        <h3>{{ $t("pages.office.subscription.details.title") }}</h3>
      </div>
      <Btn theme="ghost">{{ $t('actions.modify') }}</Btn>
    </div>

    <div class="subscription-header">
      <h2 class="h2">{{ $t("pages.office.subscription.unsubscribe.title") }}</h2>
    </div>

    <div class="subscription-box box">
      <div class="subscription-box-left">
        <p>{{ $t("pages.office.subscription.unsubscribe.text") }}</p>
        <p class="baseline">{{ $t("pages.office.subscription.unsubscribe.baseline") }}</p>
      </div>
      <Btn theme="alert" @onClick="onUnsubscribe">{{ $t('actions.unsubscribe') }}</Btn>
    </div>

    <Popup
      v-if="unsubscribe"
      :title="$t('pages.office.subscription.unsubscribe.popup.title')"
      @onClose="onClose"
    >
      <p v-html="$t('pages.office.subscription.unsubscribe.popup.text')"/>
    </Popup> -->
  </div>
</template>

<script>
import Btn from "@/components/elements/Btn";
import Header from "@/components/modules/Header";
import Popup from "@/components/partials/Popup";
import { getDisplayDate } from '@/helpers';

export default {
  components: {
    Btn,
    Header,
    Popup
  },
  data() {
    return {
      getDisplayDate,
      unsubscribe: false,
      actions: [
        // {
        //   label: this.$t('actions.upgrade-plan'),
        //   action: () => { this.$router.push({ name: 'Subscription' }) }
        // }
      ]
    }
  },
  computed: {
    client() {
      const { originalData } = this.$store.state.client;
      return originalData;
    }
  },
  methods: {
    getLaywersCount() {
      if (this.client?.lawyer_list) return;
      const lawyers = this.client.lawyer_list.split(',');
      return lawyers.length;
    },
    onUnsubscribe(e) {
      this.unsubscribe = true;
    },
    onClose() {
      this.unsubscribe = false;
    }
  }
};
</script>


<style scoped lang="scss">
.subscription {
  // .header {
  //   :deep(.header-left) {
  //     @include mq($from: "m", $until: "l-big") {
  //       width: auto;
  //     }
  //   }
  // }

  .following {
    margin-top: 40px;
    font-size: rem(32px);
    text-align: center;
    font-weight: 700;

    @include mq($until: "m") {
      font-size: rem(24px);
    }
  }

  &-stat {
    display: flex;

    @include mq($until: "m") {
      flex-direction: column;
      align-items: flex-start;

      &:not(:first-child) {
        margin-top: 12px;
      }
    }

    &-label {
      @include mq($from: "m") {
        margin-right: 16px;
        width: calc(50% - 16px);
        max-width: 264px;
      }
    }

    &-value {
      font-weight: bold;
    }
  }

  &-header {
    @include margin(20px null 4px);
    display: flex;
    align-items: center;
    justify-content: space-between;

    @include mq($until: "m") {
      flex-direction: column;
      align-items: flex-start;
    }

    &-message {
      opacity: .6;

      :deep(strong) {
        font-weight: bold;
      }
    }
  }

  &-box {
    display: flex;
    align-items: flex-start;

    @include mq($until: "m") {
      flex-direction: column;
    }

    h3 {
      margin-top: 40px;
      font-weight: bold;
    }

    .button {
      margin-left: auto;

      @include mq($until: "m") {
        @include margin(12px null null 0);
        width: 100%;
      }
    }

    .baseline {
      opacity: .6;
      font-size: rem(12px);
    }

    .type {
      &::first-letter {
        text-transform: uppercase;
      }
    }
  }

  :deep(.popup-wrapper) {
    max-width: 544px;
  }
}
</style>