<template>
  <div class="apploader">
    <div class="apploader-center">
      <img
        class="apploader-logo"
        src="/img/logo.svg"
      />
      <Loader/>
    </div>
  </div>
</template>

<script>
import Loader from '@/components/elements/Loader';

export default {
  components: {
    Loader
  }
}
</script>

<style lang="scss">
.apploader {
  @include size(100%);
  display: flex;
  align-items: center;
  justify-content: center;

  &-center {
    position: relative;

    &::before {
      @include position(absolute, calc(50% + 6px) null null 50%);
      @include size(42px, 52px);
      content: '';
      z-index: 1;
      transform: translate(-50%, -50%);
      border-radius: 0 0 20px 20px;
      background-color: var(--white-dark);
    }

    .loader {
      @include position(absolute, 50% null null 50%);
      @include size(180px);
      z-index: 0;
      transform: translate(-50%, -50%);
    }
  }

  &-logo {
    @include size(70px);
    display: block;
    position: relative;
    z-index: 2;
  }
}
</style>