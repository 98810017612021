<template>
  <div class="detail">
    <div class="detail-left">
      <BackButton :onClick="onBack"/>
      <p v-if="title" class="detail-title">{{ title }}</p>
    </div>

    <BurgerButton :open="burgerOpen" @onClick="onBurger"/>
    <Profile :user="user.data" desktopOnly/>
  </div>
</template>

<script>
import BurgerButton from '@/components/elements/BurgerButton';
import BackButton from '@/components/elements/BackButton';
import Profile from '@/components/modules/Profile';

export default {
  components: {
    BurgerButton,
    BackButton,
    Profile,
  },
  computed: {
    title() {
      const { name } = this.$route;
      const { client, category } = this.$store.state;

      switch(name) {
        case 'CategoriesDetail':
          return category.data?.description;
        case 'Profile':
        case 'Offices':
        case 'Categories':
        case 'CategoriesList':
        case 'CategoriesUpdate':
          return this.$t('pages.account.title');
        case 'Subscription':
        case 'SubscriptionOptions':
        case 'SubscriptionPayment':
        case 'SubscriptionBilling':
        case 'SubscriptionRecap':
          return this.$t('pages.subscription.title');
        default:
          return this.$t('pages.office.title-name', { name: client.data?.name });
      }
    },
    user() {
      const { user } = this.$store.state;
      return user;
    },
    burgerOpen() {
      const { search, menu } = this.$store.state.responsive.visibilities;
      return search || menu;
    },
  },
  methods: {
    onBack() {
      const { meta } = this.$route;
      this.$router.push(meta.back ? meta.back : { name: 'LegalCases' });
    },
    onBurger() {
      this.$store.commit('responsive/toggleVisibility', 'menu');
    }
  }
}
</script>

<style lang="scss" scoped>
.detail {
  display: flex;
  align-items: center;

  @include mq($until: 'm') {
    @include position(fixed, null null 0 0);
    @include size(100%, auto);
    @include padding(6px 8px);
    z-index: 101;
    align-items: center;
    border-top: 1px solid var(--grey-border);
    background-color: var(--white);
    max-width: unset;

    .layout--staging & {
      bottom: 30px;
    }
  }

  // @include mq($from: "m", $until: "l") {
  //   align-items: center;
  // }

  &-left {
    @include padding(null null null 8px);
    display: flex;
    align-items: center;
    margin-right: auto;

    @include mq($from: 'm') {
      @include padding(12px 8x);
    }
  }

  &-title {
    margin-left: 16px;
    font-weight: 700;
  }

  .burger {
    @include position(relative, null 0 0 null);
    margin-left: auto;
    flex-shrink: 0;
  }

  .profile {
    border-left: 1px solid var(--grey-border);

    @include mq($from: "m", $until: "l") {
      margin-left: 8px;
    }
  }
}
</style>