<template>
  <router-link
    v-if="to"
    :class="`button ${theme}`"
    :target="target"
    :to="to"
  >
    <slot/>
  </router-link>
   <a
    v-else-if="href"
    :class="`button ${theme}`"
    :target="target"
    :href="href"
  >
    <slot/>
  </a>
  <button
    v-else
    :class="`button ${theme}`"
    :type="type"
    :title="title"
    :disabled="disabled"
    @click="$emit('onClick', $event)"
  >
    <slot/>
  </button>
</template>

<script>
export default {
  props: {
    type: {
      type: String,
      default: 'button'
    },
    disabled: {
      type: Boolean,
      default: false
    },
    theme: {
      type: String,
      default: ''
    },
    href: {
      type: String
    },
    to: {
      type: [String, Object]
    },
    target: {
      type: String
    },
    title: {
      type: String
    }
  }
}
</script>

<style lang="scss" scoped>
.button {
  @include padding(9px 32px);
  display: inline-block;
  position: relative;
  transition: background-color .2s $ease-out-quart, border-color .2s $ease-out-quart, color .2s $ease-out-quart, opacity .3s $ease-out-quart;
  border: 1px solid var(--black-light);
  border-radius: 8px;
  background-color: var(--black-light);
  color: $white;
  font-family: $sans-serif;
  font-size: rem(14px);
  font-weight: 700;
  line-height: 1;
  text-decoration: none;
  text-align: center;
  outline: none;
  cursor: pointer;

  @include mq($until: 'm') {
    @include padding(8px 16px);
    font-size: rem(12px);
  }

  @include mq($from: 'l') {
    &:hover {
      border-color: var(--primary);
      background-color: var(--primary);
      color: var(--white);

      .svg {
        fill: var(--primary);
      }
    }
  }

  .svg {
    transition: fill .3s $ease-out-quart;
    fill: $white;
    fill: currentColor;
  }

  &.full {
    display: block;
    width: 100%;
  }

  // Themes
  &.ghost,
  &.ghost-faded {
    @include padding(9px null);
    border: 0;
    background-color: var(--ghost);
    color: var(--black-light);

    @include mq($from: 'm') {
      @include padding(10px null);
    }

    @include mq($from: 'l') {
      &:hover {
        background-color: var(--primary);
        color: var(--white);
      }
    }
  }

  &.ghost-faded {
    color: var(--grey-darker);
  }

  &.invisible {
    border-color: rgba($black, 0);
    background-color: rgba($black, 0);
    color: var(--grey);

    .svg {
      fill: var(--grey);
    }

    &:hover {
      color: var(--primary);

      .svg {
        fill: var(--primary);
      }
    }
  }

  &.alert {
    border-color: var(--alert-bg);
    background-color: var(--alert-bg);
    color: var(--alert);

    &:hover {
      background-color: var(--alert);
      border-color: var(--alert);
      color: var(--white);
    }
  }

  &.icon {
    @include padding(null 0);
    @include size(32px);
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--ghost);
    border-color: var(--ghost);
    color: var(--grey);

    &:hover {
      background-color: var(--primary);
      border-color: var(--primary);
      color: var(--white);
    }

    .svg {
      fill: currentColor;
    }

    &.prev {
      .svg {
        margin-left: -1px;
      }
    }

    &.next {
      .svg {
        margin-left: 1px;
      }
    }
  }

  &.pill {
    @include padding(6px 12px 7px);
    display: flex;
    align-items: center;
    border-radius: 15px;
    border: 1px solid var(--grey-lightest);
    background: none;
    color: var(--black);
    font-weight: 500;

    &:hover {
      .svg {
        fill: var(--black);
      }
    }
  }

  // Disabled
  &[disabled] {
    opacity: .3;
    pointer-events: none;
  }
}
</style>

<style lang="scss">
.button {
  &.pill {
    .svg {
      @include margin(null null null 4px);
      display: flex;
      align-items: center;
      fill: var(--grey-lighter);
      transition: fill .3s $ease-out-quart;
      vertical-align: middle;
      cursor: pointer;
      pointer-events: auto;

      svg {
        display: inline-block;
      }
    }
  }
}
</style>