<template>
  <div
    :class="[
      'document',
      { 'document--failed': document.state === 'failure' },
      { 'document--selected': selected }
    ]"
    
  >
    {{ document.metadata && document.metadata.reference ? document.metadata.reference : document.name }}

    <a
      href="#"
      class="document-link"
      @click.stop.prevent="onClick(document)"
    >
    </a>
    <span
      v-if="document.state === 'processed'"
      class="document-label"
    >
      - {{ $t('documents.processed') }}
    </span>
    <div
      v-if="canDelete || (actions && actions.length)"
      class="document-actions"
    >
      <template v-if="actions && actions.length">
        <span
          v-for="action in actions"
          :key="`action-${document.token}-${action.icon}`"
          class="document-action"
          @click.capture.stop="onAction(action, document)"
        >
          <!-- @click.capture.stop="action.action(this, document)" -->
          <Sprite :type="action.icon" :width="14" :height="14"/>
        </span>
      </template>
      <span
        v-if="canDelete"
        class="document-action document-delete"
        @click.capture.stop="onDelete(document.token)"
      >
        <Sprite :type="'close'" :width="14" :height="14"/>
      </span>
    </div>

    <div
      v-if="document.metadata"
      class="document-metadatas"
    >
      <div class="document-metadatas-left">
        <!-- <span
          v-if="document.metadata.reference"
          class="document-metadata reference"
        >
          {{ document.metadata.reference }}
        </span> -->

        <span
          v-if="formatSource(document.metadata)"
          class="document-metadata source"
        >
          {{ formatSource(document.metadata) }}
        </span>
        <span
          v-if="getDate(document.metadata)"
          class="document-metadata date"
        >
          {{ formatDate(getDate(document.metadata)) }}
        <!-- <span
          class="document-metadata reference"
        >
          {{ document.metadata.reference ? document.metadata.reference : '' }}
          {{ document.metadata.reference && (formatSource(document.metadata) || getDate(document.metadata)) ? ' • ' : '' }}
          {{ formatSource(document.metadata) ? formatSource(document.metadata): '' }}
          {{ formatSource(document.metadata) && getDate(document.metadata) ? ' • ' : '' }}
          {{ getDate(document.metadata) ? formatDate(getDate(document.metadata)): '' }} -->
        </span>
      </div>
      <div class="document-metadatas-right">
        <span
          v-if="document.metadata.visibility"
          class="document-metadata visibility"
        >
          {{ $t(`metadatas.visibility.${document.metadata.visibility}`) }}
        </span>
      </div>
    </div>

    <!-- <div
      v-if="displayHTML && document.html"
      class="document-html"
      v-html="document.html"
    /> -->
  </div>
</template>

<script>
import Sprite from '@/components/elements/Sprite';

export default {
  components: {
    Sprite
  },
  props: {
    document: {
      type: Object,
      required: true
    },
    selected: {
      type: Boolean,
      default: false
    },
    displayHTML: {
      type: Boolean,
      default: false
    },
    canDelete: {
      type: Boolean,
      default: false
    },
    actions: {
      type: Array,
      default: null
    }
  },
  data() {
    return {
      expanded: false
    }
  },
  methods: {
    // Events
    onClick($event) {
      this.$emit('onClick', $event)
    },
    onDelete($event) {
      this.$emit('onDelete', $event)
    },
    onExpand() {
      this.expanded = !this.expanded;
    },
    onAction(action, doc) {
      action.action(this, doc);
    },
    // Helpers
    getDate(doc) {
      return doc.legislationDate || doc.publicationDate || doc.pronouncedDate || doc.redactionDate;
    },
    formatDate(date) {
      const pieces = date.split('-');
      return `${pieces[2]}/${pieces[1]}/${pieces[0]}`;
    },
    formatSource(metas) {
      if (!metas.source || metas.source === 'symplicy') return 'Symplicy';
      if (!metas.lawyerSource) return null;
      if (typeof metas.lawyerSource === 'string') return metas.lawyerSource;
      return metas.lawyerSource.name;
    }
  }
};
</script>

<style lang="scss" scoped>
.document {
  @include padding(6px 14px);
  // @include padding(8px 16px);
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  flex-wrap: wrap;
  width: 100%;
  transition: color .3s $ease-out-quart;
  border: 2px solid var(--white);
  border-radius: 8px;
  background-color: var(--white);
  box-shadow: 0px 1px 4px rgba(15, 15, 15, .16);
  color: var(--black);
  font-family: $alternate;
  font-weight: 400;
  // font-weight: 600;
  text-decoration: none;
  position: relative;

  &:hover {
    color: var(--primary);
  }

  &--failed {
    pointer-events: none;
    color: rgba(0, 0, 0, .5) !important;

    &-metadatas { opacity: .5; }
  }

  &--selected {
    border-color: var(--primary);
  }

  &-label {
    display: flex;
    align-items: center;
    border-radius: 12px;
    padding-bottom: 5px;
    margin-left: 5px;
    color: var(--grey);
    font-family: $alternate;
    font-size: rem(12px);
    font-weight: 400;
    line-height: 1;

    .svg {
      margin-right: 4px;
      fill: var(--grey);
      opacity: .4;
    }
  }

  &-link {
    @include position(absolute, 0 null null 0);
    z-index: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
  }

  &-actions {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-left: auto;
  }

  &-action {
    @include size(24px);
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    z-index: 1;
    margin-left: 10px;
    border-radius: 50%;
    background-color: rgba($grey-lightest, .4);
    pointer-events: all;
    cursor: pointer;

    + .document-action {
      margin-left: 8px;
    }

    .svg {
      fill: var(--grey-dark);
      opacity: .4;
    }

    &:hover {
      .svg {
        fill: var(--primary);
      }
    }
  }

  &-summary {
    margin-top: 4px;
    width: 100%;
    font-size: rem(14px);
    line-height: 1.2;
    color: var(--grey);

    &:not(.expanded) {
      @include line-clamp(1, 16.8px, false);
    }
  }

  &-metadatas {
    display: flex;
    align-items: flex-end;
    width: 100%;
    color: var(--grey);
    font-size: rem(14px);

    &-left,
    &-right {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
    }

    &-left {
      width: 70%;
    }

    &-right {
      width: 30%;
    }
  }

  &-metadata {
    &:not(:first-child) {
      position: relative;
      padding-left: 8px;
      margin-left: 8px;

      &::before {
        @include position(absolute, 0 null null -1px);
        content: '·';
      }
    }

    &.visibility {
      margin-left: auto;

      &:before {
        content: none;
      }
    }
  }


  &-html {
    width: 100%;

    b,
    strong {
      font-weight: 700;
    }

    i,
    em {
      font-style: italic;
    }
  }
}
</style>
