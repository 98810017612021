import router from '../router';
import { calls, endpoints } from '@/services/calls';
import { dispatchNotif } from '@/services/notifications';

const legalCase = {
  namespaced: true,
  state: {
    requests: 0,
    actionsRequests: 0,
    token: null,
    data: {},
    documentsLists: []
  },
  mutations: {
    updateToken(state, token) {
      state.token = token;
    },
    setData(state, data) {
      state.data = data;
    },
    updateLists(state, lists) {
      state.documentsLists = lists;
    },
    incRequests(state, type = 'requests') {
      state[type]++;
    },
    decRequests(state, type = 'requests') {
      state[type]--;
    }
  },
  actions: {
    reset({ commit }) {
      commit('updateToken', null);
      commit('setData', {});
      commit('updateLists', []);
    },
    async get({ state, commit, dispatch }) {
      commit('incRequests');
      const { token } = state;

      const url = endpoints.getLegalCase.replace('{token}', token);
      const request = await calls.get(url);

      if (request.status === 'success') {
        const { data } = request;
        commit('setData', data);

        const { questions_and_answers, litigant } = data;
        const lists = []
        let indexFiles = 0;

        questions_and_answers.map(field => {
          if (field.question_type === 'file' || field.question_type === 'files') {
            const files = field.answer.split('||');
            const list = {
              title: field.question.title['fr'],
              items: []
            };

            files.map((file, index) => {
              indexFiles++;
              const split = file.split('::');
              const url = split[0];
              const mime = split[1];

              list.items.push({
                name: `Document ${indexFiles}`,
                downloadName: `${litigant.firstname} ${litigant.lastname} - Document ${indexFiles}`,
                url,
                mime
              });
            });

            lists.push(list);
          }
        });

        commit('updateLists', lists);
      }

      commit('decRequests');
    },
    async refuse({ state, commit, dispatch }) {
      commit('incRequests', 'actionsRequests');
      const { token } = state;

      const request = await calls.post('refuseLegalCase', { token });
      // const request = await refuseLegalcase(token);
      if (request.status === 'success') {
        const { data } = request;
        // const { data } = request.data;
        dispatchNotif({ type: 'success', text: 'Affaire refusée' });
        router.push({ name: 'MyCases' });
      }

      commit('decRequests', 'actionsRequests');
      return request;
    },
    async open({ state, commit, dispatch }) {
      commit('incRequests', 'actionsRequests');
      const { token } = state;

      const request = await calls.post('openLegalCase', { token });
      // const request = await openLegalcase(token);
      if (request.status === 'success') {
        const { data } = request;
        // const { data } = request.data;
        dispatch('get');
        dispatchNotif({ type: 'success', text: 'Affaire acceptée' });
      }

      commit('decRequests', 'actionsRequests');
    },
    async close({ state, commit, dispatch }) {
      commit('incRequests', 'actionsRequests');
      const { token } = state;

      const request = await calls.post('closeLegalCase', { token });
      // const request = await closeLegalcase(token);
      if (request.status === 'success') {
        const { data } = request;
        // const { data } = request.data;
        dispatch('get');
        dispatchNotif({ type: 'success', text: 'Affaire clôturée' });
      }

      commit('decRequests', 'actionsRequests');
    },
    async addDocument({ state, commit, dispatch }, docToken) {
      commit('incRequests');
      const url = endpoints.addDocToLegalCase
        .replace('{legalcase}', state.data.token)
        .replace('{doc}', docToken);
      const request = await calls.post(url);
      // const request = await addDocToCase(state.data.token, docToken);

      if (request.status === 'success') {
        dispatchNotif({ type: 'success', text: 'Le document a bien été ajouté à l\'affaire' });
      }

      commit('decRequests');
      return request;
    },
    async reassign({ state, commit, dispatch }, lawyerID) {
      commit('incRequests');
      const request = await calls.post('reassignLegalCase', { case_uuid: state.data.token, user_id: lawyerID });
      // const request = await reassignLegalcase({ case_uuid: state.data.token, user_id: lawyerID });

      if (request.status === 'success') {
        if (request.data.code) {
          dispatchNotif({ type: 'error', text: request.data.message ? request.data.message : request.data.code });
        } else {
          dispatchNotif({ type: 'success', text: 'Affaire réassignée !' });
          dispatch('get');
        }
      }

      commit('decRequests');
    }
  }
}

export default legalCase;
