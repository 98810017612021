<template>
  <div class="topbar">
    <!-- <div class="topbar-wrapper"> -->
      <!-- FileNames -->
      <!-- <template v-if="$route.name === 'Tagging'">
        <span
          v-if="!files || files.length < 2"
          class="topbar-file"
        >
          {{ file.name ? file.name : 'Document non nommé' }}
        </span>

        <template v-else>
          <router-link
            v-for="document in files"
            :key="document.token"
            :to="{
              name: 'Tagging',
              query: { token: document.token }
            }"
            :class="[
              'topbar-document',
              { 'is-active': $route.query.token === document.token }
            ]"
          >
            <span class="topbar-document-text">{{ document.name }}</span>
          </router-link>
        </template>
      </template> -->
      <img
        class="topbar-logo"
        src="/img/logo.svg"
      />
      <LegalCase v-if="topbar === 'legalcase'"/>
      <Detail v-else-if="topbar === 'detail'"/>
      <Default v-else/>
  </div>
</template>

<script>
  import Sprite from '@/components/elements/Sprite';
  import Profile from '@/components/modules/Profile';
  import Default from '@/components/modules/topbar/Default';
  import Detail from '@/components/modules/topbar/Detail';
  import LegalCase from '@/components/modules/topbar/LegalCase';

  export default {
    components: {
      Sprite,
      Detail,
      Profile,
      Default,
      LegalCase
    },
    computed: {
      user() {
        return this.$store.state.user;
      },
      links() {
        const { role } = this.$store.state.user.data;
        const routes = this.$router.getRoutes();

        return routes.filter(r => {
          if (role === 'admin') return r.meta.menu && r.meta.admin;
          return r.meta.menu && !r.meta.admin;
        });
      },
      file() {
        return this.$store.state.file;
      },
      files() {
        return this.$store.state.files.importeds;
      },
      // detail() {
      //   const { meta } = this.$route;
      //   return meta && meta.detail;
      // },
      topbar() {
        const { meta } = this.$route;
        return meta?.topbar;
      }
    },
    methods: {
      onBack() {
        const { meta } = this.$route;
        this.$router.push({ name: meta.back });
      },
      onBurger() {
      }
    }
  }
</script>

<style lang="scss" scoped>
.topbar {
  position: relative;
  background-color: var(--white);
  box-shadow: 0px 2px 8px rgba(15, 15, 15, 0.16);
  z-index: 100;

  @include mq($until: 'm') {
    display: flex;
    align-items: center;
    height: 46px;
  }

  &-logo {
    @include size(32px);
    margin-left: 8px;

    @include mq($from: 'm') {
      display: none;
    }
  }

  &-file {
    @include padding(8px 16px);
    @include size(100%);
    display: flex;
    justify-content: flex-start;
    align-items: center;
    z-index: 1;
    background-color: var(--white);
  }

  &-document {
    @include padding(8px, 16px);
    border-radius: 8px;
    margin-right: 8px;
    background-color: rgba($grey-lightest, .4);
    font-size: rem(14px);
    line-height: (24 / 14);
    color: var(--black-light);
    text-decoration: none;
    transition: background-color .3s $ease-out-quart, color .3s $ease-out-quart;

    &.is-active {
      cursor: auto;
      background-color: rgba($primary, .16);
    }

    &:not(.is-active) {
      &:hover {
        color: $primary;
      }
    }

    &-text {
      @include line-clamp(1, 24px, false);
      max-width: 150px;
    }
  }
}
</style>
