<template>
  <div class="upload">
    <!-- <template v-if="!files || !files.length"> -->
      <input
        id="file-upload"
        type="file"
        class="upload-input u-visually-hidden"
        accept=".png,.jpg,.jpeg,.pdf,.doc,.docx"
        :multiple="multiple"
        @change="onChange"
      >
      <!-- multiple -->
      <template v-if="!files || !files.length">
        <label
          for="file-upload"
          class="upload-label"
        >
          <span class="upload-label-button">
            <sprite
              :width="14"
              :height="14"
              :type="'plus'"
            />
          </span>
          <span class="upload-label-text upload-label-text--placeholder">{{ $t('upload.empty') }}</span>
        </label>
      </template>

      <template v-else>
        <div class="upload-label">
          <button
            class="upload-label-button upload-label-button--close"
            @click.stop="onReset"
          >
            <sprite
              :width="12"
              :height="12"
              :type="'close'"
            />
          </button>
          <span class="upload-label-text">{{ $tc('upload.full', files.length, { count: files.length }) }}</span>
        </div>
      </template>
  </div>
</template>

<script>
import Btn from '@/components/elements/Btn';
import Sprite from '@/components/elements/Sprite';

export default {
  components: {
    Btn,
    Sprite
  },
  props: {
    files: {
      type: Array
    },
    multiple: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    onChange(e) {
      const $input = e.currentTarget;
      const files = $input.files;
      const accept = /\.png|\.jpg|\.jpeg|\.pdf|\.doc|\.docx/g;
      const supporteds = [];

      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        const lower = file.name.toLowerCase();
        const extAccepted = lower.match(accept);

         if (!extAccepted) {
          window.alert('File not supported :', file.name);
        } else {
          if (!this.multiple) {
            this.onAdd(file);
          } else {
            supporteds.push(file);
          }
        }
      }

      if (this.multiple) this.$emit('add', supporteds);
    },
    onAdd(file) {
      this.$emit('add', file);
    },
    // onRemove(file) {
    //   this.$emit('remove', file);
    // },
    onReset() {
      this.$emit('reset');
    },
    onImport() {
      this.$emit('import');
    },
  },
};
</script>


<style lang="scss" scoped>
.upload {
  position: relative;
  width: 100%;
  max-width: 448px;
  margin-top: 8px;

  label {
    cursor: pointer;
  }

  &-label {
    @include padding(8px);
    @include size(100%, auto);
    display: flex;
    align-items: center;
    border: 1px dashed var(--grey-lighter);
    border-radius: 8px;
    background: none;

    &-button {
      @include size(24px);
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 8px;
      border-radius: 50%;
      background-color: $primary;
      font-size: rem(14px);
      line-height: 1;

      .svg {
        fill: var(--white);
      }

      &--close {
        background-color: rgba($primary, .15);
        cursor: pointer;

        .svg {
          fill: var(--secondary);
        }
      }
    }

    &-text {
      color: $black-light;

      &--placeholder {
        opacity: .6;
      }
    }
  }
}
</style>
