import axios from 'axios';
import router from '../router';
import { dispatchNotif } from '@/services/notifications';

axios.defaults.withCredentials = true;

export default class Api {
  constructor(config, endpoints = {}) {
    this.axios = axios.create(config);
    this.endpoints = endpoints;
    this.init();
  }

  init() {
    this.initLang();
    this.initParams();
    this.initErrors();
  }

  initLang(lang = 'fr') {
    const interceptor = (config) => {
      const { method, url } = config;

      if (method === 'get') {
        if (url && !url.includes('lang=')) {
          config.url = `${url}${url.indexOf('?') >= 0 ? '&' : '?'}lang=${lang}`;
        }
      }

      return config;
    };

    this.setInterceptor(interceptor);
  }

  initParams() {
    const interceptor = (config) => {
      const params = {};

      for (const param in config.params) {
        // eslint-disable-next-line
        if (config.params.hasOwnProperty(param)) {
          if (config.params[param] !== false && config.params[param] !== null) {
            params[param] = config.params[param];
          }
        }
      }

      config.params = params;
      return config;
    };

    this.setInterceptor(interceptor);
  }

  initErrors() {
    const interceptor = (response) => {
      const { data } = response;

      if (data.status) {
        if (data.status !== 'success') {
          dispatchNotif({ text: `Error: ${data.message}`, type: 'error' });
        }

        if (!data.data) return { status: data.status, data };
        return data;
      }

      // if (!data.data) return { status: data.status, data };
      return { status: 'success', data: data.data ? data.data : data }
    };

    const error = async (e) => {
      const { response } = e;
      const data = response?.data ? response.data : {};

      dispatchNotif({
        text: `Error: ${data.message ? data.message : e.message}`,
        type: 'error'
      });

      if (response & response.status === 401) {
        const currentRoute = router.currentRoute.value;
        console.log('currentRoute.name.match(/Login|Register|Forgot|Subscribe|Reset/ :', currentRoute.name);
        console.log('currentRoute.name.match(/Login|Register|Forgot|Subscribe|Reset/ :', currentRoute.name.match(/Login|Register|Forgot|Subscribe|Reset/));
        if (!currentRoute.name.match(/Login|Register|Forgot|Subscribe|Reset/)) {
        // if (currentRoute.name !== 'Login') {
          router.push({ name: 'Login' });
        }
      }

      return {
        status: 'error',
        data: e
      }
    };

    this.setInterceptor(interceptor, error, 'response');
  }

  setInterceptor(func, error, type = 'request') {
    const e = error ? error : (error) => Promise.reject(error);
    this.axios.interceptors[type].use(func, e);
  }

  async call(type, url, params) {
    const callUrl = this.endpoints[url] || url;
    const request = await this.axios[type](callUrl, params);
    return request;
  }

  async get(url, params) {
    const request = await this.call('get', url, params);
    return request;
  }

  async post(url, params) {
    const request = await this.call('post', url, params);
    return request;
  }

  async delete(url, params) {
    const request = await this.call('delete', url, params);
    return request;
  }
}
