import { calls, endpoints } from '@/services/calls';
import { dispatchNotif } from '@/services/notifications';

const preview = {
  namespaced: true,
  state: {
    requests: 0,
    document: null,
    iframeURL: null,
    legalCases: []
  },
  mutations: {
    setDocument(state, doc) {
      state.document = doc;
    },
    updateLegalcases(state, items) {
      state.legalCases = items;
    },
    incRequests(state) {
      state.requests++;
    },
    decRequests(state) {
      state.requests--;
    }
  },
  actions: {
    set({ state, commit, dispatch }, doc) {
      if (state.iframeURL) {
        URL.revokeObjectURL(state.iframeURL);
        state.iframeURL = null;
      }

      commit('setDocument', doc);
      dispatch('get');
    },
    async get({ state, commit }) {
      commit('incRequests');
      const doc = state.document;

      const url = endpoints.downloadDoc.replace('{token}', doc.token);
      const request = await calls.get(url, { responseType: 'arraybuffer' });
      // const request = await downloadDocument(doc.token);

      if (request.status === 'success') {
        const blob = new Blob([request.data], { type: doc.mime });
        const blobURL = URL.createObjectURL(blob);
        state.iframeURL = blobURL;
      }

      commit('decRequests');
    },
    async getLegalCases({ state, commit, dispatch }) {
      const request = await calls.get('getLegalCases', { all: false, closed: false });
      // const request = await getLegalcases({ all: false, closed: false });

      if (request.status === 'success') {
        const { data } = request;
        // const { data } = request.data;
        commit('updateLegalcases', data);
      }
    },
    async open({ state, commit }, { type = 'open' }) {
      const doc = state.document;

      if (type === 'open') {
        window.open(state.iframeURL);
      } else {
        const a = document.createElement('a');
        a.href = state.iframeURL;
        a.download = doc.name;
        document.body.querySelector('#sidebar-right').appendChild(a);
        a.click();
        document.body.querySelector('#sidebar-right').removeChild(a);
      }
    },
    async addToCase({ state, commit, dispatch }, legalCaseToken) {
      commit('incRequests');
      const url= endpoints.addDocToLegalCase
        .replace('{legalcase}', legalCaseToken)
        .replace('{doc}', state.document.token);
      const request = await calls.post(url);
      // const request = await addDocToCase(legalCaseToken, state.document.token);

      if (request.status === 'success') {
        dispatchNotif({ type: 'success', text: 'Le document a bien été ajouté à l\'affaire' });
      }

      commit('decRequests');
    }
  }
}

export default preview;
