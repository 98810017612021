<template>
  <div class="detail">
    <Lawcase v-for="lawcase in lawcases" :key="lawcase.id" :lawcase="lawcase"/>

    <div v-if="loading" class="detail-loader">
      <Loader />
    </div>
  </div>
</template>

<script>
import Btn from '@/components/elements/Btn';
import Loader from "@/components/elements/Loader";
import Lawcase from '@/components/modules/Lawcase';

export default {
  components: {
    Btn,
    Loader,
    Lawcase
  },
  computed: {
    search() {
      const { skillsSearch } = this.$store.state.category;
      return skillsSearch;
    },
    lawcases() {
      const { lawcases } = this.$store.state.category;
      const filtereds = lawcases.filter(cat => {
        if(!this.search) return true;
        const name = cat.name.toLowerCase();
        return name.match(this.search);
      });
      return filtereds;
    },
    loading() {
      const { requests } = this.$store.state.category;
      return requests !== 0;
    }
  },
  beforeMount() {
    const { id } = this.$route.params;
    this.$store.commit('category/update', { prop: 'id', value: id });
    this.$store.commit('category/update', { prop: 'lawyers', value: [] });
    this.$store.commit('category/update', { prop: 'lawcases', value: [] });
    this.$store.dispatch('category/getLawcases');
    this.$store.dispatch('category/getLawyers');
  },
}
</script>


<style lang="scss" scoped>
.detail {
  position: relative;

  &-loader {
    &:not(:first-child) {
      @include position(absolute, 0 null null 0);
      @include size(100%, auto);
      z-index: 1;
      background-color: rgba($white, .7);
    }
  }

  .lawcase {
    + .lawcase {
      margin-top: 8px;
    }
  }
}
</style>