<template>
  <div :class="`three-dots${open ? ' three-dots--open' : ''}`" ref="container">
    <button
      class="three-dots-toggle"
      aria-haspopup="menu"
      @click="open = !open"
    >
      <span class="u-visually-hidden">Open</span>
      <span class="three-dots-toggle-dots" />
    </button>

    <ul
      v-show="open"
      :aria-expanded="open"
      class="three-dots-menu"
      role="menu"
    >
      <li
        v-for="(item, index) in items"
        :key="`three-dots-${index}`"
        class="three-dots-menu-item"
      >
        <button v-if="item.action" class="three-dots-menu-link">
          {{ item.label }}
        </button>
        <a v-else-if="item.href" :href="item.href" target="_blank" class="three-dots-menu-link">
          {{ item.label }}
        </a>
        <router-link v-else-if="item.to" :to="item.to" class="three-dots-menu-link">
          {{ item.label }}
        </router-link>
      </li>
    </ul>
  </div>
</template>


<script>
import { isDescendant } from '@/helpers';

export default {
  props: {
    items: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      open: false,
      // eslint-disable-next-line
      _documentClick: this.onDocumentClick.bind(this),
    }
  },
  watch: {
    open(val) {
      if (val) {
        // Not launching directly the listener to avoid the trigger on the toggle
        setTimeout(() => {
          document.addEventListener('click', this._documentClick);
        }, 100);
        return;
      }

      document.removeEventListener('click', this._documentClick);
    }
  },
  methods: {
    onDocumentClick(e) {
      const { target } = e;
      const descendant = isDescendant(this.$refs.container, target);
      if (!descendant && this.open) {
        this.open = false;
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.three-dots {
  position: relative;

  &--open {
    z-index: 1;
  }

  &-toggle {
    @include size(32px);
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    z-index: 1;
    margin-left: 16px;
    border-radius: 10px;
    background-color: var(--white-dark);

    &-dots {
      @include size(3px);
      position: relative;
      border-radius: 50%;
      background-color: var(--black);
      transition: background-color .2s $ease-out-quart;
      transition-delay: .05s;

      &::before,
      &::after {
        @include position(absolute, 0 null null null);
        @include size(100%);
        content: '';
        border-radius: 50%;
        background-color: var(--black);
        transition: background-color .2s $ease-out-quart;
      }

      &::before {
        right: 200%;
      }

      &::after {
        left: 200%;
        transition-delay: .1s;
      }
    }
  }

  &-menu {
    @include position(absolute, calc(100% - 4px) 0 null null);
    border-radius: 8px;
    background-color: var(--white);
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.16);
    z-index: 2;

    &-item {
      + .three-dots-menu-item {
        border-top: var(--grey-border);
      }
    }

    &-link {
      @include padding(8px 16px);
      display: block;
      transition: color .3s $ease-out-quart;
      white-space: nowrap;
      color: var(--black-light);
      text-decoration: none;

      &:hover,
      &:focus {
        color: var(--primary);
      }
    }
  }
}
</style>