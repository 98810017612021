<template>
  <div
    v-if="display"
    ref="container"
    :class="[
      display,
      'sidebar sidebar-left',
      {
        'sidebar--full': display?.match(/officeMenu|accountMenu/),
        'is-visible': isVisible
      }
    ]"
  >
    <div v-if="display === 'search'" class="sidebar-header">
      <Btn
        class="sidebar-close"
        theme="icon"
        @click="onClose"
      >
        <Sprite
          :type="'close-big'"
          :width="20"
          :height="20"
        />
      </Btn>
    </div>

    <div class="sidebar-content">
      <Search v-if="display === 'search'" :fields="fields"/>
      <OfficeMenu v-else-if="display === 'officeMenu'" />
      <AccountMenu v-else-if="display === 'accountMenu'" />
    </div>
    <!-- </div> -->
  </div>
</template>

<script>
import Btn from '@/components/elements/Btn';
import Sprite from '@/components/elements/Sprite';
import Search from '@/components/modules/sidebar/Search';
import OfficeMenu from '@/components/modules/sidebar/OfficeMenu';
import AccountMenu from '@/components/modules/sidebar/AccountMenu';
import { isDescendant } from '@/helpers';

import fields from '@/data/static/search.json';

export default {
  components: {
    Btn,
    Sprite,
    Search,
    OfficeMenu,
    AccountMenu
  },
  data() {
    return {
      fields,
      // eslint-disable-next-line
      _documentClick: this.onDocumentClick.bind(this),
    }
  },
  computed: {
    isVisible() {
      const { search, menu } = this.$store.state.responsive.visibilities;
      return search || menu;
    },
    display() {
      const { meta } = this.$route;
      return meta.sidebarLeft;
    },
  },
  watch: {
    $route() {
      this.onClose();
    },
    isVisible(val) {
      if (window.innerWidth >= 768) return;
      if (val) {
        // Not launching directly the listener to avoid the trigger on the toggle
        setTimeout(() => {
          document.addEventListener('click', this._documentClick);
        }, 100);
        return;
      }

      document.removeEventListener('click', this._documentClick);
    }
  },
  methods: {
    onClose() {
      const { search, menu } = this.$store.state.responsive.visibilities;
      if (search) this.$store.commit('responsive/toggleVisibility', 'search');
      if (menu) this.$store.commit('responsive/toggleVisibility', 'menu');
    },
    onDocumentClick(e) {
      const { target } = e;
      const descendant = isDescendant(this.$refs.container, target);
      if (!descendant && this.isVisible) {
        this.onClose();
      }
    }
  }
}
</script>

<style lang="scss">
.sidebar-left {
  @include mq($until: 'l') {
    transition: opacity .3s $ease-out-quart, transform .3s $ease-out-quart;

    &:not(.is-visible) {
      transform: translateX(10px);
      opacity: 0;
      pointer-events: none;
    }
  }
}
</style>