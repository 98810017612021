<template>
  <div class="lawyers">
    <!-- <h2 class="lawyers-title">{{ $t('sidebar.lawyers.title') }}</h2> -->
    <h2 class="lawyers-title">Cette matière est active pour...</h2>
    <ul class="lawyers-list">
      <li
        v-for="lawyer in lawyers"
        :key="lawyer.id"
        class="lawyer"
      >
        <template v-if="isAdmin">
          <input
            :id="`lawyer-${lawyer.id}`"
            :value="lawyer.id"
            :checked="lawyer.checked"
            name="lawyers"
            type="checkbox"
            class="u-visually-hidden lawyer-input"
          />
          <label :for="`lawyer-${lawyer.id}`" class="lawyer-label">
            <figure v-if="lawyer.picture" class="lawyer-label-picture">
              <img :src="lawyer.picture"/>
            </figure>
            <span v-else class="lawyer-label-picture">{{ lawyer.plus ? `+${lawyer.plus}` : lawyer.name.charAt(0) }}</span>

            <p class="lawyer-label-text">{{ lawyer.name }}</p>

            <!-- <span class="lawyer-label-check">
              <Sprite type="check"/>
            </span> -->
          </label>
        </template>
        <div v-else class="lawyer-label">
          <figure v-if="lawyer.profile_picture_path" class="lawyer-label-picture">
            <img :src="lawyer.profile_picture_path"/>
          </figure>
          <span v-else class="lawyer-label-picture">{{ lawyer.plus ? `+${lawyer.plus}` : lawyer.name.charAt(0) }}</span>

          <p class="lawyer-label-text">{{ lawyer.name }}</p>
        </div>
      </li>
    </ul>

    <!-- <div class="lawyers-actions">
      <Btn @onClick="onDelete">{{ $t("actions.delete-lawcase") }}</Btn>
    </div> -->
  </div>
</template>

<script>
import Btn from '@/components/elements/Btn';
import Sprite from '@/components/elements/Sprite';

export default {
  components: {
    Btn,
    Sprite    
  },
  computed: {
    isAdmin() {
      // Currently false as we don't handle user case on admin
      return false;
      const { user, client } = this.$store.state;
      return user.data.id === client.data.admin_id;
    },
    lawyers() {
      const { lawyers } = this.$store.state.category;
      return lawyers;
    }
  },
  methods: {
    onDelete() {
      console.log('on delete');
    }
  }
}
</script>


<style lang="scss" scoped>
.lawyers {
  @include padding(16px);
  display: flex;
  flex-direction: column;
  height: 100%;

  &-title {
    flex-shrink: 0;
    padding-bottom: 8px;
    opacity: .6;
  }

  // Lawyers
  &-list {
    flex-grow: 1;
    overflow: auto;
  }

  .lawyer {
    &:not(:first-child) {
      border-top: 1px solid var(--grey-border);
    }

    &-input {
      &:checked {
        + .lawyer-label {
          .lawyer-label-check {
            border-color: var(--primary);
            background-color: var(--primary);

            .svg {
              display: block;
            }
          }
        }
      }
    }

    &-label {
      @include padding(12px null);
      display: flex;
      align-items: center;
      width: 100%;
      cursor: pointer;

      &:is(label):focus,
      &:is(label):hover {
        .lawyer-label-text {
          color: var(--primary);
        }
      }

      &-picture {
        @include size(32px);
        display: block;
        overflow: hidden;
        margin-right: 12px;
        border-radius: 50%;
        background-color: var(--grey-lightest);
        text-align: center;
        font-size: rem(10px);
        line-height: 32px;

        img {
          @include size(30px);
          display: block;
          border-radius: 50%;
        }
      }

      &-text {
        transition: color .3s $ease-out-quart;
      }

      &-check {
        @include size(18px);
        margin-left: auto;
        border-radius: 50%;
        border: 1px solid var(--grey-lighter);
        background-color: var(--white-dark);

        .svg {
          display: none;
          fill: var(--white);
        }
      }
    }
  }

  // Actions
  &-actions {
    padding-top: 8px;
    flex-shrink: 0;
    // margin-top: auto;

    .button {
      width: 100%;
    }
  }
}
</style>