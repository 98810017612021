<template>
  <footer class="footer">
    <ul class="footer-list">
      <li class="footer-list-item">
        <span class="footer-list-link">© Symplicy</span>
      </li>
      <li v-for="link in links" :key="link.label" class="footer-list-item">
        <a :href="link.href" target="_blank" class="footer-list-link">
          {{ link.label }}
        </a>
      </li>
    </ul>
  </footer>
</template>

<script>
export default {
  data() {
    return {
      links: [
        {
          href: 'https://symplicy.com/conditions-generales-de-services/',
          label: this.$t('footer.links.services')
        },
        {
          href: 'https://symplicy.com/politique-en-matiere-de-protection-des-donnees-a-caractere-personnel/ ',
          label: this.$t('footer.links.privacy')
        },
        {
          href: 'https://symplicy.com/politique-en-matiere-de-cookies/',
          label: this.$t('footer.links.cookies')
        },
        {
          href: 'https://symplicy.com/mentions-legales/',
          label: this.$t('footer.links.legals')
        }
      ]
    }
  }
};
</script>

<style lang="scss" scoped>
.footer {
  @include padding(null 16px);
  z-index: 1;

  &-list {
    display: flex;
    // margin-left: 12px;

    @include mq($until: "m") {
      margin-top: 32px;
      flex-direction: column;
      align-items: center;
    }

    &-item {
      &:not(:first-child) {
        margin-top: 8px;

        @include mq($from: "m") {
          @include margin(0 null null 18px);
          position: relative;

          &::before {
            @include position(absolute, calc(50% - 1px) calc(100% + 8px) null null);
            @include size(2px);
            content: '';
            border-radius: 50%;
            background-color: rgba($black-light, .4);
          }
        }
      }
    }

    &-link {
      display: block;
      font-size: rem(12px);
      font-weight: 500;
      color: rgba($black-light, .4);
      text-decoration: none;
      transition: color .3s $ease-out-quart;

      &:is(a):hover {
        color: var(--primary);
      }
    }
  }
}
</style>