<template>
  <div class="legalcase">
    <BackButton :onClick="onBack"/>

    <div class="legalcase-wrapper">
      <div class="legalcase-wrapper-top">
        <!-- Content -->
        <div class="legalcase-wrapper-top-content">
          <h1 class="legalcase-title">{{ `${legalcase.litigant.firstname} ${legalcase.litigant.lastname}` }}</h1>
          <span class="legalcase-subtitle">{{ legalcase.law_case_content ? legalcase.law_case_content : 'Aucun cas concret lié' }}</span>
        </div>

        <!-- Actions -->
        <div class="legalcase-wrapper-top-actions">
          <span
            v-if="rightText"
            class="header-actions-text"
          >
            {{ rightText }}
          </span>
          <Select
            v-if="selectLawyers"
            :value="null"
            :allowNull="true"
            :placeholder="selectLawyers.placeholder"
            :options="selectLawyers.options"
            :selected="selectLawyers.selected"
            @onChange="onLawyerSelect"
          />
          <Btn
            v-for="(btn, index) in actions"
            :key="`view-actions-${index}`"
            :theme="btn.theme"
            @onClick="onAction(btn)"
          >
            {{ typeof btn.label === 'function' ? btn.label() : btn.label }}
          </Btn>
        </div>
      </div>

      <Tabs :tabs="tabs" :light="true"/>
    </div>
  </div>
</template>


<script>
import Btn from '@/components/elements/Btn';
import BackButton from '@/components/elements/BackButton';
import Sprite from '@/components/elements/Sprite';
import Select from '@/components/elements/Select';
import Tabs from '@/components/modules/Tabs';

export default {
  components: {
    Btn,
    BackButton,
    Sprite,
    Select,
    Tabs,
  },
  beforeUnmount() {
    this.$store.commit('upload/updateLegalcase', null);
  },
  computed: {
    legalcase() {
      return this.$store.state.legalCase.data;
    },
    displayTabs() {
      const { data } = this.$store.state.legalCase;
      return data && data.status !== 'new';
    },
    loading() {
      return this.$store.state.legalCase.actionsRequests !== 0;
    },
    rightText() {
      const { status } = this.legalcase;
      return status === 'closed' ? 'Cloturée' : null;
    },
    tabs() {
      const routes = this.$router.getRoutes();
      const route = routes.filter(r => r.name === 'LegalCase')[0];
      const legalcase = this.$store.state.legalCase.data;
      const filtereds = route.children.filter(r => {
        if (!r.meta || !r.meta.if) return true;
        return r.meta.if && r.meta.if.condition(legalcase);
      });

      return filtereds;
    },
    selectLawyers() {
      const { status } = this.legalcase;
      if (status === 'closed') return null;

      const user = this.$store.state.user?.data?.id;
      const legalcaseOwner = this.$store.state.legalCase.data.user_id;
      if (legalcaseOwner !== user) return null;

      const select = {
        placeholder: 'Réassigner l\'affaire',
        options: this.$store.state.client.lawyers
          .filter((l) => l.id !== this.$store.state.user.data.id)
          .map(l => {
            return {
              value: l.id,
              label: l.name
            }
          }),
        selected: this.$store.state.legalCase.data.user_id,
      };

      if (!select.options.length) return null;
      return select;
    },
    actions() {
      if (!this.legalcase) return;
      const { status, user_id } = this.legalcase;
      if (this.$store.state.user.data.id !== user_id) return;
      const { name } = this.$route;
      const actions = [];

      if (status === 'new') {
        actions.push({
          label: 'Refuser',
          theme: 'ghost',
          action: async () => {
            await this.$store.dispatch('legalCase/refuse');
            this.$router.push({ name: 'MyCases' });
          }
        });

        actions.push({
          label: 'Accepter',
          action: () => {
            this.$store.dispatch('legalCase/open');
          }
        });
      }

      if (status === 'opened') {
        actions.push({
          label: 'Cloturer',
          theme: 'ghost',
          action: () => this.$store.dispatch('legalCase/close')
        });
      }

      return actions;
    }
  },
  methods: {
    onBack() {
      const { meta } = this.$route;
      this.$router.push(meta.back ? meta.back : { name: 'LegalCases' });
    },
    onLawyerSelect(event) {
      this.$store.dispatch('legalCase/reassign', event);
    },
    onAction(btn) {
      btn.action();
    }
  }
}
</script>


<style lang="scss" scoped>
.legalcase {
  @include padding(12px 16px null 8px);
  display: flex;
  align-items: flex-start;
  width: 100%;

  // Back
  .backstreet-back {
    margin-right: 24px;
  }

  // Wrapper / Container
  &-wrapper {
    width: calc(100% - 56px);

    &-top {
      display: flex;
      align-items: flex-start;
      width: 100%;

      &-content {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
      }

      &-actions {
        display: flex;
        margin-left: auto;
      }
    }
  }

  // Content
  &-title {
    font-size: rem(24px);
    line-height: (32 / 24);
    font-weight: 700;
  }

  &-subtitle {
    color: var(--black-lighter);
    opacity: .6;
  }

  // Actions
  &-actions-text {
    display: block;
    padding-top: 14px;
    color: var(--grey-lighter);
    font-size: rem(14px);
    font-weight: 700;
    line-height: 1;
  }

  .button {
    + .button {
      margin-left: 8px;
    }
  }

  .select {
    min-width: 200px;
    margin-right: 16px;

    &.select--open {
      z-index: 2;
    }
  }

  // Tabs
  .tabs {
    margin-top: 8px;
  }
}
</style>