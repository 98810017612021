import { calls, endpoints } from '@/services/calls';
import { dispatchNotif } from '@/services/notifications';

import globals from '@/data/static/globals';

const office = {
  namespaced: true,
  state: {
    requests: 0,
    // policies: {
    //   terms: [],
    //   privacy: []
    // },
    langs: null
  },
  mutations: {
    update(state, { prop, value }) {
      state[prop] = value;
    },
    // updatePolicyLang(state, { value, type, index }) {
    //   state.policies[type][index].lang = value;
    // },
    // updatePolicyProperty(state, { type, property, value, index, lang }) {
    //   if (lang) {
    //     state.policies[type] = state.policies[type].map(p => {
    //       if (p.lang === lang) p[property] = value;
    //       return p;
    //     });
    //     return;
    //   }

    //   state.policies[type][index][property] = value;
    // },
    addLang(state, lang) {
      state.langs[lang] = {};
      for (let i = 0; i < globals.policies.types.length; i++) {
        const type = globals.policies.types[i];
        state.langs[lang][type] = {
          type,
          lang,
          content: "",
          url: "",
        };
      }
    },
    deleteLang(state, lang) {
      delete state.langs[lang];
    },
    addPolicy(state, { type, lang }) {
      state.policies[type].push({
        lang: lang ? lang : null,
        content: "",
        url: "",
      });
    },
    deletePolicy(state, { type, index, lang }) {
      let ind = index;

      if (lang) {
        const policies = state.policies[type];

        for (let i = 0; i < policies.length; i++) {
          const el = policies[i];
          if (el.lang === lang) {
            ind = i;
            break;
          }
        }
      }

      state.policies[type].splice(ind, 1);
    },
    // setPolicy(state, { type, value }) {
    //   state.policies[type] = value;
    // },
    // setPolicies(state, policies) {
    //   // for (const prop in policies) {
    //   //   const pol = policies[prop];
    //   //   policies[prop].error = typeof pol.error !== undefined ? pol.error : '';
    //   // }
    //   state.policies = {
    //     ...state.policies,
    //     ...policies
    //   }
    // },
    incRequests(state, type = 'requests') {
      state[type]++;
    },
    decRequests(state, type = 'requests') {
      state[type]--;
    }
  },
  actions: {
    reset({ commit }) {
      commit('update', { prop: 'langs', value: null });
    },
    // validatePolicies({ state, commit, dispatch }) {
    //   const { policies } = state;
    //   const types = ["privacy", "terms"];
    //   // const hasError = 

    //   for (let i = 0; i < types.length; i++) {
    //     const type = types[i];
    //     policies[type] = policies[type]
    //       .filter(pol => {
    //         return pol.content?.length && pol.content !== '<p><br></p>';
    //       });
    //   }

    //   return true;
    // },
    async getPolicies({ rootState, state, commit, dispatch }) {
      commit('incRequests');
      const { uuid } = rootState.client;
      const url = endpoints.getClientPolicies.replace('{uuid}', uuid);
      const request = await calls.get(url);

      if (request.status === 'success') {
        const { data } = request;
        const langs = {};
        const types = ["privacy", "terms"];

        // From policies object to langs object
        for (const type in data) {
          const policies = data[type];

          for (let i = 0; i < policies.length; i++) {
            const policy = policies[i];
            policy.type = type;
            const { lang } = policy;
            if (!langs[lang]) langs[lang] = {};
            langs[lang][type] = policy;
          }
        }

        for (const lang in langs) {
          const l = langs[lang];

          for (const type of types) {
            if (!l[type]) {
              l[type] = {
                content: '',
                lang,
                type,
                url: null
              }
            }
          }
        }

        commit('update', { prop: 'langs', value: langs });
        // commit('setPolicies', data);
      }

      commit('decRequests');
    },
    async savePolicies({ rootState, state, commit, dispatch }) {
      commit('incRequests');
      const { uuid } = rootState.client;

      // await dispatch('validatePolicies');
      // const policies = {...state.policies};
      // // policies.terms.map(pol => {
      // //   delete
      // // })
      // const url = endpoints.setPolicies.replace('{uuid}', uuid);
      // const request = await calls.post(url, { policies: state.policies });
      // const request = await savePolicies(uuid, state.policies);

      const policies = {};
      for(const lang in state.langs) {
        for (const policy in state.langs[lang]) {
          if (!policies[policy]) policies[policy] = [];
          const pol = state.langs[lang][policy];
          if(pol?.content?.length && pol?.content !== '<p><br></p>') {
            policies[policy].push(pol);
          }
        }
      }
      const types = ["privacy", "terms"];
      for (let i = 0; i < types.length; i++) {
        const type = types[i];
        if (!policies[type]) policies[type] = {};
      }
      const url = endpoints.setClientPolicies.replace('{uuid}', uuid);
      const request = await calls.post(url, { policies });
      // const request = await savePolicies(uuid, policies);

      if (request.status === 'success') {
        dispatchNotif({ type: 'success', text: 'Contrats enregistrés !' });
        dispatch('getPolicies');
      }

      commit('decRequests');
    }
  }
}

export default office;
