<template>
  <div class="textzone">
    <h2
      v-if="title"
      class="headline textzone-title"
    >
      {{ title }}
    </h2>
    <div
      ref="content"
      class="textzone-content"
      v-html="content"
    />
  </div>
</template>

<script>
import axios from 'axios';

export default {
  props: {
    title: {
      type: String,
      default: null
    },
    content: {
      type: String,
      default: null
    }
  },
  mounted() {
    this.bindLinks();
  },
  beforeUnmount() {
    this.unBindLinks();
  },
  data() {
    return {
      links: [],
      downloads: [],
      linkClick: this.onLinkClick,
      downloadClick: this.onDownloadClick,
    }
  },
  methods: {
    bindLinks() {
      this.links = [...this.$refs.content.querySelectorAll('[data-vue-link]')];
      this.downloads = [...this.$refs.content.querySelectorAll('[data-vue-download]')];

      if (!this.links.length && !this.downloads.length) return;

      this.links.map($l => { $l.addEventListener('click', this.linkClick);});
      this.downloads.map($d => { $d.addEventListener('click', this.downloadClick);});
    },
    unBindLinks() {
      this.links.map($l => {
        $l.removeEventListener('click', this.linkClick);
      });
      this.downloads.map($d => {
        $d.removeEventListener('click', this.downloadClick);
      });
    },
    onLinkClick(e) {
      e.preventDefault();
      const { currentTarget } = e;
      const { vueLink } = currentTarget.dataset;
      this.$router.push({ path: vueLink });
    },
    async onDownloadClick(e) {
      e.preventDefault();
      const { currentTarget } = e;
      const { vueDownload, vueDownloadName } = currentTarget.dataset;
      const split = vueDownload.split('::');
      const url = split[0];
      const mime = split[1];
      const request = await axios.get(url, { responseType: 'arraybuffer' });

      if (request.status === 200) {
        const blob = new Blob([request.data], { type: mime });
        const blobURL = URL.createObjectURL(blob);

        const a = document.createElement('a');
        a.href = blobURL;
        a.download = vueDownloadName ? vueDownloadName : currentTarget.textContent;
        this.$refs.content.appendChild(a);
        a.click();
        this.$refs.content.removeChild(a);
      }
    },
  }
}
</script>

<style lang="scss" scoped>
.textzone {
  &:not(:first-child) {
    margin-top: 16px;
  }

  &-title {
    @include padding(null 16px);
    margin-bottom: 8px;
  }

  &-content {
    @include padding(8px 16px);
    border-radius: 8px;
    background-color: var(--white);
  }
}
</style>

<style lang="scss">
.textzone {
  &-content {
    pre {
      white-space: pre-wrap;  
    }
  }
}
</style>