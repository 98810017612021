<template>
  <div class="page-policies">
    <Header
      :title="$t('pages.office.policies.title')"
      :text="$t('pages.office.policies.text')"
      :small="$t('pages.office.policies.small')"
      size="large"
      :actions="[
        {
          label: $t('actions.save'),
          action: onSave,
        },
      ]"
    />

    <div class="langs">
      <ul class="langs-list">
        <li
          v-for="(lang, key) in langs"
          :key="key"
          :class="`langs-item${current === key ? ' current' : ''}`"
        >
          <button class="langs-btn" @click="onLang(key)">
            {{ $t(`languages.${key}`) }}
          </button>

          <div
            :class="`langs-dots${dots === key ? ' is-active' : ''}`"
            :ref="`dots-${key}`"
          >
            <button class="langs-dots-toggle" @click="onToggleDots(key)">
              <span class="u-visually-hidden">
                {{ $t(`languages.${key}`) }}
              </span>
              <Sprite :type="'dots'" />
            </button>
            <div class="langs-dots-menu">
              <li class="langs-dots-menu-option">
                <button class="langs-dots-menu-btn" @click="onDelete(key)">
                  Supprimer la langue
                </button>
              </li>
            </div>
          </div>
        </li>
        <li v-if="availableLangs?.length" class="langs-item">
          <button class="langs-btn btn-add">+ Ajouter une langue</button>

          <ul class="langs-dropdown">
            <li
              v-for="l in availableLangs"
              :key="`lang-${l}`"
              class="langs-dropdown-option"
            >
              <button class="langs-dropdown-btn" @click="onAdd(l)">
                {{ $t(`languages.${l}`) }}
              </button>
            </li>
          </ul>
        </li>
      </ul>
    </div>

    <div class="policies">
      <template v-if="langs && langs[current]">
        <div
          v-for="type in types"
          :key="`policy-${type}`"
          :class="`policy${loading ? ' is-loading' : ''}`"
        >
          <h2 class="policy-title">{{ $t(`labels.${type}`) }}</h2>
          <div class="policy-field">
            <QuillEditor
              :ref="`${type}Editor`"
              theme="snow"
              contentType="html"
              v-model:content="lang[type].content"
              :toolbar="toolbar"
              :placeholder="$t(`placeholders.${type}`)"
            />
          </div>
        </div>
      </template>

      <p v-else-if="!loading" class="policies-no-result headline">
        {{ $t("pages.office.policies.no-policy") }}
      </p>

      <div v-if="loading" class="policies-loader">
        <Loader />
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import { QuillEditor } from "@vueup/vue-quill";
import Btn from "@/components/elements/Btn";
import Input from "@/components/elements/Input";
import Loader from "@/components/elements/Loader";
import Select from "@/components/elements/Select";
import Sprite from "@/components/elements/Sprite";
import Header from "@/components/modules/Header";
import "@vueup/vue-quill/dist/vue-quill.snow.css";
import globals from "@/data/static/globals.json";

export default {
  components: {
    QuillEditor,
    Btn,
    Input,
    Loader,
    Select,
    Sprite,
    Header,
  },
  beforeMount() {
    this.$store.dispatch("office/getPolicies");
  },
  data() {
    return {
      dots: null,
      current: "en",
      types: ["terms", "privacy"],
      toolbar: [
        [{ header: [1, 2, 3, 4, false] }],
        ["bold", "italic", "underline"],
        [{ list: "ordered" }, { list: "bullet" }],
        ["link"],
      ],
      _onDeleteDocumentClick: this.onDeleteDocumentClick.bind(this),
    };
  },
  computed: {
    policies() {
      return this.$store.state.office.policies;
    },
    lang() {
      const lang = this.$store.state.office.langs?.[this.current];
      return lang;
    },
    langs() {
      const { langs } = this.$store.state.office;
      return langs;
    },
    availableLangs() {
      const availables = globals.languages.filter((l) => !this.langs?.[l]);
      return availables;
    },
    loading() {
      return this.$store.state.office.requests !== 0;
    },
  },
  watch: {
    langs(langs) {
      if (langs) {
        if (!langs.en) {
          for (let i = 0; i < globals.languages.length; i++) {
            const lang = globals.languages[i];
            if (langs[lang]) {
              this.current = lang;
              break;
            }
          }
        }
      }
    },
    dots(val, prev) {
      if (val && !prev) {
        setTimeout(() => {
          document.addEventListener("click", this._onDeleteDocumentClick);
        }, 10);
      }

      if (!val && prev)
        document.removeEventListener("click", this._onDeleteDocumentClick);
    }
  },
  methods: {
    onLang(key) {
      this.current = key;
      this.resetEditors();
    },
    resetEditors() {
      for (let i = 0; i < this.types.length; i++) {
        const type = this.types[i];
        const editor = this.$refs[`${type}Editor`];
        if (!this.lang?.[type].content) editor.setHTML('');
      }
    },
    onAdd(lang) {
      this.$store.commit("office/addLang", lang);
      this.current = lang;
      this.resetEditors();
    },
    onToggleDots(lang) {
      this.dots = this.dots === lang ? null : lang;
    },
    onDelete(lang) {
      this.$store.commit("office/deleteLang", lang);
      const langs = ['en', 'fr', 'nl'];
      for (let i = 0; i < langs.length; i++) {
        const lang = langs[i];
        if (this.langs[lang]) {
          this.current = lang;
          break;
        }
      }
    },
    onSave() {
      this.$store.dispatch("office/savePolicies");
    },
    // getAvailableLangs(type) {
    //   const langs = this.policies[type].map((p) => p.lang);
    //   return globals.languages.filter((l) => langs.indexOf(l) < 0);
    // },
    onLanguageChange(value, type, index) {
      this.$store.commit("office/updatePolicyLang", { value, type, index });
    },
    onDeleteDocumentClick(e) {
      const { target } = e;
      const container = this.$refs[`dots-${this.dots}`];
      const isDescendant = this.isDescendant(container, target);
      if (!isDescendant) {
        this.onToggleDots(null);
      }
    },
    isDescendant(parent, child) {
      let node = child.parentNode;
      while (node) {
        if (node === parent) return true;
        node = node.parentNode;
      }

      return false;
    },
  },
};
</script>

<style lang="scss" scoped>
.policies {
  position: relative;

  &-loader {
    @include position(absolute, 0 null null 0);
    @include size(100%);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1;
  }

  &-no-result {
    @include margin(16px);
    text-align: center;
  }
}

.policy {
  display: flex;
  flex-direction: column;
  // align-items: center;
  margin-top: 32px;

  &.is-loading {
    opacity: 0.3;
  }

  + .policy {
    margin-top: 16px;
  }

  &-title {
    margin-bottom: 8px;
  }

  &-item {
    width: 100%;
    margin-top: 16px;

    &-header {
      display: flex;
      justify-content: space-between;
    }

    &-title {
      font-weight: 700;
    }

    &-close {
      .svg {
        fill: var(--grey-lighter);
      }

      &:hover {
        .svg {
          fill: var(--primary);
        }
      }
    }

    &-field {
      &:not(:first-child) {
        margin-top: 12px;
      }
    }

    + .policy-item {
      margin-top: 32px;
      padding-top: 26px;
      border-top: 1px solid var(--grey-border);
    }
  }

  .button {
    margin-top: 32px;
  }
}

.editor {
  margin-top: 32px;

  .label {
    font-size: rem(14px);
    line-height: (24 / 14);
    margin-bottom: 4px;
  }
}

.langs {
  border-bottom: 1px solid var(--grey-lightest);
  margin-top: -8px;

  &-list {
    @include margin(null -8px);
    display: flex;
  }

  &-item {
    @include margin(null 4px);
    display: flex;
    align-items: center;
    position: relative;

    &::before {
      @include size(calc(100% - 8px), 2px);
      @include position(absolute, null null -1px 4px);
      content: "";
      background-color: var(--primary);
      transform: scaleY(0);
      transform-origin: 50% 100%;
    }

    &.current {
      &::before {
        transform: scaleY(1);
      }

      .langs-btn {
        color: var(--primary);
      }
    }
  }

  &-btn {
    @include padding(9px 4px);
    display: block;
    font-size: rem(14px);
    line-height: 1;
    color: var(--black-light);

    &.btn-add {
      min-width: 170px;
      color: var(--primary);
      text-align: left;

      &:hover {
        + .langs-dropdown {
          transform: translateY(0);
          opacity: 1;
          pointer-events: all;
        }
      }
    }
  }

  &-dots {
    @include margin(null 4px null null);
    position: relative;

    &.is-active {
      .langs-dots-toggle {
        background-color: var(--grey-lightest);
      }

      .langs-dots-menu {
        display: flex;
      }
    }

    &-toggle {
      display: block;
      border-radius: 4px;
      transition: background-color 0.3s $ease-out-quart;

      &:hover {
        .svg {
          fill: var(--primary);
        }
      }

      .svg {
        fill: var(--grey);
        transition: fill 0.3s $ease-out-quart;
      }
    }

    &-menu {
      @include position(absolute, 100% null null 0);
      display: none;
      z-index: 2;
      flex-direction: column;
      background-color: var(--white);
      border-radius: 4px;
      overflow: hidden;
      min-width: 100%;

      &-option {
        overflow: hidden;
        border-bottom: 1px solid var(--grey-lightest);

        &:first-child {
          border-radius: 4px 4px 0 0;
        }

        &:last-child {
          border-radius: 0 0 4px 4px;
          border-bottom: 0;
        }
      }

      &-btn {
        @include padding(13px 8px);
        display: block;
        color: var(--black-light);
        font-size: rem(14px);
        line-height: 1;
        text-align: left;
        white-space: nowrap;

        &:hover {
          color: var(--primary);
        }
      }
    }
  }

  &-dropdown {
    @include position(absolute, 100% null null 0);
    // display: none;
    z-index: 2;
    flex-direction: column;
    background-color: var(--white);
    border-radius: 4px;
    overflow: hidden;
    min-width: 100%;
    transform: translateY(-10px);
    opacity: 0;
    transition: transform 0.2s $ease-out-quart, opacity 0.2s $ease-out-quart;
    pointer-events: none;

    &:hover {
      transform: translateY(0);
      opacity: 1;
      pointer-events: all;
    }

    &-option {
      overflow: hidden;
      border-bottom: 1px solid var(--grey-lightest);

      &:first-child {
        border-radius: 4px 4px 0 0;
      }

      &:last-child {
        border-radius: 0 0 4px 4px;
        border-bottom: 0;
      }
    }

    &-btn {
      @include padding(13px 8px);
      display: block;
      width: 100%;
      color: var(--black-light);
      font-size: rem(14px);
      line-height: 1;
      text-align: left;
      transition: color 0.3s $ease-out-quart;

      &:hover {
        color: var(--primary);
      }
    }
  }
}
</style>

<style lang="scss">
.ql-toolbar {
  border-radius: 8px 8px 0 0;
  border-color: var(--grey-lightest) !important;
  background-color: var(--white);

  .ql-formats {
    &:last-child {
      margin-right: 0;
    }

    .ql-picker {
      border: 1px solid var(--grey-lightest);
    }
  }
}

.ql-container {
  border-color: var(--grey-lightest) !important;
  border-radius: 0 0 8px 8px;
  background-color: var(--white);

  .ql-editor {
    min-height: 260px;

    strong {
      font-weight: bold;
    }

    em {
      font-style: italic;
    }

    u {
      text-decoration: underline;
    }
  }
}
</style>