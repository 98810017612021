

export const dispatchNotif = (notif) => {
  const e = new CustomEvent('notifications', { detail: notif });
  document.dispatchEvent(e);
}

export default class Notifications {
  constructor(component) {
    this.instance = component;
    this.bindEvents();
  }
  
  bindEvents() {
    document.addEventListener('notifications', this.onDocumentNotif.bind(this));
  }

  unbindEvents() {
    document.removeEventListener('notifications', this.onDocumentNotif);
  }

  onDocumentNotif({ detail }) {
    if (!this.instance) return;
    this.instance.$store.dispatch(
      'notifications/addNotifs',
      [detail]
    );
  }
}

