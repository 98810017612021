<template>
  <div class="lawyer">
    <div class="lawyer-content">
      <h2 class="lawyer-title">{{ lawyer.name }}</h2>
      <p>{{ lawyer.email }}</p>

      <template v-if="isAdmin">
        <Radio
          :label="$t('sidebar.lawyer.label')"
          :value="client.admin_id === lawyer.id ? 1 : 0"
          :options="[
            {
              label: $t('sidebar.lawyer.options.user'),
              value: 0,
              disabled: lawyer.id === client.admin_id
            },
            {
              label: $t('sidebar.lawyer.options.admin'),
              value: 1,
              disabled: lawyer.id === client.admin_id
            }
          ]"
          name="access"
          vertical
          @onChange="onAccess"
        />
        <p v-if="lawyer.id !== client.admin_id && lawyer.id === clientData.admin_id" class="lawyer-warning">{{ $t("sidebar.lawyer.warning") }}</p>
      </template>
    </div>
    <div v-if="isAdmin" class="lawyer-actions">
      <Btn theme="alert" @onClick="onDeleteToggle">{{ $t('actions.delete-lawyer') }}</Btn>
      <Btn :disabled="disabledSave" @onClick="onSave">{{ $t('actions.save') }}</Btn>
    </div>

    <div v-if="loading" class="lawyer-loader">
      <Loader/>
    </div>

    <Popup
      v-if="deleteVisible"
      :title="deletePopup.title"
      @onClose="onDeleteClose"
    >
      <div class="popup-content">
        <p class="popup-text" v-html="deletePopup.text"/>
        <div class="popup-actions">
          <Btn @click="onDeleteClose">{{ deletePopup.cancel }}</Btn>
          <Btn :disabled="disabledDelete" theme="alert" @onClick="onDelete">{{ deletePopup.remove }}</Btn>
        </div>
        <div v-if="deleteLoading" class="popup-loader">
          <Loader />
        </div>
      </div>
    </Popup>
  </div>
</template>

<script>
import Btn from "@/components/elements/Btn";
import Radio from "@/components/elements/Radio";
import Loader from "@/components/elements/Loader";
import Popup from "@/components/partials/Popup";

export default {
  components: {
    Btn,
    Radio,
    Loader,
    Popup
  },
  data() {
    return {
      deleteVisible: false
    }
  },
  computed: {
    loading() {
      const { detail } = this.$store.state.lawyer.requests;
      return detail !== 0;
    },
    deleteLoading() {
      const { remove } = this.$store.state.lawyer.requests;
      return remove !== 0;
    },
    lawyer() {
      const { data } = this.$store.state.lawyer;
      return data;
    },
    client() {
      const { originalData } = this.$store.state.client;
      return originalData;
    },
    clientData() {
      const { data } = this.$store.state.client;
      return data;
    },
    disabledDelete() {
      const isAdmin = this.lawyer.id === this.client.admin_id;
      if (isAdmin) return this.lawyersCount > 1;
      return false;
    },
    lawyersCount() {
      const { data } = this.$store.state.client;
      const lawyers = data.lawyer_list.split(',');
      return lawyers.length;
    },
    disabledSave() {
      const { data } = this.$store.state.client;
      const disabled = data.admin_id === this.client.admin_id;
      return disabled;
    },
    isAdmin() {
      const { user, client } = this.$store.state;
      return user.data.id === client.originalData.admin_id;
    },
    deletePopup() {
      if (this.lawyersCount === 1) {
        return {
          title: this.$t('sidebar.lawyer.popup.title.one'),
          text: this.$t('sidebar.lawyer.popup.text.one'),
          cancel: this.$t('actions.cancel'),
          remove: this.$t('actions.delete')
        }
      }

      if (this.lawyer.id === this.client.admin_id) {
        return {
          title: this.$t('sidebar.lawyer.popup.title.is-admin'),
          text: this.$t('sidebar.lawyer.popup.text.is-admin'),
          cancel: this.$t('actions.cancel'),
          remove: this.$t('actions.delete')
        }
      }

      return {
        title: this.$t('sidebar.lawyer.popup.title.other', { name: this.lawyer.name }),
        text: this.$t('sidebar.lawyer.popup.text.other', { name: this.lawyer.name }),
        cancel: this.$t('actions.cancel'),
        remove: this.$t('actions.delete')
      }
    }
  },
  watch: {
    lawyer() {
      this.reset();
    }
  },
  beforeUnmount() {
    this.reset();
  },
  methods: {
    reset() {
      this.$store.commit('client/updateData', { prop: 'admin_id', value: this.client.admin_id });
    },
    onAccess(e) {
      if (e === 1) {
        this.$store.commit('client/updateData', { prop: 'admin_id', value: this.lawyer.id });
        return;
      }
      this.$store.commit('client/updateData', { prop: 'admin_id', value: this.client.admin_id });
    },
    onDeleteClose() {
      this.deleteVisible = false;
    },
    onDeleteToggle() {
      this.deleteVisible = true;
    },
    async onDelete() {
      if (this.lawyersCount === 1){
        window.open('mailto:info@symplicy.com');
        return;
      }

      if (this.lawyer.id === this.client.admin_id) return;
      const req = await this.$store.dispatch('lawyer/delete');

      if (req.status === 'success') {
        this.$store.dispatch("client/getLawyers");
      }

      this.onDeleteClose();
    },
    async onSave() {
      const req = await this.$store.dispatch('lawyer/setAsAdmin');
      this.$store.dispatch('client/getClient');
    }
  }
}
</script>

<style lang="scss" scoped>
.lawyer {
  @include padding(16px);
  display: flex;
  flex-direction: column;
  height: 100%;
  position: relative;

  &-content {
    flex-grow: 1;
    overflow: auto;
  }

  &-title {
    font-size: rem(16px);
    font-weight: 700;
    line-height: (24 / 16);
  }

  .radio {
    margin-top: 16px;

    :deep(.label) {
      font-weight: 700;
    }
  }

  &-warning {
    @include padding(4px 8px);
    @include margin(8px null null 24px);
    border-radius: 4px;
    background-color: var(--white-darkest);
  }

  &-actions {
    display: flex;
    flex-direction: column;
    flex-shrink: 0;

    .button {
      + .button {
        margin-top: 8px;
      }
    }
  }

  &-loader {
    @include position(absolute, 0 null null 0);
    @include size(100%);
    display: flex;
    align-items: center;
    z-index: 3;
    background-color: rgba($white, .7);
    pointer-events: none;
  }

  .popup {
    &-content {
      position: relative;
    }

    &-actions {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 16px;

      .button {
        width: calc(50% - 8px);
      }
    }

    &-loader {
      @include position(absolute, 0 null null 0);
      @include size(100%);
      display: flex;
      justify-content: center;
      align-items: center;
      z-index: 1;
      background-color: rgba(255, 255, 255, .5);
      }
  }
}
</style>