<template>
  <div class="juridical-documents">
    <template v-if="!loading">
      <List
        v-if="documents && documents.length"
        :items="documents"
        :selected="selected"
        @onClick="onClickDoc"
      />
      <p
        v-if="!documents.length"
        class="noresult"
      >
        {{ $t('documents.noresult.default') }}
      </p>

      <List
        v-if="suggesteds && suggesteds.length"
        :title="$t('lists.documents.suggesteds')"
        :items="suggesteds"
        :selected="selected"
        :actions="suggestedActions"
        @onClick="onClickDoc"
      />
    </template>
    <Loader v-else/>
  </div>
</template>

<script>
import Btn from '@/components/elements/Btn';
import Loader from '@/components/elements/Loader';
import List from '@/components/modules/List';

export default {
  name: 'JuridicalDocuments',
  components: {
    Btn,
    Loader,
    List
  },
  beforeMount() {
    const { params } = this.$route;
    this.$store.commit('documents/updateScopes', ['cabinet', { 'case': params.token }]); // models
    this.$store.dispatch('documents/getDocuments', {});
    this.$store.dispatch('documents/getDocuments', { type: 'suggesteds' });
    this.$store.commit('polysearch/updateName', 'my-documents');
    this.$store.dispatch('polysearch/getFilters');
  },
  data: () => {
    return {
      suggestedActions: [
        {
          icon: 'add',
          action: async (context, doc) => {
            const request = await context.$store.dispatch('legalCase/addDocument', doc.token);
            if (request.status === 'success') {
              context.$store.dispatch('documents/getDocuments', {});
              context.$store.dispatch('documents/getDocuments', { type: 'suggesteds' });
            }
          }
        }
      ]
    }
  },
  computed: {
    documents() {
      return this.$store.state.documents.items;
    },
    suggesteds() {
      const { suggesteds, items } = this.$store.state.documents;
      const itemsTokens = items.map(doc => doc.token);
      return suggesteds.filter(doc => !itemsTokens.includes(doc.token));
    },
    selected() {
      return this.$store.state.preview.document;
    },
    loading() {
      return this.$store.state.documents.requests !== 0
    }
  },
  methods: {
    onClickDoc(doc) {
      this.$store.dispatch('preview/set', doc);
    },
    onAdd() {
       this.$store.commit('upload/toggle')
    }
  }
}
</script>