<template>
  <div class="page legal-cases">
    <Header
      :title="$t(`pages.${page.name.toLowerCase()}.title`)"
      :actions="onboarding ? null : actions"
    />

    <Onboarding v-if="onboarding"/>
    <template v-else>
      <Tabs :tabs="tabs"/>
      <router-view/>
    </template>
  </div>
</template>


<script>
import Loader from '@/components/elements/Loader';
import Tabs from '@/components/modules/Tabs';
import Header from '@/components/modules/Header';
import Onboarding from '@/components/partials/Onboarding';

export default {
  name: 'LegalCases',
  components: {
    Header,
    Tabs,
    Loader,
    Onboarding
  },
  data() {
    return {
      actions: [
        {
          label: () => this.$store.state.legalCases.filters.closed ? 'Masquer les affaires clôturées' : 'Afficher les affaires clôturées',
          theme: 'ghost',
          action: () => {
            const { closed } = this.$store.state.legalCases.filters;
            const { page } = this.$route.query;
            this.$store.commit('legalCases/updateFilter', { filter: 'closed', value: !closed });

            if (page) this.$router.push({ path: this.$route.path});
            else this.$store.dispatch('legalCases/get', {});
          }
        }
      ]
    }
  },
  computed: {
    onboarding() {
      const { data } = this.$store.state.user;
      const onboarding = data?.onboarding;
      if (!onboarding) return false;
      if (onboarding.has_ever_received_a_case) return false;
      return onboarding.has_done_onboarding === false || onboarding.has_user_set_casus_on_their_site === false;
    },
    page() {
      const routes = this.$router.getRoutes();
      const route = routes.filter(r => r.name === 'LegalCases')[0];
      return route;
    },
    tabs() {
      const routes = this.$router.getRoutes();
      const route = routes.filter(r => r.name === 'LegalCases')[0];
      return route.children;
    }
  },
  methods: {
    onClickDoc(doc) {
      this.$store.dispatch('preview/set', doc);
    }
  }
};
</script>
