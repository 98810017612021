<template>
  <div class="page detail">
    <router-view/>
  </div>
</template>

<script>
export default {
  beforeMount() {
    const { params } = this.$route;
    this.$store.commit('legalCase/updateToken', params.token);
    this.$store.commit('upload/updateLegalcase', params.token);
    this.$store.dispatch('legalCase/get');
    this.$store.dispatch('client/getLawyers');
  },
  beforeUnmount() {
    this.$store.commit('upload/updateLegalcase', null);
  },
}
</script>
