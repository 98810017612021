import { calls, endpoints } from '@/services/calls';

const polysearch = {
  namespaced: true,
  state: {
    requests: 0,
    name: 'juridical-documents', // my-documents
    fields: null,
    filters: []
  },
  mutations: {
    setFields(state, fields) {
      state.fields = fields;
    },
    setFilters(state, filters) {
      state.filters = filters;
    },
    updateName(state, name) {
      state.name = name;
    },
    updateValue(state, { field, value }) {
      state.fields[field] = value;
    },
    incRequests(state) {
      state.requests++;
    },
    decRequests(state) {
      state.requests--;
    }
  },
  actions: {
    async getFilters({ state, commit }) {
      commit('incRequests');
      const { name } = state;
      const url = endpoints.getSearchFields.replace('{name}', name);
      const request = await calls.get(url);
      // const request = await getSearchFields(name);

      if (request.status === 'success') {
        const { data } = request;
        commit('setFilters', data);

        const fields = {};
        data.map(field => {
          if (field.component === 'block') field.fields.map(f => fields[f.data] = null);
          else fields[field.data] = null;
        });

        commit('setFields', fields);
      }

      commit('decRequests');
      return request;
    }
  }
}

export default polysearch;
