<template>
  <div class="page account">
    <router-view />
  </div>
</template>

<script>
export default {
  name: 'Account'
};
</script>
