<template>
  <div class="staging">
    <div class="container">
      <p>Staging</p>
    </div>
  </div>
</template>

<script>
</script>

<style lang="scss" scoped>
.staging {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 1;
  width: 100%;
  height: 30px;
  background-color: rgba($primary, 1);
  grid-area: stagingbar;

  p {
    color: var(--white);
    text-align: center;
  }
}
</style>