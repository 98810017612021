<template>
<!-- HTML -->
  <div
    :class="['toolbox', { 'at-bottom': atBottom }]"
    :style="{
      'top': `${top}px`,
      'left': typeof(left) === 'number'? `${left}px` : left
    }"
  >
    <div class="toolbox-container">
      <button
        class="toolbox-close"
        @click="onClose"
      >
        <sprite :type="'close-big'"/>
      </button>

      <form v-if="add" class="toolbox-add">
        <h3 class="toolbox-title">Nouveau tag...</h3>
        <div class="toolbox-add-field">
          <label for="toolbox-add-tag">Nom du tag</label>
          <input
            ref="input"
            id="toolbox-add-tag"
            type="text"
            placeholder="Nom du tag"
            @input="onInput"
          />
        </div>
        <div class="toolbox-add-field">
          <input
            :value="formatedLabel"
            ref="input-formated"
            id="toolbox-add-tag-formatted"
            type="text"
            placeholder="Tag formaté"
            @input="onInputFormated"
          />
          <button
            class="toolbox-add-field-copy"
            type="button"
            @click="onCopyTag"
          >
            <Sprite
              :width="16"
              :height="16"
              :type="'copy'"
            />
          </button>
        </div>
        <div class="toolbox-add-field">
          <label>Couleur du tag</label>
          <ul class="toolbox-add-colors">
            <li
              v-for="{ code } in colors"
              :key="code"
              class="toolbox-add-color"
            >
              <input
                type="radio"
                :id="`color-${code}`"
                :value="code"
                :checked="code === color.code"
                @change="onColor(code)"
              >
              <label :for="`color-${code}`" :style="{ 'background-color': `#${code}` }" />
            </li>
          </ul>
        </div>

        <div class="toolbox-add-buttons">
          <btn
            :theme="'invisible'"
            @click="onCancel"
          >
            Annuler
          </btn>

          <btn
            :theme="'ghost'"
            @click="onAdd"
          >
            Ajouter
          </btn>
        </div>
      </form>
      <div v-else class="toolbox-tags">
        <h3 class="toolbox-title">Sélectionnez un tag</h3>
        <input ref="shadowInput" class="toolbox-tags-input" />
        <ul class="toolbox-tags-list">
          <li
            v-for="(label, index) in labels"
            :key="`toolbox-tag-${index}`"
            class="toolbox-tags-item"
          >
            <button
              class="toolbox-tags-button"
              @click="onLabel(label)"
            >
              <span :style="{ backgroundColor: `#${label.color.code}`,}" />
              {{ label.name }}
            </button>
          </li>
          <li class="toolbox-tags-item">
            <button
              class="toolbox-tags-button toolbox-tags-button--switcher"
              @click="onToogleAdd"
            >
              Nouveau tag
            </button>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
// import toolbox from '@/data/static/toolbox.json';
import Sprite from '@/components/elements/Sprite';
import Btn from '@/components/elements/Btn';

export default {
  components: {
    Sprite,
    Btn
  },
  props: {
    top: {
      type: Number,
      default: 150
    },
    left: {
      type: [Number, String],
      default: 150
    },
    atBottom: {
      type: Boolean,
      default: false
    },
    labels: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      add: true
    }
  },
  computed: {
    label() {
      return this.$store.state.toolbox.label;
    },
    formatedLabel() {
      return this.$store.state.toolbox.formatedLabel;
    },
    color() {
      return this.$store.state.toolbox.color;
    },
    colors() {
      return this.$store.state.toolbox.colors;
    }
  },
  beforeMount() {
    if(this.labels.length) {
      this.add = false;
    }
  },
  mounted() {
    this.setFocus();
  },
  beforeUnmount() {
    this.$store.commit('toolbox/updateLabel', '');
  },
  watch: {
    top() {
      this.setFocus();
    },
    left() {
      this.setFocus();
    }
  },
  methods: {
    setFocus() {
      if(this.$refs.input) {
        this.$refs.input.focus();
        return;
      }

      this.$refs.shadowInput.focus();
      this.$refs.shadowInput.blur();
    },
    onAdd(e) {
      e.preventDefault();
      if (!this.label.length) return;

      this.$emit('addLabel');
    },
    onColor(color) {
      const currentColor = this.colors.filter(c => c.code === color)[0];
      this.$store.commit('toolbox/updateColor', currentColor);
    },
    onInput(e) {
      const { currentTarget } = e;
      const { value } = currentTarget;
      this.$store.commit('toolbox/updateLabel', value);
      this.$store.commit('toolbox/updateFormated', value);
    },
    onInputFormated(e) {
      const { currentTarget } = e;
      const { value } = currentTarget;
      this.$store.commit('toolbox/updateFormated', value);
    },
    onCopyTag() {
      const $input = document.createElement('textarea');
      $input.value = this.formatedLabel;
      document.body.appendChild($input);
      $input.select();
      document.execCommand('copy');
      document.body.removeChild($input);
      this.$store.dispatch('notifications/addNotifs', [{
        type: 'success',
        text: 'Tag copié !'
      }]);
    },
    onLabel(label) {
      this.$emit('onLabel', label);
    },
    onCancel() {
      if(this.labels && this.labels.length) {
        this.add = false;
        return;
      }

      this.$emit('onClose');
    },
    onToogleAdd() {
      this.add = true;
    },
    onClose() {
      this.$emit('onClose');
    }
  }
}
</script>

<style lang="scss" scoped>
  .toolbox {
    position: absolute;
    border-radius: 4px;
    transform: translateX(-50%) translateY(calc(-100% - 12px));
    width: 320px;
    border-radius: 8px;
    box-shadow: 0px 2px 16px rgba(15, 15, 15, .16);
    z-index: 3;

    &.at-bottom {
      box-shadow: 0 0px 8px 4px rgba(black, .2);

       &::before {
         bottom: auto;
         top: -6px;
       }
    }

    &::before {
      content: '';
      position: absolute;
      left: calc(50% - 4px);
      bottom: -6px;
      z-index: 0;
      width: 12px;
      height: 12px;
      transform: rotate(45deg);
      background-color: var(--white);
      box-shadow: 0px 2px 16px rgba(15, 15, 15, .16);
    }

    &-container {
      @include padding(16px);
      position: relative;
      z-index: 1;
      border-radius: 8px;
      background-color: var(--white);
    }

    &-close {
      @include position(absolute, 16px 16px null null);
      @include size(24px);
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;

      &:hover {
        .svg {
          fill: var(--primary);
        }
      }

      .svg {
        fill: var(--secondary);
      }
    }

    &-title {
      color: var(--primary);
      font-size: rem(14px);
      font-weight: 700;
    }

    &-add {
      display: flex;
      flex-direction: column;

      &-field {
        position: relative;
        width: 100%;
        margin-top: 16px;

        > label {
          margin-bottom: 4px;
        }

        input[type="text"] {
          @include padding(9px 16px);
          display: block;
          width: 100%;
          border: 1px solid var(--grey-border);
          border-radius: 8px;
          outline: none;
          transition: border-color .3s $ease-out-quart;

          &:focus {
            border-color: var(--primary);
          }
        }

        &-copy {
          @include position(absolute, 0 0 null null);
          @include size(35px);
          display: flex;
          align-items: center;
          justify-content: center;

          &:hover {
            .svg {
              fill: var(--primary);
            }
          }
        }
      }

      &-colors {
        display: flex;
      }

      &-color {
        &:not(:first-child) {
          margin-left: 8px;
        }

        input {
          display: none;

          &:checked {
            + label {
              &::before {
                transform: scale(1);
              }
            }
          }
        }

        label {
          @include size(16px);
          display: block;
          position: relative;
          border-radius: 50%;

          &::before {
            @include size(8px);
            @include position(absolute, 4px null null 4px);
            content: '';
            border-radius: 50%;
            transform: scale(0);
            transition: transform .1s $ease-out-quart;
            background-color: var(--white);
          }
        }
      }

      &-buttons {
        display: flex;
        justify-content: space-between;
        margin-top: 32px;

        .button {
          width: calc(50% - 4px);
        }
      }
    }

    &-tags {
      &-button {
        @include padding(9px null);
        display: flex;
        align-items: center;
        font-size: rem(14px);
        line-height: 1;
        cursor: pointer;

        span {
          @include size(14px);
          border-radius: 4px;
          margin-right: 8px;
        }

        &--switcher {
          padding-left: 22px;
          color: var(--grey);

          &:hover {
            color: var(--primary);
          }
        }
      }

      &-input {
        position: absolute;
        top: 0;
        left: 0;
        opacity: 0;
        width: 0;
        height: 0;
      }
    }
  }
</style>
