<template>
  <div
    ref="container"
    class="juridical-documents"
  >
    <template v-if="!loading">
      <template v-if="lists && lists.length">
        <List
          v-for="list in lists"
          :key="list.title"
          :title="list.title"
          :items="list.items"
          @onClick="onClickDoc"
        />
      </template>
      <p
        v-if="!lists.length"
        class="noresult"
      >
        {{ $t('documents.noresult.default') }}
      </p>
    </template>
    <Loader v-else/>
  </div>
</template>

<script>
import axios from 'axios';
import Loader from '@/components/elements/Loader';
import List from '@/components/modules/List';

export default {
  name: 'LitigeantDocuments',
  components: {
    Loader,
    List
  },
  beforeMount() {
    const { params } = this.$route;
    this.$store.dispatch('legalCase/get', params.token);
  },
  computed: {
    documents() {
      return this.$store.state.documents.items;
    },
    lists() {
      return this.$store.state.legalCase.documentsLists;
    },
    selected() {
      return this.$store.state.preview.document;
    },
    loading() {
      return this.$store.state.documents.requests !== 0;
    }
  },
  methods: {
    async onClickDoc(doc) {
      const request = await axios.get(doc.url, { responseType: 'arraybuffer' });

      if (request.status === 200) {
        const blob = new Blob([request.data], { type: doc.mime });
        const blobURL = URL.createObjectURL(blob);

        const a = document.createElement('a');
        a.href = blobURL;
        a.download = doc.downloadName ? doc.downloadName : doc.name;
        this.$refs.container.appendChild(a);
        a.click();
        this.$refs.container.removeChild(a);
      }
    }
  }
}
</script>