<template>
  <div ref="container" :class="`profile${desktopOnly ? ' desktop-only' : ''}`">
    <div class="profile-wrapper">
      <button
        class="profile-toggle"
        aria-haspopup="menu"
        @click="onClick"
      >
        <span class="u-visually-hidden">Ouvrir la dropdown profile</span>
      </button>
      <img v-if="user?.profile_picture_url" :src="user.profile_picture_url"  class="profile-round"/>
      <span v-else class="profile-round">{{ user?.name ? user.name.charAt(0) : '' }}</span>
      <div class="profile-infos">
        <span v-if="user" class="profile-name">{{ user.name }}</span>
        <span v-if="user" class="profile-role">{{ user.client?.name ?? $t(`profile.roles.${user?.role}`) }}</span>
      </div>
      <Sprite :type="open ? 'chevron-up' : 'chevron-down'" :width="16" :height="16"/>
    </div>
    <div
      class="profile-dropdown"
      role="menu"
      :aria-hidden="open ? 'false' : 'true'"
    >
      <ul class="profile-dropdown-list">
        <li
          v-for="link in links"
          :key="`menu-${link.name}`"
          class="profile-dropdown-item"
        >
          <router-link
            :to="{ name: link.name }"
            class="profile-dropdown-link"
          >
            <Sprite v-if="link.meta?.userMenu?.prependIcon" :type="link.meta.userMenu.prependIcon" class="prepend-icon"/>
            {{ $t(`pages.${link.name.toLowerCase()}.title`) }}
            <Sprite v-if="link.meta?.userMenu?.appendIcon" :type="link.meta.userMenu.appendIcon" class="append-icon"/>
          </router-link>
        </li>
        <li class="profile-dropdown-item">
          <button
            class="profile-dropdown-link profile-dropdown-link--button"
            @click="onLogout"
          >
            Déconnexion
          </button>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import Sprite from '@/components/elements/Sprite';
import { isDescendant } from '@/helpers';

export default {
  components: {
    Sprite,
  },
  data() {
    return {
      open: false,
      // eslint-disable-next-line
      _documentClick: this.onDocumentClick.bind(this),
    }
  },
  props: {
    user: {
      type: Object,
      required: true
    },
    desktopOnly: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    links() {
      const { role } = this.$store.state.user.data;
      const routes = this.$router.getRoutes();

      const userRoutes = routes.filter(r => {
        if (role === 'admin') return false;
        return r.meta.userMenu;
      });

      return userRoutes;
    },
  },
  watch:{
    $route (to, from) {
      this.open = false;
    },
    open(val) {
      if (!val) {
        document.removeEventListener('click', this._documentClick);
        return;
      }
      document.addEventListener('click', this._documentClick);
    }
  },
  methods: {
    onClick() {
      this.open = !this.open;
    },
    onLogout() {
      this.$store.dispatch('user/logout');
    },
    onDocumentClick(e) {
      const { target } = e;
      const descendant = isDescendant(this.$refs.container, target);
      if (!descendant && this.open) {
        this.open = false;
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.profile {
  position: relative;
  z-index: 2;
  width: 240px;

  @include mq($until: 'l') {
    margin-left: auto;
  }

  @include mq($until: 'm') {
    @include position(fixed, null 0 0 null);
    width: auto;
    z-index: 102;

    &.desktop-only {
      display: none;
    }

    .layout--staging & {
      bottom: 30px;
    }
  }

  &-wrapper {
    @include padding(6px 8px);
    display: flex;
    align-items: center;
    position: relative;
    width: 100%;
    height: 100%;

    @include mq($from: 'l') {
      @include padding(12px 16px);
      height: auto;
    }

    .svg {
      margin-left: auto;
      fill: var(--grey-dark);

      @include mq($until: 'm') {
        display: none;
      }
    }
  }

  &-toggle {
    @include position(absolute, 0 null null 0);
    @include size(100%);
    z-index: 1;
    cursor: pointer;

    &:hover,
    &:focus {
      ~ .profile-infos {
        .profile-name,
        .profile-role {
          color: var(--primary);
        }
      }
    }
  }

  &-round {
    @include size(24px);
    border-radius: 50%;
    background-color: var(--grey-lightest);
    text-align: center;
    line-height: 24px;
    object-fit: cover;

    @include mq($until: 'l') {
      @include size(32px);
      line-height: 32px;
    }
  }

  &-infos {
    @include padding(null 8px);
    display: flex;
    flex-direction: column;

    @include mq($until: 'm') {
      display: none;
    }
  }

  &-name {
    color: var(--black-lighter);
    font-size: rem(12px);
    line-height: (16 / 12);
    transition: color .3s $ease-out-quart;
  }

  &-role {
    color: var(--grey-dark);
    font-size: rem(10px);
    line-height: (16 / 10);
    transition: color .3s $ease-out-quart;
  }

  &-dropdown {
    @include position(absolute, 100% 0 null null);
    @include size(100%, auto);
    display: flex;
    flex-direction: column;
    background-color: var(--white);
    box-shadow: 0px 2px 8px rgba(15, 15, 15, 0.16);
    transform: translateY(-10px);
    opacity: 0;
    transition: transform .2s $ease-out-quart, opacity .2s $ease-out-quart;
    pointer-events: none;

    @include mq($until: 'm') {
      left: auto;
      right: 8px;
      top: auto;
      bottom: calc(100% + 8px);
      min-width: 240px;
      border: 1px solid var(--grey-border);
    }

    &[aria-hidden="false"] {
      transform: translateY(0);
      opacity: 1;
      pointer-events: all;
    }

    &-item {
      &:not(:first-child) {
        .profile-dropdown-link{
          border-top: 1px solid rgba(255, 255, 255, 0);

          &:not(.router-link-active) {
            border-top-color: var(--grey-lightest);
          }
        }
      }
    }

    &-link {
      @include padding(12px 16px);
      display: flex;
      align-items: center;
      position: relative;
      width: 100%;
      color: var(--black-light);
      font-size: rem(12px);
      line-height: (16 / 12);
      text-align: left;
      text-decoration: none;
      transition: color .3s $ease-out-quart;

      .svg {
        fill: currentColor;

        &.prepend-icon {
          margin-right: 5px;
        }

        &.append-icon {
          margin-left: auto;
        }
      }

      &::before {
        @include position(absolute, -1px null null 0);
        @include size(2px, calc(100% + 2px));
        content: '';
        transform: scaleX(0);
        transform-origin: 0 50%;
        background-color: var(--black-light);
      }

      &:hover {
        font-weight: bold;
        // color: var(--primary);
      }

      &.router-link-active {
        // color: var(--primary);
        font-weight: bold;
        background-color: var(--white-dark);
        z-index: 2;
        margin-bottom: -1px;

        &::before {
          transform: scaleX(1);
        }
      }

      &--button {
        justify-content: center;
        opacity: .6;
      }
    } 
  }
}
</style>

<style lang="scss">
.profile {
  @include mq($until: 'm') {
    .layout-detail & {
      display: none;
    }
  }
}
</style>