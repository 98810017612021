<template>
  <div class="welcome">
    <img class="welcome-logo" src="/img/logo.svg"/>
    <div class="welcome-wrapper">
      <h1 class="welcome-title">{{ $t('pages.welcome.title') }}</h1>
      <p v-html="$t('pages.welcome.text')" class="welcome-text"/>

      <form class="welcome-form" novalidate @submit.prevent="onSubmit">
        <Field
          :value="company"
          :error="error"
          :label="$t('labels.your-company')"
          id="company"
          type="text"
          @input.self="onInput"
        />

        <p v-html="$t('pages.welcome.subline')" class="welcome-subline"/>

        <Btn type="submit">
          {{ $t('pages.welcome.submit') }}
        </Btn>
      </form>

      <div v-if="loading" class="welcome-loader">
        <Loader />
      </div>
    </div>
  </div>
</template>

<script>
import Btn from '@/components/elements/Btn';
import Loader from '@/components/elements/Loader';
import Field from '@/components/modules/authentication/Field';

export default {
  components: {
    Btn,
    Loader,
    Field
  },
  data() {
    return {
      company: '',
      error: null,
      loading: false
    }
  },
  methods: {
    validate() {
      this.error = !this.company ? this.$t('errors.required') : null;
    },
    onInput(e) {
      this.company = e;
    },
    async onSubmit() {
      this.validate();
      if(this.error) return;
      this.loading = true;
      const req = await this.$store.dispatch('user/setClientName', this.company);

      if (req.status === 'success') {
        // if (req.data?.data?.status === 'error') {
        if (req.data?.status === 'error') {
          this.error = req.data.data.message;
        } else {
          this.$store.dispatch('user/getUser');
          this.$router.push({ name: 'LegalCases' });
          this.$store.dispatch(
            'notifications/addNotifs',
            [{ type: 'success', text: 'Le nom de votre société a bien été enregistré' }]
          );
        }
      }

      this.loading = false;
    }
  }
}
</script>

<style lang="scss" scoped>
.welcome {
  @include size(100%);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &-logo {
    @include size(40px);
    margin-bottom: 32px;
  }

  &-wrapper {
    @include padding(40px 24px);
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    width: calc(100% - 32px);
    max-width: $gs-container;
    border-radius: 8px;
    background-color: var(--white);
    text-align: center;
  }

  &-title {
    font-size: 24px;
    font-weight: 700;
    line-height: (32 / 24);

    + .welcome-text {
      margin-top: 8px;
    }
  }

  &-text {
    color: var(--grey-darker);
  }

  &-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 32px;
    max-width: 380px;

    .field {
      width: 100%;

      :deep(.field-input) {
        text-align: center;
      }
    }
  }

  &-subline {
    margin-top: 8px;
    font-size: rem(12px);
    color: var(--grey-darker);
  }

  .button {
    margin-top: 32px;
  }

  &-loader {
    @include position(absolute, 0 null null 0);
    @include size(100%);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 2;
    border-radius: 8px;
    background-color: rgba($white, .7);
  }
}
</style>