<template>
  <div class="page process">
    <div class="container">
      <Header
        :title="$t('pages.process.title')"
        :actions="actions"
      />

      <template v-if="!loading">
        <!-- Search -->
        <template v-if="search.requestTerms && search.requestTerms.length > 2">
          <div class="process-list">
            <Loader v-if="search.requests !== 0"/>
            <template v-else>
              <List
                :title="$tc('lists.documents.search', search.results.length, { nbr: search.results.length, terms: search.requestTerms })"
                :items="search.results"
                :displayHTML="true"
                @onClick="onClickDoc"
              />
            </template>
          </div>
        </template>

        <template v-else>
          <!-- Unprocessed documents -->
          <div
            v-if="unprocesseds && unprocesseds.length"
            class="process-list"
          >
            <List
              :items="unprocesseds"
              :canDelete="true"
              @onDelete="onDelete"
              @onClick="onClickDoc"
            />
          </div>

          <Pagination
            v-if="unprocesseds.length"
            :total="total"
            :current="parseInt($route?.query?.page, 10) || 1"
            :perPage="globals.pagination.perPage"
          />

          <p v-if="!unprocesseds.length">Aucun document trouvé</p>
        </template>
      </template>

      <Loader v-else/>
    </div>
  </div>
</template>

<script>
import Loader from '@/components/elements/Loader';
import List from '@/components/modules/List';
import Header from '@/components/modules/Header';
import Pagination from '@/components/modules/Pagination';
import globals from '@/data/static/globals.json';

export default {
  name: 'Process',
  components: {
    Header,
    List,
    Loader,
    Pagination
  },
  beforeMount() {
    this.$store.commit('polysearch/updateName', 'juridical-documents');
    this.$store.commit('documents/updateScopes', ['admin']);
    this.getData();
    this.$store.dispatch('polysearch/getFilters');
  },
  data() {
    return {
      globals,
      updating: false,
      actions: [
        {
          label: this.$t('home.upload.title'),
          action: () => this.$store.commit('upload/toggle')
        }
      ],
    }
  },
  computed: {
    unprocesseds() {
      return this.$store.state.documents.items.filter(d => d.state === 'pending' || d.state === 'success');
    },
    search() {
      return this.$store.state.search;
    },
    loading() {
      return this.$store.state.documents.requests !== 0
    },
    total() {
      return this.$store.state.documents.total;
    }
  },
  watch: {
    $route(current, prev) {
      if (current && current.name === 'Process') {
        this.getData();
      }
    },
  },
  methods: {
    async getData() {
      const { page } = this.$route.query;
      if (page) {
        await this.$store.dispatch('documents/getDocuments', {
          offset: (page - 1) * globals.pagination.perPage,
          status: 'pending|success'
        });
      } else {
        await this.$store.dispatch('documents/getDocuments', { status: 'pending|success'});
      }

      if (page && this.total !== 0 && parseInt(page, 10) > Math.ceil(this.total / globals.pagination.perPage)) {
        this.$router.push({
          name: this.$route.name,
          query: { page: Math.floor(this.total / globals.pagination.perPage) }
        })
      }
    },
    async onDelete(token) {
      const req = await this.$store.dispatch('documents/delete', token);
      if (req.status === 'success') {
        this.getData();
      }
    },
    onClickDoc(doc) {
      this.$store.dispatch('preview/set', doc);
    }
  }
};
</script>

<style lang="scss" scoped>
.process {
  &-header {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 24px;

    .h1 {
      margin-bottom: 8px;
    }

    .search {
      width: calc(100% - 250px);
      max-width: 624px;
    }
  }

  &-list {
    display: flex;
    flex-direction: column;
    align-items: center;

    .headline {
      width: 100%;
    }

    .loader {
      margin-top: 24px;
    }
  }
}
</style>

<style lang="scss">
.home {
  .popup {
    .button:not(.pill) {
      margin-top: 32px;
      margin-left: auto;
    }
  }
}
</style>
