import { createStore } from 'vuex';

// Modules
import user from './user';
import file from './file';
import files from './files';
import lawyer from './lawyer';
import client from './client';
import upload from './upload';
import search from './search';
import office from './office';
import preview from './preview';
import toolbox from './toolbox';
import category from './category';
import responsive from './responsive';
import documents from './documents';
import legalCase from './legalCase';
import legalCases from './legalCases';
import polysearch from './polysearch';
import subscription from './subscription';
import notifications from './notifications';

export default createStore({
  state: {},
  mutations: {},
  actions: {
    // reset({ dispatch }) {
    //   dispatch('user/reset');
    //   dispatch('client/reset');
    //   dispatch('office/reset');
    //   dispatch('documents/reset');
    //   dispatch('legalCase/reset');
    //   dispatch('legalCases/reset');
    // }
  },
  modules: {
    user,
    file,
    files,
    lawyer,
    office,
    search,
    client,
    upload,
    preview,
    toolbox,
    category,
    documents,
    legalCase,
    responsive,
    legalCases,
    polysearch,
    subscription,
    notifications
  },
});
