<template>
  <div class="datainput">
    <input
      :id="id"
      :type="type"
      :placeholder="placeholder"
      :value="value"
      :disabled="disabled"
      @input="onInput"
    />
  </div>
</template>

<script>
export default {
  props: {
    id: {
      type: String
    },
    label: {
      type: String
    },
    value: {
      type: String
    },
    type: {
      type: String,
      default: 'text'
    },
    placeholder: {
      type: String
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    onInput(e) {
      this.$emit('onInput', e.target.value);
    }
  },
}
</script>

<style lang="scss" scoped>
.datainput {
  input {
    @include padding(7px);
    width: 100%;
    display: block;
    font-size: rem(14px);
    font-weight: 700;
    line-height: (16 / 14);
    border: 1px solid var(--white);
    border-radius: 8px;
    outline: none;
    transition: border-color .3s $ease-out-quart;
    background: none;
    appearance: none;
    color: var(--black-light);

    @include mq($until: 'l') {
      &:not(:focus) {
        border-color: var(--grey-border);
      }
    }

    &:not([disabled]) {
      cursor: text;

      &:hover {
        border-color: var(--grey-border);
      }

      &:focus {
        border-color: var(--primary);
      }
    }
  }
}
</style>