<template>
  <div
    v-if="display"
    class="sidebar sidebar-right"
    id="sidebar-right"
  >
    <Lawyer v-if="display === 'lawyer'"/>
    <Lawyers v-else-if="display === 'lawyers'"/>
    <Preview v-else-if="display === 'preview'" />
  </div>
</template>

<script>
import Lawyer from '@/components/modules/sidebar/Lawyer';
import Lawyers from '@/components/modules/sidebar/Lawyers';
import Preview from '@/components/modules/sidebar/Preview';

import { isDescendant } from '@/helpers';

export default {
  components: {
    Lawyer,
    Lawyers,
    Preview
  },
  data() {
    return {
      // eslint-disable-next-line
      _documentClick: this.onDocumentClick.bind(this)
    }
  },
  watch: {
    display(val, oldVal) {
      if (val && !oldVal) {
        document.addEventListener('click', this._documentClick);
        return;
      }

      document.removeEventListener('click', this._documentClick);
    }
  },
  computed: {
    display() {
      const { sidebarRight } = this.$route.meta;
      switch (sidebarRight) {
        case 'preview':
          return this.$store.state.preview.document ? sidebarRight : null;
        case 'lawyer':
          return this.$store.state.lawyer.id ? sidebarRight : null;
        case 'lawyers':
          const { data } = this.$store.state.client;
          const lawyers = data.lawyer_list.split(',');
          return lawyers.length > 1 && this.$store.state.category.requests === 0 ? sidebarRight : null;
        default: 
          return null;
      }
    }
  },
  methods: {
    onDocumentClick(e) {
      const { target } = e;
      const descendant = isDescendant(this.$el, target);
      if (!descendant) {
        const { sidebarRight } = this.$route.meta;
        switch (sidebarRight) {
          case 'preview':
            this.$store.commit('preview/setDocument', null);
            break;
          case 'lawyer':
            this.$store.commit('lawyer/update', { prop: 'id', value: null });
            this.$store.commit('lawyer/update', { prop: 'data', value: null });
            this.$store.commit('lawyer/update', { prop: 'originalData', value: null });
            break;
        }
      }
    }
  }
};
</script>