// OK
import { calls, endpoints } from '@/services/calls';
import { dispatchNotif } from '@/services/notifications';
import globals from '@/data/static/globals.json';


const documents = {
  namespaced: true,
  state: {
    requests: 0,
    filters: {
      // scopes : public / cabinet / case / models
      scopes: [],
      case: null
    },
    isSearch: false,
    items: [],
    suggesteds: [],
    faileds: [],
    search: [],
    total: 0
  },
  mutations: {
    updateScopes(state, scopes) {
      state.filters.scopes = scopes;
    },
    updateItems(state, { items, type }) {
      state[type] = items;
    },
    updateIsSearch(state, flag) {
      state.isSearch = flag;
    },
    updateTotal(state, total) {
      state.total = total;
    },
    addItem(state, file) {
      state.items.push(file);
    },
    resetItems(state) {
      state.items = [];
    },
    incRequests(state) {
      state.requests++;
    },
    decRequests(state) {
      state.requests--;
    }
  },
  actions: {
    reset({ commit }) {
      commit('updateItems', { type: 'items', items: [] });
      commit('updateItems', { type: 'suggesteds', items: [] });
      commit('updateItems', { type: 'faileds', items: [] });
      commit('updateItems', { type: 'search', items: [] });
    },
    async getDocuments({ state, commit, dispatch }, { type = 'items', limit = globals.pagination.perPage, offset = 0, status = null }) {
      commit('incRequests');
      commit('updateIsSearch', false);

      const { scopes } = state.filters;
      const params = { limit, offset };

      if (status) params.status = status;

      if (scopes.length) {
        scopes.map(s => {
          if (typeof s === 'object') {
            for (const key in s) {
              if (s.hasOwnProperty(key)) {
                params[key] = s[key];
              }
            }
          } else {
            params[s] = true;
          }
        });
      }

      if (type === 'suggesteds') params.proposed = true;

      const request = await calls.get('getDocs', { params });
      let items = [];

      if (request.status === 'success') {
        commit('updateTotal', request.meta.total);
        const { data } = request;

        if (data?.length) {
          const tokens = data.map(d => d.token);
          const states = await calls.post('getDocsState', { tokens });
          
          for (let i = 0; i < data.length; i++) {
            const doc = data[i];
            doc.state = states.data.content[doc.token];
          }
        }
        
        items = data;
      }

      commit('updateItems', { items, type });
      commit('decRequests');
      return request;
    },
    async getLitigeantDocuments({ state, commit, dispatch }, token) {
      commit('incRequests');
      const url = endpoints.getLegalCase.replace('{token}', token);
      const request = await calls.get(url);

      if (request.status === 'success') {
        const { data } = request;
        const { questions_and_answers } = data;
        const documents = [];

        questions_and_answers.map(field => {
          if (field.question_type === 'file' || field.question_type === 'files') {
            const files = field.answer.split('||');
            files.map((file, index) => {
              const split = file.split('::');
              const url = split[0];
              const mime = split[1];
              const urlSplit = url.split('/');
              const token = urlSplit[urlSplit.length - 1];

              documents.push({
                name: `${field.question.title['fr']} - ${index + 1}`,
                token,
                mime
              });
            });
          }
        })

        commit('updateItems', { items: documents, type: 'items' });
      }

      commit('decRequests');
    },
    async updateFiles({ state, commit }) {
      const inProcessing = state.items.filter(d => !d.processed);
      if (!inProcessing.length) return;

      const tokens = inProcessing.map(d => d.token);
      const states = await calls.post('getDocsState', { tokens });

      for (let i = 0; i < inProcessing.length; i++) {
        const doc = inProcessing[i];
        doc.state = states.data.content[doc.token];
      }

      return true;
    },
    async search({ state, commit, dispatch }, params) {
      commit('incRequests');
      commit('updateIsSearch', true);
      const { scopes } = state.filters;
      const reqParams = { ...params };

      if (scopes.length) {
        scopes.map(s => {
          if (typeof s === 'object') {
            for (const key in s) {
              if (s.hasOwnProperty(key)) reqParams[key] = s[key];
            }
          } else {
            reqParams[s] = true;
          }
        });
      }

      const request = await calls.post('searchDocs', reqParams);
      let items = [];

      if (request.status === 'success') {
        const { data } = request;
        const tokens = data.map(d => d.token);

        if (tokens?.length) {
          const states = await calls.post('getDocsState', { tokens });
          
          for (let i = 0; i < data.length; i++) {
            const doc = data[i];
            doc.state = states.data.content[doc.token];
          }
        }
        
        items = data;
      }

      commit('updateItems', { items, type: 'search' });
      commit('decRequests');
    },
    async delete({ state, dispatch }, token) {
      const req = await calls.delete('deleteDoc', { data: { token }});

      if (req.status === 'success') {
        dispatchNotif({ type: 'success', text: 'Document supprimé' });
        return req;
      }

      return false;
    }
  }
}

export default documents;


// {
//   "token":"3953fe89-4933-4775-bc68-447cafee4b2c",
//   "name":"Mod\u00e8le PRJ",
//   "metadata":{
//     "name":"Mod\u00e8le PRJ",
//     "type":"document",
//     "source":"lawyer",
//     "country":"be",
//     "language":"fr",
//     "lawcases":[
//       {
//         "id":494,
//         "content":"Je suis au bord de la faillite"
//       },
//       {
//         "id":493,
//         "content":"Je suis en faillite"
//       }
//     ],
//     "anonymous":false,
//     "reference":"Mod\u00e8le PRJ, 11 F\u00e9vrier 2022",
//     "visibility":"private",
//     "lawyerSource":{
//       "id":1442,
//       "name":"Emma Dassy"
//     },
//     "redactionDate":"2022-02-11",
//     "authorLastname":"Dassy",
//     "authorFirstname":"Emma"
//   }
// }
