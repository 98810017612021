<template>
  <div class="page search">
    <div class="search-header">
      <form
        class="search-header-form"
        @submit="onSubmit"
      >
        <input
          type="text"
          @input="onInput"
        >
        <button type="submit">
          <sprite
            :type="'search'"
            :width="20"
            :height="20"
          />
        </button>
        <div class="search-header-form-filters">
          <Select
            :options="types"
            :value="filters.type"
            :allowNull="true"
            :placeholder="'Nature du document'"
            @onChange="onFilter($event, 'type')"
          />
          <Select
            :placeholder="'Confidentialité'"
            :options="visibilities"
            :value="filters.visibility"
            @onChange="onFilter($event, 'visibility')"
          />
        </div>
      </form>
    </div>
    <div
      v-if="results.length"
      class="search-results"
    >
      <div
        v-for="result in results"
        :key="result.token"
        class="search-result"
      >
        <h2>{{ result.name }}</h2>
        <p>{{ result.text }}</p>
      </div>
    </div>
    <p v-else>{{ $t('no_result') }}</p>
  </div>
</template>

<script>
import Select from '@/components/elements/Select';
import Sprite from '@/components/elements/Sprite';

import metadatas from '@/data/static/metadatas';

export default {
  components: {
    Select,
    Sprite
  },
  data() {
    return {
      types: [...metadatas.types],
      visibilities: [...metadatas.visibilities],
    }
  },
  computed: {
    terms() {
      return this.$store.state.search.terms;
    },
    results() {
      return this.$store.state.search.results;
    },
    filters() {
      return this.$store.state.search.filters;
    }
  },
  methods: {
    onInput(e) {
      const { currentTarget } = e;
      const { value } = currentTarget;

      this.$store.commit('search/updateTerms', value);
    },
    onFilter(value, property) {
      this.$store.commit('search/updateFilter', { property, value });

      // if (condition) {
        
      // }
    },
    onSubmit(e) {
      e.preventDefault();
      this.$store.dispatch('search/getResults');
    }
  }
}
</script>

<style lang="scss" scoped>
.search {
  display: flex;
  flex-direction: column;

  &-header {
    @include padding(32px 40px);
    display: flex;
    justify-content: center;
    width: 100%;

    &-form {
      position: relative;

      input {
        @include padding(8px 0);
        display: block;
        width: 100%;
        min-width: 360px;
        border: 0;
        border-bottom: 1px solid $grey-lightest;
        transition: border-bottom-color .3s $ease-out-quart;
        appearance: none;
        outline: none;
        font-size: rem(18px);

        &:focus {
          border-bottom-color: $primary;
        }
      }

      button {
        @include position(absolute, 0 0 null null);
        @include size(40px, 100%);
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;

        &:hover {
          .svg {
            fill: $primary;
          }
        }
      }

      &-filters {
        display: flex;
        width: 100%;
      }
    }
  }

  &-results {
    @include margin(null auto);
    width: 720px;
  }

  &-result {
    @include padding(12px null);

    h2 {
      font-size: rem(18px);
      font-weight: 600;
    }

    &:not(:first-child) {
      border-top: 1px solid $grey-lightest;
    }
  }
}
</style>
