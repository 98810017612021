import { calls, endpoints } from '@/services/calls';
import { dispatchNotif } from '@/services/notifications';

const file = {
  namespaced: true,
  state: {
    requests: 0,
    name: null,
    token: null,
    content: null,
    metadatas: null,
    fileMetadatas: null,
    categorization: [],
    tagsOpenAI: [],
    processing: false,
    prompt: null,
    onpage: false,
    summary: false,
    search: {
      terms: '',
      currentTerms: '',
      current: null,
      results: []
    },
    saveRequests: 0,
    regenerateRequests: 0
  },
  mutations: {
    update(state, { prop, value }) {
      state[prop] = value;
    },
    // Label
    addLabel(state, label) {
      state.categorization.push(label);
    },
    toggleLabel(state, label) {
      state.categorization.map((l) => {
        if (l === label) {
          l.open = !l.open;
        }
        return l;
      })
    },
    deleteLabel(state, label) {
      state.categorization = state.categorization.filter(l => l.name !== label.name);
    },
    editLabel(state, { label, value, property = 'name' }) {
      state.categorization.map((l) => {
        if (l.name === label.name) l[property] = value;
        return l;
      });
    },
    resetLabelName(state, label) {
      state.categorization.map((l) => {
        if (l.name === label.name) l.name = l.savedName;
        return l;
      });
    },
    resetLabelCode(state, label) {
      state.categorization.map((l) => {
        if (l.name === label.name) l.code = l.savedCode;
        return l;
      });
    },
    highlightLabel(state, { label, value }) {
      state.categorization.map((l) => {
        if (l === label) l.highlighted = value;
        return l;
      })
    },
    // Selection
    addSelection(state, { selection, label }) {
      state.categorization.map((l) => {
        if (l.name === label.name) {
          label.data.push(selection);
        }
      });
    },
    removeSelection(state, { selection, label }) {
      const indexLabel = state.categorization.indexOf(label);
      const indexSelection = state.categorization[indexLabel].data.indexOf(selection);
      state.categorization[indexLabel].data.splice(indexSelection, 1);
    },
    deleteSelection(state, { label, selection }) {
      state.categorization.map(l => {
        if (l.name === label.name) l.data = l.data.filter(s => {
          if (s.start_index === selection.start_index && s.end_index === selection.end_index) return false;
          return true;
        });
        return l;
      });
    },
    highlightSelection(state, { label, selection, value }) {
      state.categorization.map((l) => {
        if (l.name === label.name) {
          l.data.map(s => {
            if (s.start_index === selection.start_index && s.end_index === selection.end_index) {
              s.highlighted = value;
            }

            return s;
          });
        }

        return l;
      });
    },
    // Metadatas
    updateName(state, name) {
      state.name = name;
    },
    updateToken(state, token) {
      state.token = token;
    },
    updateContent(state, content) {
      state.content = content;
    },
    updateMetadatas(state, metadatas) {
      state.metadatas = {...metadatas};
    },
    updateMetadata(state, { type, value }) {
      state.metadatas[type] = value;
    },
    updateMetadataArray(state, { type, item }) {
      const ids = state.metadatas[type].map(el => el.id);
      const index = ids.indexOf(item.id);

      if (index >= 0) {
        state.metadatas[type].splice(index, 1);
        return;
      }

      state.metadatas[type].push(item);
    },
    updateFileMetadatas(state, metadatas) {
      state.fileMetadatas = {...metadatas};
    },
    updateProcessing(state, processing) {
      state.processing = processing;
    },
    updateOnPage(state, onpage) {
      state.onpage = onpage;
    },
    updateCategorization(state, categorization) {
      state.categorization = categorization;
    },
    updateSearchTerms(state, terms) {
      state.search.terms = terms;
    },
    updateSearchResults(state, results) {
      state.search.results = results;
    },
    updateSearchCurrent(state, current) {
      state.search.current = current;
    },
    updateSearchCurrentTerms(state, terms) {
      if(!terms) {
        state.search.currentTerms = state.search.terms;
        return;
      }

      state.search.currentTerms = terms;
    },
    incrementRequests(state) {
      state.requests++;
    },
    decrementRequests(state) {
      state.requests--;
    },
    incrementSaveRequests(state) {
      state.saveRequests++;
    },
    decrementSaveRequests(state) {
      state.saveRequests--;
    },
    incrementRegenerateRequests(state) {
      state.regenerateRequests++;
    },
    decrementRegenerateRequests(state) {
      state.regenerateRequests--;
    }
  },
  actions: {
    async getFile({ state, commit, dispatch }, token) {
      if (!state.onpage) return;
      commit('incrementRequests');
      const t = token ? token : state.token;
      const check = await calls.post('getDocsState', { tokens: [t] });

      if (check.status !== 'success') return;

      if (!check.data.content[t]) {
        commit('updateProcessing', true);
        commit('decrementRequests');

        setTimeout(() => {
          dispatch('getFile', token);
        }, 2000);
        return;
      }

      const promptReq = await calls.get('getDocPrompt');
      commit('update', { prop: 'prompt', value: promptReq.data.prompt });
      const url = endpoints.getDoc.replace('{token}', t);
      const request = await calls.get(url);

      if(request.status === 'success') {
        commit('updateProcessing', false);
        const { data } = request;
        commit('updateName', data.name);
        commit('updateToken', data.token);
        commit('updateContent', data.content);
        commit('updateMetadatas', JSON.parse(JSON.stringify(data.metadata)));
        commit('updateFileMetadatas', JSON.parse(JSON.stringify(data.metadata)));
        commit('update', { prop: 'tagsOpenAI', value: data.unprocessed_openai_tag });
        commit('update', { prop: 'summary', value: data.summary ? data.summary : null });

        if (!data.categorization || !data.categorization.length) {
          commit('updateCategorization', []);
        } else {
          const categorization = data.categorization.map(l => {
            l.savedName = l.name;

            l.data.map(s => {
              s.highlighted = false;
              return s;
            });
            return l;
          });
          commit('updateCategorization', categorization);
          // commit('updateCategorization', data.categorization);
        }
      }

      commit('decrementRequests');
    },
    async searchInFile({ state, commit }) {
      const { search, content } = state;
      const { terms } = search;

      commit('updateSearchCurrentTerms');

      if (!terms.length || !content.length) {
        commit('updateSearchResults', []);
        commit('updateSearchCurrent', null);
        return;
      }

      const indices = [],
            searchableTerms = terms.toLowerCase(),
            searchableText = content.toLowerCase();
      let index,
          startIndex = 0;

      while ((index = searchableText.indexOf(searchableTerms, startIndex)) > -1) {
        indices.push({
          start_index: index,
          end_index: index + terms.length,
          content: terms
        });

        startIndex = index + searchableTerms.length;
      }

      commit('updateSearchResults', indices);
      commit('updateSearchCurrent', indices[0]);
    },
    nextSearchCurrent({ state, commit }) {
      const { search } = state;
      const { current, results } = search;
      let index = results.indexOf(current);

      if (index < 0) return;
      if (index === results.length - 1) index = 0;
      else index++;

      commit('updateSearchCurrent', results[index]);
    },
    async save({ state, commit, dispatch }) {
      commit('incrementSaveRequests');
      const categorization = state.categorization.map(l => {
        if (!l.code) {
          l.code = l.name.replace(/ /g, '_')
            .normalize('NFD')
            .replace(/[\u0300-\u036f]/g, '')
            .replace(/[^A-z0-9_]/g, '')
            .toLowerCase();
        }
        l.savedCode = l.code;
        l.savedName = l.name;
        l.open = false;
        return l;
      });

      const params = {
        token: state.token,
        categorization,
        // metadata: null
        processed: true
      };

      const request = await calls.post('docUpdateLabelling', params);

      if (request.status === 'success') {
        dispatchNotif({ text: 'Tags sauvegardés !', type: 'success' });
      }

      commit('decrementSaveRequests');
    },
    async saveMetadatas({ state, commit, dispatch }) {
      commit('incrementSaveRequests');
      const params = {
        token: state.token,
        metadata: state.metadatas
      }
      const request = await calls.post('docUpdateLabelling', params);

      if (request.status === 'success') {
        dispatchNotif({ text: 'Metadonnées sauvegardées !', type: 'success' });
      }

      commit('decrementSaveRequests');
      return request.status;
    },
    async regenerateDocument({ state, commit }) {
      commit('incrementRegenerateRequests');
      const params = { prompt: state.prompt, token: state.token }
      const response = await calls.post('regenerateDoc', params);
      commit('decrementRegenerateRequests');
    }
  }
}

export default file;
