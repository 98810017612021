import Subscription from '../views/Subscription.vue';

export const subscription = [
  {
    path: '/subscription',
    name: 'Subscription',
    component: Subscription,
    meta: {
      topbar: 'detail',
      back: { name: 'OfficeSubscription' }
    }
  },
]