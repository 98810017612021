import Tagging from '../views/Tagging.vue';
import Search from '../views/Search.vue';
import Home from '../views/admin/Home.vue';
import Process from '../views/admin/Process.vue';
import Faileds from '../views/admin/Faileds.vue';

export const  admin = [
  {
    path: '/admin',
    name: 'Admin',
    component: Home,
    meta: {
      menu: true,
      admin: true,
      sidebarLeft: 'search',
      sidebarRight: 'preview'
    }
  },
  {
    path: '/process',
    name: 'Process',
    component: Process,
    meta: {
      menu: true,
      admin: true,
      sidebarLeft: 'search',
      sidebarRight: 'preview'
    }
  },
  {
    path: '/faileds',
    name: 'Faileds',
    component: Faileds,
    meta: {
      menu: true,
      admin: true,
      // sidebarLeft: true
    }
  },
  {
    path: '/tagging',
    name: 'Tagging',
    component: Tagging,
    meta: {
      admin: true,
      topbar: 'tagging',
      back: { name: 'Admin' }
    }
  },
  {
    path: '/search',
    name: 'Search',
    component: Search,
    meta: {
      admin: true,
      sidebarRight: 'preview'
    }
  },
];