import { calls } from '@/services/calls';

const files = {
  namespaced: true,
  state: {
    toUpload: {
      file: null,
      metadatas: {
        name: null,
        type: null,
        visibility: 'public',
        country: null,
        anonymous: false,
        source: 'symplicy',
        lawcases: []
      }
    },
    items: [],
    importeds: [],
    processeds: []
  },
  mutations: {
    updateFile(state, file) {
      state.toUpload.file = file;
    },
    updateMetadata(state, { type, value }) {
      state.toUpload.metadatas[type] = value;
    },
    updateMetadataArray(state, { type, item }) {
      const ids = state.toUpload.metadatas[type].map(el => el.id);
      const index = ids.indexOf(item.id);

      if (index >= 0) {
        state.toUpload.metadatas[type].splice(index, 1);
        return;
      }

      state.toUpload.metadatas[type].push(item);
    },
    addItem(state, file) {
      state.items.push(file);
    },
    resetItems(state) {
      state.items = [];
    },
    resetToUpload(state) {
      state.toUpload = {
        file: null,
        metadatas: {
          name: null,
          type: null,
          visibility: 'public',
          country: null,
          anonymous: false,
          lawcases: []
        }
      }
    },
    removeItem(state, file) {
      const index = state.items.indexOf(file);
      if (index < 0) return;
      state.items.splice(index, 1);
    },
    addImported(state, file) {
      state.importeds.push(file);
    },
    updateProcesseds(state, files) {
      state.processeds = files;
    } ,
    updateFileProperty(state, { index, property, value }) {
      state.processeds[index][property] = value;
    }
  },
  actions: {
    async getFiles({ commit }) {
      const request = await calls.get('getDocs');

      if (request.status === 'success') {
        const { data } = request;
        const tokens = data.map(d => d.token);
        const states = await calls.post('getDocsState', { tokens });

        for (let i = 0; i < data.length; i++) {
          const doc = data[i];
          doc.state = states.data.content[doc.token];
        }

        commit('updateProcesseds', data);
      }
    },
    // async importFile({ state, commit }) {
    //   const { file, metadatas } = state.toUpload;

    //   try {
    //     const formData = new FormData();
    //     formData.append('file', file);
    //     const request = await calls.post(uploadDoc, formData);

    //     const { data } = request;
    //     if (data.token && metadatas) {
    //       const options = {
    //         token: data.token,
    //         metadata: metadatas
    //       };
    
    //       if (metadatas.name && metadatas.name.length) options.name = metadatas.name;
    
    //       const req = await calls.post(docUpdateLabelling, options);
    //       // const request = await calls.post('/documents/update-labelling', options);
    //     }

    //     commit('addImported', {
    //       name: data.name,
    //       token: data.token,
    //       path: data.path
    //     });
    //   } catch(e) {
    //     console.log('e :', e);
    //   }
    // },
    async updateFiles({ state, commit }) {
      const inProcessing = state.processeds.filter(d => !d.processed);

      if (!inProcessing.length) return;
      
      const tokens = inProcessing.map(d => d.token);
      const states = await calls.post('getDocsState', { tokens });

      for (let i = 0; i < inProcessing.length; i++) {
        const doc = inProcessing[i];
        doc.state = states.data.content[doc.token];
      }

      return true;
    },
  }
}

export default files;
