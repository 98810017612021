<template>
  <aside class="aside">
    <div class="aside-content">
      <div
        v-if="metadatas"
        class="aside-block"
      >
        <h2 class="aside-title">
          {{ $t('aside.metadatas.title') }}

          <button
            class="aside-title-action"
            @click="$emit('toggleMeta')"
          >
            <Sprite
              :type="'edit'"
              :width="16"
              :height="16"
            />
          </button>
        </h2>

        <p
          v-for="(value, key) in metas.main"
          :key="`metadata-${key}`"
          class="aside-metadata"
        >
          <!-- Label -->
          <span class="aside-metadata-property">{{ $t(`metadatas.${key}.label`) }}</span>
          <!-- Value -->
          <span v-if="key === 'date'" class="aside-metadata-value">{{ value }}</span>
          <span v-else class="aside-metadata-value">{{ $t(`metadatas.${key}.${value}`) }}</span>
        </p>

        <template
          v-for="(value, key) in metas.secondary"
          :key="`metadata-${key}`"
        >
          <p
            v-if="value"
            :class="'aside-metadata'"
          >
            <!-- Label -->
            <span class="aside-metadata-property">{{ $t(`metadatas.${key}.label`) }}</span>
            <!-- Value -->
            <span v-if="key.match(/nature|jurisdictionType|jurisdictionSection|jurisdictionName|jurisdictionAuthor|institution|abbr|language|collection/g)" class="aside-metadata-value">{{ $t(`metadatas.${key}.${value}`) }}</span>
            <span v-else-if="key.match(/date|legislationDate|publicationDate|pronouncedDate|redactionDate/)" class="aside-metadata-value">{{ formatDate(value) }}</span>
            <span v-else class="aside-metadata-value">{{ value }}</span>
          </p>
        </template>

        <p
          v-if="metas.lawcases && metas.lawcases.length"
          class="aside-metadata aside-metadata--lawcases"
        >
          <!-- Label -->
          <span class="aside-metadata-property">{{ $t('metadatas.lawcases.label') }}</span>
          <!-- Value -->
          <span
            v-for="lawcase in metas.lawcases"
            :key="`aside-lawcase-${lawcase.id}`"
            class="aside-metadata-value"
          >
            {{ lawcase.content }}
          </span>
          <!-- <span class="aside-metadata-value">{{ formatLawcases(metas.lawcases) }}</span> -->
        </p>

        <p
          v-if="metas.reference"
          class="aside-metadata aside-metadata--reference"
        >
          {{ metas.reference }}
        </p>
      </div>

      <h2 class="aside-title">{{ $t('aside.title') }}</h2>
      <p class="aside-text">{{ $t('aside.text') }}</p>

      <!-- Search -->
      <div class="aside-block">
        <h3 class="headline">{{ $t('aside.search.title') }}</h3>
        <form
          class="aside-search"
          novalidate
          @submit.prevent="onSearchSubmit"
        >
          <input
            class="aside-search-input"
            type="text"
            :value="search.terms"
            :placeholder="$t('aside.search.placeholder')"
            @input="onSearchInput"
          >
          <span
            v-show="search.currentTerms"
            class="aside-search-results"
          >
            {{ $tc('aside.search.results', search.results.length, { nbr: search.results.length }) }}
          </span>

          <button class="aside-search-submit">
            <Sprite
              :type="'search'"
              :width="16"
              :height="16"
            />
          </button>
        </form>
      </div>


      <!-- Tags -->
      <div class="aside-block">
        <h3 class="headline">{{ $t('aside.labels.title') }}</h3>
        <ul class="aside-labels">
          <li
            v-for="(label, index) in categorization"
            :key="`label-${index}`"
            :class="['aside-label', { 'is-open': label.open }]"
          >
            <!-- @click="$emit('toggleLabel', label)" -->
            <div
              class="aside-label-title"
              @mouseenter="$emit('highlightLabel', label)"
              @mouseleave="$emit('shadowLabel', label)"
            >
              <div
                :class="['aside-label-title-check', { 'is-open': editingColor?.name === label.name }]"
              >
                <button
                  class="check-toggle"
                  :style="{'background-color': `#${label.color.code}`,}"
                  @click="toggleColors(label)"
                />
                <div class="check-dropdown">
                  <ul
                    v-if="editingColor?.name === label.name"
                     class="check-dropdown-list"
                  >
                    <li
                      v-for="color in colors"
                      :key="color.code"
                    >
                      <button
                        :class="[{ 'is-selected': label.color.code === color.code }]"
                        :style="{ 'background-color': `#${color.code}` }"
                        @click="onInputLabel({ currentTarget: { value: color }}, label, 'color')"
                      />
                    </li>
                  </ul>
                </div>
              </div>
              <input
                :value="label.name"
                class="aside-label-title-input"
                @input="onInputLabel($event, label)"
                @blur="onBlurLabel($event, label)"
              />
              <!-- {{ label.name }} -->

              <div class="aside-label-title-actions">
                <button
                  class="aside-label-title-delete"
                  @click.stop="$emit('deleteLabel', label)"
                >
                  <Sprite
                    :type="'close'"
                    :width="16"
                    :height="16"
                  />
                </button>


                <button
                  class="aside-label-title-toggle"
                  @click.stop="$emit('toggleLabel', label)"
                >
                  <Sprite
                    v-if="!label.open"
                    :type="'chevron-down'"
                    :width="24"
                    :height="24"
                  />
                  <Sprite
                    v-else
                    :type="'chevron-up'"
                    :width="24"
                    :height="24"
                  />
                </button>
              </div>
            </div>

            <div
              v-if="label.open"
              class="aside-label-dropdown"
            >
              <div class="aside-label-id">
                <span>ID :</span>
                <input
                  :value="label.code"
                  @input="onInputLabel($event, label, 'code')"
                  @blur="onBlueLabelCode($event, label)"
                />
              </div>
              <ul class="aside-label-selections">
                <li
                  v-for="(selection, i) in label.data"
                  :key="`label-${index}-${i}`"
                  class="aside-label-selection"
                  @mouseenter="$emit('highlightSelection', { label, selection })"
                  @mouseleave="$emit('shadowSelection', { label, selection })"
                >
                  <span class="text">{{ selection.content }}</span>
                  <button
                    class="close"
                    @click="removeSelection({ label, selection })"
                  >
                    <sprite
                      :type="'close'"
                      :width="10"
                      :height="10"
                    />
                  </button>
                </li>
              </ul>
            </div>
          </li>
        </ul>
      </div>

      <!-- Generated tags -->
      <div
        v-if="tagsOpenAI?.length"
        class="aside-block"
      >
        <h3 class="headline">{{ $t('aside.labels.title-ia') }}</h3>
        <ul class="aside-labels">
          <li
            v-for="(label, index) in tagsOpenAI"
            :key="`label-${index}`"
            :class="['aside-label']"
          >
            <div class="aside-label-title">
              <span class="aside-label-title-input">{{ label }}</span>
              <div class="aside-label-title-actions">
                <button
                  class="aside-label-title-add"
                  @click.stop="$emit('addIALabel', label)"
                >
                  <Sprite
                    :type="'add'"
                    :width="16"
                    :height="16"
                  />
                </button>
              </div>
            </div>
          </li>
        </ul>
      </div>

      <!-- Prompt -->
      <div class="aside-block">
        <h3 class="headline">{{ $t('aside.labels.title-prompt') }}</h3>
        <textarea
          class="aside-input"
          :value="prompt"
          @input="onPrompt"
        />
        <Btn
          :theme="'primary'"
          @click="onRegenerate"
          :disabled="loadingRegenerate || !summary"
        >
          {{ $t('actions.regenerate') }}
        </Btn>
      </div>
    </div>

    <div class="aside-footer">
      <Btn
        :theme="'primary'"
        @click="onSave"
        :disabled="loading"
      >
      {{ $t('actions.save') }}
      </Btn>
    </div>
  </aside>
</template>

<script>
import Selection from '@/components/elements/Selection';
import Sprite from '@/components/elements/Sprite';
import Btn from '@/components/elements/Btn';
import { sanitizeCode } from '@/data/formaters/label';

import metadatas from '@/data/static/metadatas';

export default {
  components: {
    Selection,
    Sprite,
    Btn
  },
  props: {
    metadatas: {
      type: Object,
      default: null
    },
    categorization: {
      type: Array,
      default: () => []
    },
    tagsOpenAI: {
      type: Array,
      dafeult: null
    }
  },
  data() {
    return {
      juridsictionsName: {...metadatas.be.jurisprudence.jurisdictionsName},
      editingColor: null
    }
  },
  computed: {
    currentFile() {
      const files = this.$store.state.files.importeds;
      const selecteds = files.filter(f => f.token === this.$store.state.file.token);
      return selecteds;
    },
    metas() {
      const mainsProperties = ['type', 'country', 'visibility', 'anonymous', 'language'];
      const metas = {
        main: {},
        secondary: {}
      };

      for (const prop in this.metadatas) {
        if (Object.prototype.hasOwnProperty.call(this.metadatas, prop)) {
          if (mainsProperties.includes(prop)) {
            if (this.metadatas[prop]) {
              metas.main[prop] = this.metadatas[prop];
            }
          } else {
            if (prop === 'lawcases' || prop === 'reference') {
              metas[prop] = this.metadatas[prop];
            } else {
              metas.secondary[prop] = this.metadatas[prop];
            }
          }
        }
      }

      if (metas.secondary.source) {
        // metas.secondary.source = metas.secondary.source === 'symplicy' ? 'Symplicy' : metas.secondary.lawyerSource;
        if (metas.secondary.source === 'symplicy') {
          metas.secondary.source = 'Symplicy';
        } else {
          switch (typeof metas.secondary.lawyerSource) {
            case 'object':
              metas.secondary.source = metas.secondary.lawyerSource.name;
              break;
            case 'string':
              metas.secondary.source = metas.secondary.lawyerSource;
              break;
            default:
              break;
          }
          
        }
      }

      delete metas.secondary.name;
      delete metas.secondary.lawyerSource;

      return metas;
    },
    files() {
      return this.$store.state.files.importeds;
    },
    terms() {
      return this.$store.state.file.search.terms;
    },
    search() {
      return this.$store.state.file.search;
    },
    currentSearch() {
      return this.$store.state.file.search.current;
    },
    loading() {
      return this.$store.state.file.saveRequests !== 0;
    },
    loadingRegenerate() {
      return this.$store.state.file.regenerateRequests !== 0;
    },
    colors() {
      return this.$store.state.toolbox.colors;
    },
    summary() {
      return this.$store.state.file.summary;
    },
    prompt() {
      return this.$store.state.file.prompt;
    }
  },
  watch: {
    currentSearch(to, from) {
      if (to) {
        this.scrollToResult();
      }
    }
  },
  methods: {
    onSearchInput(e) {
      const { value } = e.currentTarget;
      this.$store.commit('file/updateSearchTerms', value);
    },
    onSearchSubmit(e) {
      const { terms, currentTerms } = this.$store.state.file.search;

      if(terms === currentTerms) {
        if (terms.length) {
          this.$store.dispatch('file/nextSearchCurrent');
        }
        return;
      }

      this.$store.dispatch('file/searchInFile');
    },
    removeSelection(e) {
      this.$emit('deleteSelection', { ...e });
    },
    onInputLabel(e, label, property) {
      let { value } = e.currentTarget;
      if(property === 'code') value = sanitizeCode(value);
      this.$emit('updateLabel', { value, label, property });
      this.editingColor = null;
    },
    onBlurLabel(e, label) {
      const { value } = e.currentTarget;
      this.$emit('blurLabel', { value, label });
    },
    onBlueLabelCode(e, label) {
      const { value } = e.currentTarget;
      this.$emit('blurLabelCode', { value, label });
    },
    onPrompt(e) {
      const { value } = e.currentTarget;
      this.$store.commit('file/update', { prop: 'prompt', value });
    },
    onRegenerate() {
      this.$store.dispatch('file/regenerateDocument');
    },
    onSave() {
      this.$store.dispatch('file/save');
    },
    scrollToResult() {},
    // Helpers
    formatDate(date) {
      if (!date) return date;
      const pieces = date.split('-');
      return `${pieces[2]}/${pieces[1]}/${pieces[0]}`;
    },
    formatLawcases(els) {
      return els.map(el => el.content).join(', ')
    },
    toggleColors(label) {
      if (this.editingColor?.name === label.name) {
        this.editingColor = null;
        return;
      }

      this.editingColor = label;
    }
  }
}
</script>

<style lang="scss" scoped>
  .aside {
    @include padding(32px null 16px);
    display: flex;
    flex-direction: column;
    width: 320px;
    flex-shrink: 0;
    background-color: var(--white);
    overflow: auto;

    &-content {
      @include padding(null 16px);
      @include size(100%, auto);
      display: flex;
      flex-direction: column;
      overflow: auto;
    }

    &-title {
      display: flex;
      align-items: center;
      justify-content: space-between;
      color: var(--primary);
      font-size: rem(18px);
      font-weight: 600;
      line-height: (24 / 18);

      &:not(:first-child) {
        margin-top: 32px;
      }

      &-action {
        @include size(22px);
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        margin-right: -4px;

        .svg {
          fill: var(--grey-light);
        }

        &:hover {
          .svg {
            fill: var(--primary);
          }
        }
      }
    }

    &-text {
      margin-top: 8px;
      color: rgba($black-light, .6);
      font-size: rem(12px);
      line-height: 2;
    }

    &-metadata {
      display: flex;
      justify-content: space-between;
      width: 100%;
      color: rgba($black-light, .6);
      font-size: rem(12px);
      line-height: 1.3;

      &--lawcases {
        flex-wrap: wrap;

        .aside-metadata-value {
          margin-top: 5px;
          width: 100%;
          text-align: left;

          + .aside-metadata-value {
            margin-top: 3px;
          }
        }
      }

      &--reference {
        margin-top: 12px;
        padding-top: 12px;
        border-top: 1px solid var(--grey-lightest);
      }

      &:not(:first-child):not(.aside-metadata--reference) {
        margin-top: 8px;
      }

      &-label {
        width: 40%;
      }

      &-value {
        color: var(--grey-darkest);
        width: 60%;
        text-align: right;
      }
    }

    &-block {
      width: 100%;

      &:not(:first-child) {
        margin-top: 16px;
      }

      + .aside-block:not(:first-child) {
        margin-top: 32px;
      }
    }


    // Search
    &-search {
      position: relative;

      &-input {
        @include padding(9px 36px 9px 16px);
        display: block;
        width: 100%;
        border: 1px solid var(--grey-border);
        border-radius: 8px;
        font-size: rem(14px);
        line-height: 1;
        background-color: var(--white);
        outline: none;
        transition: border-color .3s $ease-out-quart;

        &:focus,
        &:hover {
          border-color: var(--primary);
        }
      }

      &-results {
        font-size: rem(12px);
      }

      &-submit {
        @include position(absolute, 0 0 null null);
        @include size(36px);
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        

        &:focus,
        &:hover {
          .svg {
            fill: var(--primary);
          }
        }

        .svg {
          fill: var(--secondary);
        }
      }
    }

    // Labels
    &-label {
      width: 100%;

      &:not(:first-child) {
        border-top: 1px solid var(--grey-lightest);
      }

      &.is-open {
        .aside-label-title {
          padding-bottom: 4px;
        }
      }

      // Label title
      &-title {
        @include padding(null 0 null 0);
        display: flex;
        justify-content: flex-start;
        align-items: center;
        width: 100%;
        font-size: 14px;
        text-align: left;

        button:hover {
          .svg {
            fill: var(--primary);
            opacity: 1;
          }
        }

        .svg {
          fill: var(--grey);
          opacity: .6;
        }

        &-check {
          position: relative;
          margin-right: 8px;

          &.is-open {
            z-index: 2;
          }

          .check-toggle {
            @include size(14px);
            display: block;
            border-radius: 4px;
          }

          .check-dropdown {
            position: absolute;
            top: calc(100% + 9px);
            left: -8px;
            border-radius: 4px;
            box-shadow: 0px 2px 16px rgba(15, 15, 15, .24);

            &::before {
              content: '';
              position: absolute;
              left: 11px;
              top: -4px;
              z-index: 0;
              width: 8px;
              height: 8px;
              transform: rotate(45deg);
              background-color: var(--white);
              box-shadow: 0px 2px 16px rgba(15, 15, 15, .16);
            }

            &-list {
              @include padding(8px);
              display: flex;
              position: relative;
              z-index: 1;
              border-radius: 4px;
              background-color: $white;

              li {
                &:not(:first-child) {
                  margin-left: 4px;
                }
              }

              button {
                @include size(16px);
                display: block;
                position: relative;
                border-radius: 50%;

                &.is-selected {
                  &::before {
                    @include size(8px);
                    @include position(absolute, 4px null null 4px);
                    content: '';
                    border-radius: 50%;
                    transition: transform .1s $ease-out-quart;
                    background-color: var(--white);
                  }
                }
              }
            }
          }
        }

        &-input {
          @include padding(8px null 7px);
          flex-grow: 1;
          margin-right: 8px;
          border: 0;
          border-bottom: 1px solid rgba($primary, 0);
          font-size: 14px;

          &:focus {
            border-bottom-color: $primary;
            outline: none;
          }
        }

        &-actions {
          display: flex;
          margin-left: auto;
        }

        &-delete {
          @include padding(14px 8px);
        }

        &-add {
          @include padding(12px 8px);
        }

        &-toggle {
          @include padding(10px 8px);
          flex-shrink: 0;
          margin-right: -8px;
        }
      }

      &-dropdown {
        width: 100%;
        margin-bottom: 16px;
        padding-left: 8px;
      }

      // ID
      &-id {
        display: flex;
        align-items: flex-start;
        margin-bottom: 10px;

        span {
          color: var(--grey-darker);
          margin-right: 8px;
          line-height: 1.25;
        }

        input {
          @include padding(0 null 6px);
          flex-grow: 1;
          border: 0;
          border-bottom: 1px solid rgba($primary, 0);
          font-size: 14px;

          &:focus {
            border-bottom-color: $primary;
            outline: none;
          }
        }
      }

      // &-selections {}

      // Label selection
      &-selection {
        @include padding(null 0 null 14px);
        display: flex;
        justify-content: space-between;
        position: relative;
        overflow: hidden;
        color: var(--grey-darker);
        font-size: 12px;
        line-height: (16 / 12);

        &:not(:first-child) {
          margin-top: 8px;
        }

        // Label selection text
        .text {
          display: -webkit-box;
          width: calc(100% - 16px);
          max-height: 32px;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;
          text-overflow: ellipsis;
          overflow: hidden;
        }

        &::before {
          @include size(4px);
          @include position(absolute, 6px null null 0);
          content: '';
          border-radius: 50%;
          background-color: var(--grey-darker);
        }

        // Label selection close
        .close {
          @include padding(1px 8px);
          margin-left: 4px;
          margin-right: -8px;
          cursor: pointer;

          &:hover {
            .svg {
              fill: var(--primary);
              opacity: 1;
            }
          }

          .svg {
            fill: var(--grey);
            opacity: .6;
          }
        }
      }
    }

    &-input {
      @include padding(9px 16px 9px);
      display: block;
      width: 100%;
      border: 1px solid var(--grey-border);
      border-radius: 4px;
      font-family: $sans-serif;
      font-size: rem(14px);
      line-height: 1;
      background-color: var(--white);
      outline: none;
      transition: border-color .3s $ease-out-quart;
      appearance: none;
      resize: vertical;
      margin-bottom: 12px;

      &:focus,
      &:hover {
        border-color: var(--primary);
      }
    }

    // Footer
    // -----------------------------------------------------------------------------
    &-footer {
      @include padding(16px 16px null);
      width: 100%;
      margin-top: auto;

      .button {
        width: 100%;
      }
    }
  }
</style>
