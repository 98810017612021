<template>
  <div class="lawyers">
    <Header
      :title="$t('pages.office.lawyers.title')"
      :search="'Chercher un avocat'"
      :actions="[{
        label: $t('actions.add-lawyer'),
        action: () => add = true,
      }]"
      @onSearch="($event) => search = $event"
    />

    <div class="lawyers-list">
      <p v-if="!lawyers?.length && !loading && search" class="noresult">{{ $t('pages.office.lawyers.noresult') }}</p>

      <div
        v-for="lawyer in lawyers"
        :key="`lawyer-${lawyer.id}`"
        class="lawyer box"
      >
        <button class="lawyer-button" @click.stop.prevent="onLawyerClick(lawyer)">
          <span class="u-visually-hidden">{{ lawyer.name }}</span>
        </button>
        <img v-if="lawyer.profile_picture_url" :src="lawyer.profile_picture_url" class="lawyer-round"/>
        <span v-else class="lawyer-round">{{ lawyer.name ? lawyer.name.charAt(0) : '' }}</span>

        <div class="lawyer-infos">
          <span class="lawyer-name">{{ lawyer.name }}</span>
          <span v-if="lawyer.id === originalClient.admin_id" class="lawyer-role">{{ 'Admin' }}</span>
        </div>
      </div>

      <div
        v-if="loading"
        class="lawyers-list-loader"
      >
        <Loader />
      </div>
    </div>
    <Invite :isVisible="add" @onClose="onCloseInvite"/>
  </div>
</template>

<script>
import Btn from "@/components/elements/Btn";
import Input from "@/components/elements/Input";
import Loader from "@/components/elements/Loader";
import Header from "@/components/modules/Header";
import Invite from "@/components/partials/Popups/Invite";

import { calls } from '@/services/calls';

export default {
  components: {
    Btn,
    Input,
    Loader,
    Header,
    Invite
  },
  mounted() {
    this.$store.dispatch("client/getLawyers");
  },
  data() {
    return {
      search: '',
      add: false
    }
  },
  computed: {
    lawyers() {
      const { lawyers } = this.$store.state.client;
      const search = this.search?.toLowerCase();
      return lawyers.filter(lawyer => {
        if (!search) return true;
        const name = lawyer.name.toLowerCase();
        return name.match(search);
      });
    },
    originalClient() {
      const { originalData } = this.$store.state.client;
      return originalData;
    },
    disabled() {
      const { email } = this;
      // eslint-disable-next-line
      const regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return !email.value?.length || !regex.test(email.value);
    },
    loading() {
      const { lawyers } = this.$store.state.client.requests;
      return lawyers !== 0;
    }
  },
  methods: {
    async onInvite() {
      this.add.requests++;

      try {
        const request = await calls.post('inviteUser', { email: this.email.value });
      } catch (error) {
        console.log('error :', error);
      }

      this.add.requests--;
    },
    onCloseInvite() {
      this.add = false;
    },
    onLawyerClick(lawyer) {
      this.$store.commit('lawyer/update', { prop: 'id', value: lawyer.id });
      this.$store.commit('lawyer/update', { prop: 'data', value: { ...lawyer }});
      this.$store.commit('lawyer/update', { prop: 'originalData', value: { ...lawyer }});
    }
  }
}
</script>

<style lang="scss" scoped>
.lawyers {
  &-list {
    position: relative;

    &-loader {
      &:not(:first-child) {
        @include position(absolute, 0 null null 0);
        @include size(100%, auto);
        z-index: 1;
      }
    }

    .noresult {
      margin-top: 32px;
    }
  }

  .lawyer {
    @include padding(16px);
    display: flex;
    align-items: flex-start;
    position: relative;
    width: 100%;
    box-shadow: 0px 1px 4px rgba(15, 15, 15, 0.16);

    &:not(:first-child) {
      margin-top: 2px;
    }

    &-button {
      @include position(absolute, 0 null null 0);
      @include size(100%);
      z-index: 1;

      &:hover {
        ~ .lawyer-infos {
          .lawyer-name {
            color: var(--primary);
          }
        }
      }
    }

    &-round {
      @include size(32px);
      border-radius: 50%;
      background-color: var(--grey-lightest);
      text-align: center;
      line-height: 32px;
    }

    &-infos {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      margin-left: 16px;
    }

    &-name {
      transition: color .3s $ease-out-quart;

      &:not(:last-child) {
        margin-top: -4px;
      }
    }

    &-role {
      color: var(--grey);
      font-size: rem(12px);
      line-height: (16 / 12);
      margin-bottom: -4px;
    }
  }

  // :deep(.popup-wrapper) {
  //   max-width: 576px;
  // }

  // .add-form {
  //   display: flex;
  //   flex-direction: column;
  //   align-items: flex-start;

  //   .input {
  //     width: 100%;
  //     max-width: 312px;
  //   }

  //   .button {
  //     margin-left: auto;
  //   }
  // }
}
</style>