import Office from '../views/Office.vue';
import Lawyers from '../views/office/Lawyers.vue';
import Policies from '../views/office/Policies.vue';
import Informations from '../views/office/Informations.vue';
import OfficeSubscription from '../views/office/Subscription.vue';

export const office = [
  {
    path: '/office',
    name: 'Office',
    component: Office,
    redirect: '/office/informations',
    meta: {
      userMenu: true,
    },
    children: [
      {
        path: '/office/informations',
        name: 'Informations',
        component: Informations,
        meta: {
          topbar: 'detail',
          sidebarLeft: 'officeMenu'
        }
      },
      {
        path: '/office/lawyers',
        name: 'Lawyers',
        component: Lawyers,
        meta: {
          topbar: 'detail',
          sidebarLeft: 'officeMenu',
          sidebarRight: 'lawyer'
        }
      },
      {
        path: '/office/policies',
        name: 'Policies',
        component: Policies,
        meta: {
          admin: true,
          topbar: 'detail',
          sidebarLeft: 'officeMenu'
        }
      },
      {
        path: '/office/subscription',
        name: 'OfficeSubscription',
        component: OfficeSubscription,
        meta: {
          admin: true,
          trial: false,
          shortName: "Subscription",
          topbar: 'detail',
          sidebarLeft: 'officeMenu'
        }
      },
    ]
  },
]