<template>
  <div class="app">
    <!-- Authentication -->
    <AppLoader v-if="loading" />
    <Layout v-else-if="auth.isAuth && !$route?.name?.match(/Welcome|Login|Forgot|Register|Subscribe|Reset/)" :isStaging="isStaging"/>
    <Welcome v-else-if="auth.isAuth && $route?.name?.match(/Welcome/)"/>
    <Authentication v-else/>
  </div>
</template>

<script>
import StagingBar from '@/components/partials/StagingBar';
import AppLoader from '@/components/partials/AppLoader';
import Layout from '@/views/Layout';
import Welcome from '@/views/Welcome';
import Authentication from '@/views/Authentication';

export default {
  components: {
    AppLoader,
    Layout,
    Welcome,
    Authentication,
    StagingBar
  },
  beforeMount() {
    const { name, fullPath } = this.$route;
    const { pathname } = window.location;

    if (name !== 'Reset' && (pathname?.length && pathname !== '/')) {
      this.$store.dispatch('user/authCheck');
    }

    if (process.env.VUE_APP_STAGING === "true") {
      console.lgo = console.log;
    }
  },
  data() {
    return {
      isStaging: process.env.VUE_APP_STAGING === "true"
    }
  },
  watch: {
    $route(current) {
      if (current.fullPath === '/') {
        this.$store.dispatch('user/authCheck');
      }
    }
  },
  computed: {
    auth() {
      return this.$store.state.user.auth;
    },
    loading() {
      return this.$store.state.user.requests.auth !== 0;
    }
  }
};
</script>


<style lang="scss">
@import '@/scss/reset.scss';
@import '@/scss/colors.scss';

html {
  @include font-smoothing();
}

html,
body,
#app {
  @include size(100%);
  color: $black-light;
  font-family: $sans-serif;
  font-size: 14px;
  line-height: (24 / 14);
}

.app {
  @include size(100%, 100vh);
  overflow: hidden;
  // background-color: var(--white-dark);
  background-color: var(--white-bg);
  color: var(--black-light);
}

.u-visually-hidden {
  border: none !important;
  clip: rect(0 0 0 0) !important;
  height: 1px !important;
  margin: -1px !important;
  overflow: hidden !important;
  padding: 0 !important;
  position: absolute !important;
  white-space: nowrap !important;
  width: 1px !important;
}

.container {
  @include margin(null auto);
  max-width: $gs-container;
  // max-width: 720px;
}

.box {
  @include padding(16px);
  background-color: var(--white);
  border-radius: 8px;
}

// Text
.h1 {
  font-size: rem(24px);
  font-weight: 700;
  line-height: (32 / 24);
}

.h2 {
  font-size: rem(20px);
  font-weight: 700;
  line-height: (32 / 20);
}

.h3 {
  font-size: rem(16px);
  font-weight: 700;
  line-height: (24 / 16);
}

.headline {
  color: var(--grey);
  font-size: rem(12px);
  font-weight: 700;
  line-height: 2;
  letter-spacing: 1px;
  text-transform: uppercase;
}

.small {
  color: var(--grey);
  font-size: rem(12px);
  line-height: (16 / 12);
}

strong,
.bold {
  font-weight: 700;
}

.noresult {
  font-size: rem(16px);
  color: var(--grey-light);
  text-align: center;

  &:not(:first-child) {
    margin-top: 32px;
  }
}

p {
  a {
    color: currentColor;
    font-weight: bold;
    transition: color .3s $ease-out-quart;

    &:hover {
      color: var(--primary);
    }
  }
}
</style>
