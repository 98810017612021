<template>
  <nav class="menu">
    <ul class="menu-list">
      <li 
        v-for="(link, index) in links"
        :key="`office-link-${index}`"
        class="menu-list-item"
      >
        <router-link :to="link.to" class="menu-list-link">{{ link.label }}</router-link>
      </li>
    </ul>
  </nav>
</template>

<script>
export default {
  computed: {
    isAdmin() {
      const { user, client } = this.$store.state;
      return user.data.id === client.data.admin_id;
    },
    links() {
      const { routes } = this.$router.options;
      // const { trial_ends_at } = this.$store.state.client.data;
      const office = routes.find(r => r.name === 'Office');
      return office.children
        .filter(r => {
          // if (r.meta.trial === false) return !trial_ends_at;
          if (r.meta.admin) return this.isAdmin;
          return true;
        })
        .map(route => {
          const shortcode = route.meta?.shortName || route.name;
          return {
            label: this.$t(`pages.office.${shortcode.toLowerCase()}.title`),
            to: { name: route.name }
          }
        });
    }
  },
}
</script>


<style lang="scss" scoped>
.menu {
  &-list {
    display: flex;
    flex-direction: column;

    &-item {
      width: 100%;
    }

    &-link {
      @include padding(11px 8px 13px);
      display: block;
      position: relative;
      z-index: 1;
      width: 100%;
      border-radius: 4px;
      line-height: (16 / 14);
      text-decoration: none;
      color: var(--black-light);
      transition: color .2s $ease-out-quart, background-color .2s $ease-out-quart;

      &:hover,
      &.router-link-active {
        font-weight: bold;
      }

      &.router-link-active {
        background-color: var(--white-dark);
        font-weight: bold;
      }
    }
  }
}
</style>