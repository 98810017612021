<template>
  <div class="categories">
    <Header
      :title="$route ? $t(`pages.account.categories.${$route.meta.shortName}.title`) : null"
      :text="text"
      :actions="actions"
      :search="search"
      @onSearch="onSearch"
    />
    <router-view/>
  </div>
</template>

<script>
import Header from "@/components/modules/Header";
import Loader from '@/components/elements/Loader';

export default {
  components: {
    Header,
    Loader
  },
  beforeMount() {
    this.$store.dispatch('user/getUserCategories');
    this.onSearch(null);
  },
  computed: {
    name() {
      return this.$route?.name;
    },
    search() {
      return this.name === 'CategoriesDetail' ? 'Chercher une problématique' : 'Chercher une matière';
    },
    text() {
      switch (this.name) {
        case 'CategoriesUpdate':
          return this.$t('pages.account.categories.update.text');
        default:
          return null;
      }
    },
    actions() {
      switch (this.name) {
        case 'CategoriesList':
          return [
            {
              label: this.$t('actions.update_categories'),
              action: async () => {
                this.$router.push({ name: 'CategoriesUpdate' });
              }
            }
          ];
        case 'CategoriesUpdate':
          return [
            {
              label: this.$t('actions.confirm'),
              action: async () => {
                const req = await this.$store.dispatch("user/setUserCategories");

                if (req.status === 'success') {
                  if (this.$store.state.user.data.onboarding?.has_user_set_their_skills === false) {
                    await this.$store.dispatch('user/getUserOnboardingStatus');
                    this.$router.push({ name: 'LegalCases' });
                    return;
                  }

                  this.$store.dispatch('user/getUserCategories');
                  this.$router.push({ name: 'CategoriesList' });
                }
              }
            }
          ];
      
        default:
          return [];
      }
    }
  },
  methods: {
    onSearch(value) {
      if (value) value = value.toLowerCase();

      if (this.name === "CategoriesDetail") {
        this.$store.commit('category/update', { prop: 'skillsSearch', value });
        return;
      }

      this.$store.commit('user/update', { prop: 'categoriesSearch', value });
    }
  }
};
</script>