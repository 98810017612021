<template>
  <div class="update">
    <div v-if="loading" class="update-loader">
      <Loader/>
    </div>
    <Category
      v-for="cat in categories"
      :key="`cat-${cat.id}`"
      :category="cat"
      :isInput="true"
      :checked="userCategoriesID.includes(cat.id)"
      @onInput="onInput(cat)"
    />
  </div>
</template>

<script>
import Loader from '@/components/elements/Loader';
import Category from '@/components/elements/Category';

export default {
  components: {
    Loader,
    Category
  },
  beforeMount() {
    this.$store.dispatch('user/getAllCategories');
  },
  computed: {
    userCategoriesID() {
      const { categories } = this.$store.state.user.data;
      return categories.map(cat => cat.id);
    },
    search() {
      const { categoriesSearch } = this.$store.state.user;
      return categoriesSearch;
    },
    categories() {
      const { categories } = this.$store.state.user;
      return categories.filter(cat => {
        if(!this.search) return true;
        const description = cat.description.toLowerCase();
        return description.match(this.search);
      });
    },
    loading() {
      return this.$store.state.user.requests.categories !== 0;
    }
  },
  methods: {
    onInput(cat) {
      const categories = JSON.parse(JSON.stringify(this.$store.state.user.data.categories));
      const index = categories.map(c => c.id).indexOf(cat.id);

      if(index >= 0) this.$store.commit('user/removeUserCategory', cat)
      else this.$store.commit('user/addUserCategory', cat)
    }
  }
}
</script>

<style lang="scss" scoped>
.update {
  position: relative;
  width: 100%;

  &-loader {
    display: flex;
    justify-content: center;

    &:not(:last-child) {
      @include position(absolute, 0 null null 0);
      @include size(100%);
      z-index: 1;
      background-color: rgba($white, .5);
    }
  }
}

.category {
  &:not(:first-child) {
    margin-top: 2px;
  }
}
</style>