<template>
  <div
    :class="[
      'tabs',
      { 'tabs--light': light },
      { 'is-open': isOpen }
    ]"
    @click.stop
  >
    <button
      class="tabs-toggle"
      @click.stop.prevent="onToggle"
    >
      {{ $t(`pages.${$route.name.toLowerCase()}.title`) }}
      <Sprite :type="isOpen ? 'chevron-up' : 'chevron-down'"/>
    </button>
    <div class="tabs-dropdown">
      <ul class="tabs-list">
        <li
          v-for="tab in tabs"
          :key="`tabs-${tab.name}`"
          :class="[
            'tabs-item',
            { 'tabs-item--active': tab.name === $route.name }
          ]"
        >
          <router-link
            class="tabs-link"
            :to="{
              name: tab.name,
              params: $route.params
            }"
            @click.prevent="onClick(tab)"
          >
            {{ $t(`pages.${tab.name.toLowerCase()}.title`) }}
          </router-link>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import Sprite from '@/components/elements/Sprite';

export default {
  components: {
    Sprite
  },
  props: {
    tabs: {
      type: Array,
      required: true
    },
    light: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      isOpen: false,
      _documentClick: this.onDocumentClick.bind(this),
    }
  },
  methods: {
    onClick(tab) {
      this.$router.push({ name: tab.name, params: this.$route.params });
      this.isOpen = !this.isOpen;
    },
    onToggle() {
      this.isOpen = !this.isOpen;

      if (this.isOpen) {
        document.addEventListener('click', this._documentClick);
      } else {
        document.removeEventListener('click', this._documentClick);
      }
    },
    onDocumentClick() {
      this.onToggle();
    },
  }
}
</script>

<style lang="scss" scoped>
.tabs {
  position: relative;
  z-index: 1;

  @include mq($until: 'm') {
    @include margin(null 0);
    flex-direction: column;
    margin-bottom: 24px;

    &.is-open {
      &::before {
        display: block;
      }

      .tabs-toggle {
        border-radius: 4px 4px 0 0;
        border-bottom: 0;
      }

      .tabs-dropdown {
        display: block;
      }

      .tabs-link {
        display: block !important;
      }
    }
  }

  // Background white
  &.tabs--light {
    &::before {
      content: none;
    }

    // &::after {
    //   @include position(absolute, 0 50% null null);
    //   @include size(100vw, 100%);
    //   content: '';
    //   z-index: -1;
    //   transform: translateX(50%);
    //   background-color: var(--white);

    //   @include mq($until: 'm') {
    //     height: calc(100% + 24px);
    //   }
    // }

    .tabs-list {
      margin-bottom: 0;
    }

    // .tabs-link {
    //   &::before {
    //     height: 3px;
    //   }
    // }
  }

  // Border
  &:before {
    @include position(absolute, null null 0 16px);
    @include size(calc(100% - 32px), 1px);
    display: none;
    content: '';
    background-color: var(--grey-border);
    z-index: 3;

    @include mq($from: 'm') {
      display: block;
      width: 100%;
      left: 0;
    }
  }

  &-toggle {
    @include padding(9px 16px);
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    z-index: 2;
    width: 100%;
    max-height: 37px;
    border: 1px solid var(--grey-border);
    border-radius: 4px;
    background-color: var(--white);
    font-family: $sans-serif;
    font-size: rem(14px);
    line-height: 1.25;

    /* &::before {
      @include position(absolute, calc(100% - 1px) null null 16px);
      @include size(calc(100% - 32px), 1px);
      content: '';
      background-color: var(--grey-border);
      opacity: 0;
      z-index: 2;
    } */

    @include mq($from: 'm') {
      display: none;
    }
  }

  &-dropdown {
    @include mq($until: 'm') {
      display: none;
      position: absolute;
      top: calc(100% - 1px);
      left: 0;
      z-index: 1;
      width: 100%;
      border-radius: 0 0 4px 4px;
      background-color: var(--white);

      &::before {
        @include position(absolute, null null 0 0);
        @include size(100%, calc(100% + 35px));
        content: '';
        z-index: -1;
        border-radius: 4px;
        box-shadow: 0 4px 16px rgba($black, .2);
      }

      &::after {
        @include position(absolute, null null 0 0);
        @include size(100%);
        content: '';
        z-index: 1;
        border-radius: 0 0 4px 4px;
        border: 1px solid var(--grey-border);
        border-top: 0;
        pointer-events: none;
      }
    }
  }

  &-list {
    display: flex;
    align-items: center;
    position: relative;
    z-index: 1;

    @include mq($from: 'm') {
      @include margin(null -10px 24px);
    }

    @include mq($until: 'm') {
      @include padding(5px null);
      flex-direction: column;
      width: 100%;
      height: auto;
      max-height: 250px;
      overflow: auto;
      /* box-shadow: 0 4px 16px rgba($black, .3); */
      /* box-shadow: 0px 2px 16px rgba(15, 15, 15, .16); */

      /* &::before {
        @include position(absolute, null null 0 0);
        @include size(100%, calc(100% + 37px));
        content: '';
        z-index: -1;
        border-radius: 4px;
        box-shadow: 0 4px 16px rgba($black, .3);
      } */
    }
  }

  &-item {
    flex-shrink: 0;

    @include mq($until: 'm') {
      width: 100%;
      order: 1;

      &--active {
        display: none;
      }
    }
  }

  &-link {
    @include padding(4px 10px 12px);
    position: relative;
    display: block;
    color: $black;
    transition: color .3s $ease-out-quart;
    text-decoration: none;
    font-size: rem(14px);

    @include mq($until: 'm') {
      @include padding(5px 16px);
      /* font-size: rem(12px); */

      /* &:not(.router-link-exact-active) {
        display: none;
      } */
    }

    @include mq($from: 'm') {
      &::before {
        @include position(absolute, null null 0 10px);
        @include size(calc(100% - 20px), 2px);
        content: '';
        background-color: var(--primary);
        transform: scaleY(0);
        transform-origin: 50% 100%;
      }

      &:hover {
        color: var(--primary);
      }

       &.router-link-active {
        color: var(--primary);
        border-bottom-color: var(--white);

        &::before {
          transform: scaleY(1);
        }
      }
    }
  }
}
</style>
