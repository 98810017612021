<template>
  <a
    href="#"
    :class="[
      'legalcase',
      { 'legalcase--failed': legalcase.state === 'failure' },
      { 'legalcase--selected': selected }
    ]"
    @click.prevent="onClick(legalcase)"
  >
    {{ legalcase.litigant.firstname }} {{ legalcase.litigant.lastname }} · {{ legalcase.law_case_content ? legalcase.law_case_content : 'Aucun cas concret lié'}}
    <div
      v-if="legalcase.created_at || user"
      class="legalcase-metadatas"
    >
      <div class="legalcase-metadatas-left">
        <span
          v-if="legalcase.created_at"
          class="legalcase-metadata date"
        >
          {{ formatDate(legalcase.created_at) }}
        </span>
        <span
          v-if="user"
          class="legalcase-metadata user"
        >
          {{ user }}
        </span>
      </div>
    </div>
  </a>
</template>

<script>
import Sprite from '@/components/elements/Sprite';
import moment from 'moment';

export default {
  components: {
    Sprite
  },
  props: {
    legalcase: {
      type: Object,
      required: true
    },
    selected: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    user() {
      const currentUser = this.$store.state.user.data;
      const { user } = this.legalcase;
      return user && currentUser.id !== user.id ? user.name : null;
    }
  },
  methods: {
    // Events
    onClick($event) {
      this.$emit('onClick', $event)
    },
    onDelete($event) {
      this.$emit('onDelete', $event)
    },
    // Helpers
    formatDate(date) {
      return moment(date).format('DD[/]MM[/]YYYY')
    }
  }
};
</script>

<style lang="scss" scoped>
.legalcase {
  @include padding(6px 14px);
  // @include padding(8px 16px);
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
  width: 100%;
  transition: color .3s $ease-out-quart;
  border: 2px solid var(--white);
  border-radius: 8px;
  background-color: var(--white);
  box-shadow: 0px 1px 4px rgba(15, 15, 15, .16);
  color: var(--black);
  font-family: $alternate;
  font-weight: 400;
  // font-weight: 600;
  text-decoration: none;

  &:hover {
    color: var(--primary);
  }

  &--failed {
    pointer-events: none;
    color: rgba(0, 0, 0, .5) !important;

    &-metadatas { opacity: .5; }
  }

  &--selected {
    border-color: var(--primary);
  }

  &-label {
    @include padding(4px 16px);
    display: flex;
    align-items: center;
    border-radius: 12px;
    background-color: rgba($grey-lightest, .4);
    color: var(--grey);
    font-family: $alternate;
    font-size: rem(12px);
    font-weight: 400;
    line-height: 1;
    margin-left: auto;

    .svg {
      margin-right: 4px;
      fill: var(--grey);
      opacity: .4;
    }
  }

  &-delete {
    @include size(24px);
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 10px;
    border-radius: 50%;
    background-color: rgba($grey-lightest, .4);
    pointer-events: all;

    .svg {
      fill: var(--grey);
      opacity: .4;
    }
  }

  &-metadatas {
    display: flex;
    align-items: flex-start;
    width: 100%;
    color: var(--grey);
    font-size: rem(14px);

    &-left,
    &-right {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
    }

    &-left {
      width: 70%;
    }

    &-right {
      width: 30%;
    }
  }

  &-metadata {
    &:not(:first-child) {
      position: relative;
      padding-left: 8px;
      margin-left: 8px;

      &::before {
        @include position(absolute, 0 null null -1px);
        content: '·';
      }
    }

    &.visibility {
      margin-left: auto;

      &:before {
        content: none;
      }
    }
  }
}
</style>
