import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import i18n from './i18n';

import '@vueup/vue-quill/dist/vue-quill.snow.css';


createApp(App)
.use(store)
.use(router)
.use(i18n)
.mount('#app');
