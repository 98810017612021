<template>
  <div class="page home">
    <div class="container">
      <Header
        :title="$t('pages.admin.title')"
        :actions="actions"
      />

      <template v-if="!loading">
        <!-- Search -->
        <template v-if="!isSearch">
          <div class="home-list">
            <!-- Processeds -->
            <List
              v-if="processeds && processeds.length"
              :items="processeds"
              :canDelete="true"
              @onDelete="onDelete"
              @onClick="onClickDoc"
            />

            <Pagination
              v-if="processeds.length"
              :total="total"
              :current="parseInt($route?.query?.page, 10) || 1"
              :perPage="globals.pagination.perPage"
            />

            <p v-if="!processeds.length">Aucun document trouvé</p>
          </div>
        </template>

        <template v-else>
          <List
            :title="$tc('lists.documents.search', search.length, { nbr: search.length })"
            :items="search"
            :displayHTML="true"
            @onClick="onClickDoc"
          />
        </template>
      </template>

      <Loader v-else/>
    </div>
  </div>
</template>

<script>
import Loader from '@/components/elements/Loader';
import List from '@/components/modules/List';
import Header from '@/components/modules/Header';
import Pagination from '@/components/modules/Pagination';
import globals from '@/data/static/globals.json';

export default {
  name: 'Home',
  components: {
    Header,
    List,
    Loader,
    Pagination
  },
  beforeMount() {
    this.$store.commit('polysearch/updateName', 'juridical-documents');
    this.$store.commit('documents/updateScopes', ['admin']);
    this.getData();
    this.$store.dispatch('polysearch/getFilters');
  },
  data() {
    return {
      globals,
      updating: false,
      actions: [
        {
          label: this.$t('home.upload.title'),
          action: () => this.$store.commit('upload/toggle')
        }
      ]
    }
  },
  computed: {
    processeds() {
      return this.$store.state.documents.items.filter(d => d.state === 'processed');
    },
    search() {
      return this.$store.state.documents.search;
    },
    isSearch() {
      return this.$store.state.documents.isSearch;
    },
    loading() {
      return this.$store.state.documents.requests !== 0
    },
    total() {
      return this.$store.state.documents.total;
    }
  },
  watch: {
    $route(current, prev) {
      if (current && current.name === 'Admin') {
        this.getData();
      }
    }
  },
  methods: {
    async getData() {
      const { page } = this.$route.query;
      if (page) {
        await this.$store.dispatch('documents/getDocuments', {
          offset: (page - 1) * globals.pagination.perPage,
          status: 'processed'
        });
      } else {
        await this.$store.dispatch('documents/getDocuments', { status: 'processed' });
      }

      if (page && this.total !== 0 && parseInt(page, 10) > Math.ceil(this.total / globals.pagination.perPage)) {
        this.$router.push({
          name: this.$route.name,
          query: { page: Math.floor(this.total / globals.pagination.perPage) }
        })
      }
    },
    async onDelete(token) {
      const req = await this.$store.dispatch('documents/delete', token);
      if (req.status === 'success') {
        this.getData();
      }
    },
    onClickDoc(doc) {
      this.$store.dispatch('preview/set', doc);
    },
  }
};
</script>

<style lang="scss" scoped>
.home {
  .home-header {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 24px;

    .h1 {
      margin-bottom: 8px;
    }

    .search {
      width: calc(100% - 250px);
      max-width: 624px;
    }
  }

  .home-list {
    display: flex;
    flex-direction: column;
    align-items: center;

    .loader {
      margin-top: 24px;
    }
  }
}
</style>

<style lang="scss">
.home {
  .popup {
    .button:not(.pill) {
      margin-top: 32px;
      margin-left: auto;
    }
  }
}
</style>
