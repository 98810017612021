<template>
  <div class="my-cases">
    <template v-if="!loading">
      <!-- News -->
      <List
        v-if="news && news.length"
        :title="$t('lists.legalcases.news')"
        :items="news"
        :type="'legal-case'"
        @onClick="onClick"
      />

      <!-- Openeds -->
      <List
        v-if="openeds && openeds.length"
        :title="$t('lists.legalcases.processings')"
        :items="openeds"
        :type="'legal-case'"
        @onClick="onClick"
      />

      <!-- Closed -->
      <List
        v-if="closeds && closeds.length"
        :title="$t('lists.legalcases.closeds')"
        :items="closeds"
        :type="'legal-case'"
        @onClick="onClick"
      />

      <Pagination
        v-if="items.length"
        :total="total"
        :current="parseInt($route?.query?.page, 10) || 1"
        :perPage="globals.pagination.perPage"
      />

      <p
        v-if="!items || !items.length"
        class="noresult"
      >
        {{ $t('legalcases.noresult.default') }}
      </p>
    </template>
    <Loader v-else/>
  </div>
</template>


<script>
import Loader from '@/components/elements/Loader';
import List from '@/components/modules/List';
import Pagination from '@/components/modules/Pagination';
import globals from '@/data/static/globals.json';

export default {
  name: 'My-cases',
  components: {
    List,
    Loader,
    Pagination
  },
  beforeMount() {
    this.$store.commit('legalCases/updateFilter', { filter: 'all', value: false });
    this.getData();
  },
  data() {
    return {
      globals
    }
  },
  computed: {
    news() {
      return this.$store.state.legalCases.items.filter(d => d.status === 'new');
    },
    items() {
      return this.$store.state.legalCases.items;
    },
    openeds() {
      return this.$store.state.legalCases.items.filter(d => d.status === 'opened');
    },
    closeds() {
      return this.$store.state.legalCases.items.filter(d => d.status === 'closed');
    },
    loading() {
      return this.$store.state.legalCases.requests !== 0;
    },
    total() {
      return this.$store.state.legalCases.total;
    }
  },
  watch: {
    $route(newRoute) {
      if (newRoute.name === 'MyCases') {
        this.getData();
      }
    }
  },
  methods: {
    getData() {
      const { page } = this.$route.query;
      if (page) {
        this.$store.dispatch('legalCases/get', { offset: (page - 1) * globals.pagination.perPage });
        return;
      }

      this.$store.dispatch('legalCases/get', {});
    },
    onClick(legalcase) {
      this.$store.commit('legalCase/setData', legalcase);
      this.$router.push({
        name: 'LegalCase',
        params: {
          token: legalcase.token
        }
      })
    }
  }
};
</script>