<template>
  <div class="loader">
    <span v-if="label" class="loader-label">{{ label }}</span>
  </div>
</template>

<script>
export default {
  props: {
    label: { type: String }
  }
}
</script>

<style lang="scss" scoped>
  .loader {
    @include margin(null auto);
    @include size(80px);
    position: relative;

    @keyframes loader {
      0% {
        transform: scale(0);
        opacity: 1;
      }
      100% {
        transform: scale(1);
        opacity: 0;
      }
    }

    @keyframes loader-after {
      0% {
        transform: scale(0);
        opacity: 1;
      }
      100% {
        transform: scale(.85);
        opacity: 0;
      }
    }

    &::before,
    &::after {
      @include position(absolute, 0 null null 0);
      // @include position(absolute, calc(50% - 40px) null null calc(50% - 40px));
      @include size(100%);
      content: "";
      border: 3px solid var(--primary);
      border-radius: 50%;
      opacity: 0;
      animation: loader 1.2s $ease-out-quart infinite;
    }

    &::after {
      animation: loader-after 1.2s $ease-out-quart .3s infinite;
    }

    &-label {
      @include position(absolute, calc(100% + 16px) null null 50%);
      transform: translateX(-50%);
      color: var(--grey-darker);
      font-weight: 300;
    }
  }
</style>
