<template>
  <ul class="points">
    <li
      v-for="(point, index) in points"
      :key="`point-${index}`"
      class="point"
    >
      <Sprite
        type="auth-check"
        :width="20"
        :height="20"
      />
      <div class="point-content">
        <span class="point-title">{{ point.title }}</span>
        <span class="point-text">{{ point.text }}</span>
      </div>
    </li>
  </ul>
</template>

<script>
import Sprite from '@/components/elements/Sprite';

export default {
  components: {
    Sprite
  },
  data() {
    return {
      points: [
        {
          title: 'Démarrage rapide',
          text: 'Commencez à gérer les affaires de votre cabinet en 3 minutes.'
        },
        {
          title: 'Testez gratuitement pendant 30 jours',
          text: 'Sans engagement, sans carte de crédit, vous arrêtez quand vous voulez votre période d’essai.'
        },
        {
          title: 'Réduisez vos tâches administratives',
          text: 'Symplicy demande à vos clients toutes les informations dont vous avez besoin pour ouvrir rapidement un dossier, puis vous suggère de la documentation juridique si vous acceptez l\'affaire.'
        },
        {
          title: 'Commencez maintenant',
          text: 'Des cabinets de toutes tailles font confiance à Symplicy.'
        }
      ]
    }
  }
};
</script>

<style lang="scss" scoped>
.points {
  display: flex;
  flex-direction: column;
}

.point {
  display: flex;
  align-items: flex-start;

  &:not(:first-child) {
    margin-top: 16px;

    @include mq($from: 'l') {
      margin-top: 40px;
    }
  }

  .svg {
    margin-right: 18px;
    margin-top: 7px;
  }

  &-content {
    display: flex;
    flex-direction: column;
  }

  &-title {
    @include padding(4px null);
    font-size: rem(16px);
    line-height: 1.5;
    font-weight: 500;
  }

  &-text {
    color: $black-lighter;
  }
}
</style>