import { calls, endpoints } from '@/services/calls';
import { dispatchNotif } from '@/services/notifications';

const lawyer = {
  namespaced: true,
  state: {
    requests: {
      detail: 0,
      remove: 0
    },
    id: null,
    data: null,
    originalData: null
  },
  mutations: {
    update(state, { prop, value }) {
      state[prop] = value;
    },
    updateData(state, { prop, value }) {
      state.data[prop] = value;
    },
    incRequests(state, type) {
      state.requests[type]++;
    },
    decRequests(state, type) {
      state.requests[type]--;
    }
  },
  actions: {
    async setAsAdmin({ rootState, state, commit }) {
      const { id, data } = state;
      if (data.id === rootState.client.originalData.admin_id) return;

      commit('incRequests', 'detail');
      const request = await calls.post('setClientAdmin', { user_id: id });
      if (request.status === 'success') dispatchNotif({ type: 'success', text: 'Paramètre enregistré' });
      commit('decRequests', 'detail');
    },
    async delete({ state, commit }) {
      commit('incRequests', 'remove');
      const { id } = state;
      const url = endpoints.deleteUser.replace('{id}', id);
      const request = await calls.delete(url);

      if (request.status === 'success') {
        dispatchNotif({ type: 'success', text: 'Le compte a été supprimé' });
      }

      commit('decRequests', 'remove');
      return request;
    }
  }
}

export default lawyer;
