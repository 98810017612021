import datas from '@/data/static/metadatas.json';
import months from '@/data/static/months.json';

const getRef = (metadatas) => {
  if (!metadatas) return null;
  const { type, country, language } = metadatas;
  if (!type) return null;

  const properties = {
    legislation: {
      be: [
        'title',
        metas => formatDate(metas.legislationDate, language),
        metas => {
          const { collection } = metas;
          if (!collection) return null;
          const col = datas.be.legislation.collections.filter(c => c.value === collection);
          if (!col || !col.length) return null;
          return col[0].abbr;
        },
        metas => formatDate(metas.publicationDate, language)
      ],
      eu: [
        'title',
        metas => formatDate(metas.legislationDate, language),
        metas => {
          const { abbr } = metas;
          if (!abbr) return null;
          const abr = datas.eu.legislation.abbr.filter(c => c.value === abbr);
          if (!abr || !abr.length) return null;
          return abr[0].label;
        },
        metas => {
          const { journalSerie, journalNumber } = metas;
          if (!journalSerie || !journalNumber) return null;
          return `${journalSerie} ${journalNumber}`;
        },
        metas => formatDate(metas.journalDate, language)
      ]
    },
    jurisprudence: {
      be: [
        metas => {
          const { jurisdictionType, jurisdictionSection, jurisdictionName, jurisdictionAuthor } = metas;
          if (!jurisdictionName) return null;
          const jName = datas.be.jurisprudence.jurisdictionsName.filter(j => j.value === jurisdictionName);
          const jAuthor = datas.be.jurisprudence.jurisdictionsAuthor.filter(j => j.value === jurisdictionAuthor);
          if (!jName.length) return null;

          return `${jName[0].abbr}${jAuthor && jAuthor.length ? ', ' + jAuthor[0].abbr : ''}`;
        },
        metas => formatDate(metas.pronouncedDate, language),
        {
          required: (metas) => {
            const { jurisdictionType } = metas;
            return jurisdictionType && (jurisdictionType === 'c-e' || jurisdictionType === 'c-const');
          },
          value: 'number'
        }
      ],
    },
    doctrine: {
      be: {
        monograph: [
          'authorLastname',
          'authorFirstname',
          'title',
          'edition',
          'location',
          'publishingHouse',
          metas => {
            const { publicationDate } = metas;
            if (!publicationDate) return;
            return publicationDate.split('-')[0]
          }
        ],
        'legal-encyclopedia': [
          'authorLastname',
          'authorFirstname',
          'title',
          'journalName',
          'tome',
          'year'
        ],
        periodicals: [
          'authorLastname',
          'authorFirstname',
          'title',
          'journalName',
          'year'
        ],
        'stop-note': [
          'authorLastname',
          'authorFirstname',
          'title',
          metas => {
            const { decisionReference } = metas;
            if (!decisionReference) return null;
            return `note sous ${decisionReference}`;
          },
          'journalName',
          'year'
        ],
        'jurisprudence-review': [
          'authorLastname',
          'authorFirstname',
          'title',
          'journalName',
          'year'
        ],
        study: [
          'authorLastname',
          'authorFirstname',
          'title',
          metas => {
            const { journalName } = metas;
            if (!journalName) return null;
            return `in ${journalName}`;
          },
          'location',
          'publishingHouse',
          'year'
        ],
        publication: [
          'authorLastname',
          'authorFirstname',
          metas => {
            const { publicationType } = metas;
            if (!publicationType) return null;
            const type = datas.be.doctrine.publicationTypes.filter(c => c.value === publicationType);
            if (!type || !type.length) return null;
            return type[0].label;
          },
          'title',
          'number',
          metas => formatDate(metas.publicationDate, language),
        ]
      }
    },
    contract: { 
      be: [
        metas => {
          const type = datas.types.filter(t => t.value === metas.type);
          return type[0] ? type[0].label : datas.types[metas.type];
        },
        'name'
      ],
    },
    document: {
      be: [
        metas => {
          const type = datas.types.filter(t => t.value === metas.type);
          return type[0] ? type[0].label : datas.types[metas.type];
        },
        'name'
      ],
    },
    note: {
      be: [
        metas => {
          const type = datas.types.filter(t => t.value === metas.type);
          return type[0] ? type[0].label : datas.types[metas.type];
        },
        'name'
      ]
    },
    conclusion: {
      be: [
        metas => {
          const type = datas.types.filter(t => t.value === metas.type);
          return type[0] ? type[0].label : datas.types[metas.type];
        },
        'name'
      ]
    },
    quote: {
      be: [
        metas => {
          const type = datas.types.filter(t => t.value === metas.type);
          return type[0] ? type[0].label : datas.types[metas.type];
        },
        'name'
      ]
    }
  };

  let currentProperties = null;

  switch (type) {
    case 'doctrine':
      const { nature } = metadatas;
      if (nature && properties.doctrine[country]) currentProperties = properties.doctrine[country][nature];
      break;
    default:
      currentProperties = properties[type][country];
      break;
  }

  if (!currentProperties) return null;

  const missings = [];
  const values = [];

  for (let i = 0; i < currentProperties.length; i++) {
    const prop = currentProperties[i];
    let value = null;

    if (typeof prop === 'object') {
      value = metadatas[prop.value];

      if (prop.required(metadatas)) {
        if (value) value = value.trim();
        if (!value) {
          missings.push(prop);
          // break;
        } else {
          values.push(value);
        }
      }
    } else {
      if (typeof prop === 'function') value = prop(metadatas);
      if (typeof prop === 'string') value = metadatas[prop];

      if (value) value = value.trim();

      if (!value) {
        missings.push(prop);
      } else {
        values.push(value);
      }
    }
  }

  // if (missings.length) return null;
  return values.join(', ');
};

const formatDate = (rawDate, lang = 'fr') => {
  if (!rawDate) return null;
  const date = rawDate.split('-');
  const month = months[lang][parseInt(date[1]) - 1];
  return `${date[2]} ${month} ${date[0]}`
};


export default getRef;