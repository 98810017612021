<template>
  <div
    class="form-row"
    :data-columns="columns"
  >
    <slot></slot>
  </div>
</template>

<script>
export default {
  props: {
    columns: {
      type: Number,
      default: 2
    }
  }
}
</script>

<style lang="scss">
  .form-row {
    display: flex;
    flex-wrap: wrap;

    &:not(:first-child) {
      margin-top: 16px;
    }

    > * {
      width: 100%;
    }

    &[data-columns="2"] {
      > *:not(hr) {
        width: calc(50% - 8px);

        &:nth-child(even) {
          margin-left: 16px;
        }

        &:nth-child(n + 3) {
          margin-top: 16px;
        }
      }
    }
  }
</style>