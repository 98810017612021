import { calls, endpoints } from '@/services/calls';
import globals from '@/data/static/globals.json';

const legalCases = {
  namespaced: true,
  state: {
    requests: 0,
    filters: {
      all: false,
      closed: false
    },
    items: [
      // {
      //   "token":"44448f009-47cc-4fe5-a8-c9b2e8523555",
      //   "status": "new",
      //   "date": "2022-01-12",
      //   "litigant": {
      //     "firstname": "Samantha",
      //     "lastname": "Cherrypie"
      //   },
      //   "lawCase": {
      //     "id": 494,
      //     "content": "Je suis au bord de la faillite"
      //   }
      // }
    ],
    faileds: [],
    total: 0
  },
  mutations: {
    updateFilter(state, { filter, value }) {
      state.filters[filter] = value;
    },
    updateItems(state, items) {
      state.items = items;
    },
    updateTotal(state, total) {
      state.total = total;
    },
    incRequests(state) {
      state.requests++;
    },
    decRequests(state) {
      state.requests--;
    }
  },
  actions: {
    reset({ commit }) {
      commit('updateItems', []);
    },
    async get({ state, commit, dispatch }, { limit = globals.pagination.perPage, offset = 0 }) {
      commit('incRequests');

      const { all, closed } = state.filters;

      const params = { all, closed, limit, offset };
      const request = await calls.get('getLegalCases', { params });

      if (request.status === 'success') {
        const { data, meta } = request;
        commit('updateTotal', meta.total);
        commit('updateItems', data);
      }

      commit('decRequests');
    }
  }
}

export default legalCases;
