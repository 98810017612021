import { calls, endpoints } from '@/services/calls';

const search = {
  namespaced: true,
  state: {
    terms: '',
    filters: {
      type: null,
      visibility: null,
      source: null
    },
    requestTerms: '',
    requests: 0,
    results: []
  },
  mutations: {
    updateTerms(state, terms) {
      state.terms = terms;
    },
    updateFilter(state, { property, value }) {
      state.filters[property] = value;
    },
    updateRequestTerms(state, terms) {
      state.requestTerms = terms;
    },
    updateResults(state, results) {
      state.results = results;
    },
    incrementRequests(state) {
      state.requests++;
    },
    decrementRequests(state) {
      state.requests--;
    }
  },
  actions: {
    async getResults({ state, commit }) {
      commit('incrementRequests');
      commit('updateRequestTerms', state.terms);
      const request = await calls.post('searchDocs', state.terms);

      if (request.status === 'success') {
        let { data } = request;

        // Filters
        for(const f in state.filters) {
          const value = state.filters[f];

          if (value) {
            if (f === 'source') {
              if (value === 'symplicy') {
                data = data.filter(d => d.metadata && (!d.metadata['source'] || d.metadata['source'] === 'symplicy'));
              } else {
                data = data.filter(d => d.metadata && d.metadata['lawyerSource'] && d.metadata['lawyerSource'].id === value.value);
              }
            } else {
              data = data.filter(d => d.metadata && d.metadata[f] && d.metadata[f] === value);
            }
          }
        }

        const tokens = data.map(d => d.token);
        const states = await calls.post('getDocsState', { tokens });

        for (let i = 0; i < data.length; i++) {
          const doc = data[i];
          doc.processed = states.data.content[doc.token] === 'success';
          doc.state = states.data.content[doc.token];
        }

        commit('updateResults', data);
      }

      commit('decrementRequests');
    }
  }
}

export default search;
