import Account from '../views/Account.vue';
import Profile from '../views/account/Profile.vue';
import Offices from '../views/account/Offices.vue';
import Categories from '../views/account/Categories.vue';
import CategoriesList from '../views/account/categories/List.vue';
import CategoriesDetail from '../views/account/categories/Detail.vue';
import CategoriesUpdate from '../views/account/categories/Update.vue';

export const account = [
  {
    path: '/account',
    name: 'Account',
    component: Account,
    redirect: '/account/profile',
    meta: {
      userMenu: {
        prependIcon: 'account'
      },
    },
    children: [
      {
        path: '/account/profile',
        name: 'Profile',
        component: Profile,
        meta: {
          topbar: 'detail',
          sidebarLeft: 'accountMenu'
        }
      },
      // {
      //   path: '/account/offices',
      //   name: 'Offices',
      //   component: Offices,
      //   meta: {
      //     topbar: 'detail',
      //     sidebarLeft: 'accountMenu'
      //   }
      // },
      {
        path: '/account/categories',
        name: 'Categories',
        component: Categories,
        redirect: '/account/categories/list',
        children: [
          {
            path: '/account/categories/list',
            name: 'CategoriesList',
            component: CategoriesList,
            meta: {
              shortName: 'list',
              topbar: 'detail',
              sidebarLeft: 'accountMenu'
            },
          },
          {
            path: '/account/categories/update',
            name: 'CategoriesUpdate',
            component: CategoriesUpdate,
            meta: {
              shortName: 'update',
              topbar: 'detail',
              back: { name: 'CategoriesList' }
            },
          },
          {
            path: '/account/categories/:id',
            name: 'CategoriesDetail',
            component: CategoriesDetail,
            meta: {
              shortName: 'detail',
              topbar: 'detail',
              sidebarRight: 'lawyers',
              back: { name: 'CategoriesList' }
            },
          },
        ]
      },
    ]
  },
]