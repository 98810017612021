<template>
  <div :class="`field field--${type}${error ? ' field--error' : ''}`">
    <label
      v-if="label && type !== 'checkbox'"
      :for="id"
      class="field-label"
    >
      {{ label }}
    </label>
    <input
      :value="value"
      :placeholder="placeholder"
      :type="type"
      :id="id"
      :class="type === 'checkbox' ? 'u-visually-hidden' : 'field-input'"
      :checked="type === 'checkbox' ? checked : false"
      @input="onInput"
    />
    <label
      v-if="label && type === 'checkbox'"
      v-html="label"
      :for="id"
      class="field-label checkbox"
    />

    <button
      v-if="reveal"
      type="button"
      class="field-reveal"
      @click="onReveal"
    >
      <Sprite
        :type="`eye-${type === 'password' ? 'open' : 'closed'}`"
        :width="18"
        :height="18"
      />
    </button>

    <span v-if="error" class="field-error">{{ error }}</span>
  </div>
</template>

<script>
import Sprite from '@/components/elements/Sprite';

export default {
  components: {
    Sprite
  },
  props: {
    id: {
      type: String
    },
    value: {
      type: String
    },
    placeholder: {
      type: String
    },
    label: {
      type: String
    },
    type: {
      type: String
    },
    reveal: {
      type: Boolean
    },
    error: {
      type: String
    },
    checked: {
      type: Boolean
    }
  },
  methods: {
    onInput(e) {
      if (this.type === 'checkbox') {
        this.$emit("input", !this.checked);
        return;
      }
      const { value } = e.currentTarget;
      this.$emit("input", value);
    },
    onReveal() {
      this.$emit("reveal");
    }
  }
}
</script>

<style lang="scss">
.field {
  display: flex;
  flex-direction: column;
  position: relative;
  margin-bottom: 8px;

  &--error {
    .field-input {
      border-color: var(--alert);
    }
  }

  &--checkbox {
    .field-label {
      position: relative;
      padding-left: 32px;
      margin-bottom: 0;
      cursor: pointer;

      &::before {
        @include position(absolute, 4px null null 0);
        @include size(16px);
        content: '';
        border-radius: 4px;
        border: 1px solid var(--grey-lightest);
        background-color: var(--white-dark);
      }

      &::after {
        @include position(absolute, 7px null null 3px);
        @include size(10px);
        display: none;
        content: '';
        border-radius: 2px;
        background-color: var(--primary);
      }
    }

    input:checked {
      + .field-label {
        &::after {
          display: block;
        }
      }
    }
  }

  &-label {
    margin-bottom: 8px;
    font-weight: 500;

    &.checkbox {
      transition: color .3s $ease-out-quart;

      &:hover {
        color: var(--primary);
      }
    }
  }

  &-input {
    @include padding(3px 16px);
    display: block;
    width: 100%;
    border-radius: 8px;
    background-color: var(--white);
    border: 1px solid var(--grey-lightest);
    color: var(--black);
    font-size: rem(14px);
    line-height: (24 / 14);
    outline: none;
  }

  // Password
  &-reveal {
    @include position(absolute, 32px 0 null null);
    @include padding(4px);
    @include size(32px);
    display: flex;
    align-items: center;
    justify-content: center;
    
    .svg {
      fill: var(--primary);
    }
  }

  // Error
  &-error {
    width: 100%;
    margin-top: 6px;
    color: var(--alert);
    font-size: rem(12px);
    line-height: 1;
  }
}
</style>

<style lang="scss">
.field {
  &-label {
    a {
      color: var(--primary);
    }
  }
}
</style>